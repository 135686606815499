import React, { useState } from 'react';

import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Button = styled('button')`
    display: flex;
    gap: 4px;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    color: ${colors.white};
    background-color: ${colors.blue};
    transition: opacity ${transitions.primary};
`;

const getButtonText = buttonState => {
    if (buttonState.warning) {
        return 'checkout_basket.error';
    }

    if (buttonState.success) {
        return 'checkout_basket.added';
    }

    return 'checkout_basket.apply';
};

const QuickShopButton = ({ inStock = false, variationId, ...rest }) => {
    const { t } = useTranslation();
    const basket = useSelector(state => state.basket);

    const fontStyles = styleObjectToProps('Value Serif/14');

    const [buttonState, setButtonState] = useState({
        success: false,
        loading: false,
        warning: false,
    });

    const addToCart = async quantity => {
        setButtonState({ ...buttonState, loading: true });

        try {
            const response = await basket.addToBasket(variationId, quantity, null);

            if (response.status === 200 || response.status === 201) {
                setButtonState({ ...buttonState, success: true, loading: false });
            } else {
                setButtonState({ ...buttonState, warning: true, success: false, loading: false });
            }
        } catch (error) {
            console.error(error);
            setButtonState({ ...buttonState, warning: true });
        } finally {
            setTimeout(
                () =>
                    setButtonState({
                        success: false,
                        loading: false,
                        warning: false,
                    }),
                2800
            );
        }
    };

    const getLoading = () => {
        return basket.isFetching || buttonState.loading;
    };

    const isLoading = getLoading();
    const quantity = 1;
    let buttonText;

    if (!inStock) {
        buttonText = t('checkout_basket.out_of_stock');
    } else {
        buttonText = t(getButtonText(buttonState));
    }

    return (
        <Button
            disabled={!inStock}
            {...fontStyles}
            {...rest}
            onClick={() => {
                if (!isLoading && inStock) {
                    addToCart(quantity);
                }
            }}
        >
            <Plus color="currentColor" width="8px" />
            {buttonText}
        </Button>
    );
};

QuickShopButton.propTypes = {
    inStock: PropTypes.bool,
    variationId: PropTypes.string,
};

export default QuickShopButton;
