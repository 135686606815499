import React, { Component, Fragment } from 'react';

import CentraCheckoutScript from 'components/pages/checkout/CentraCheckoutScript';
import { CheckoutEvents } from 'libs/Events/constants';
import Events from 'libs/Events';
import PropTypes from 'prop-types';
import { injectModel } from 'state';

class CheckoutContainer extends Component {
    static propTypes = {
        basket: PropTypes.object.isRequired,
        selectedPaymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
        render: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
        renderData: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { triggerShippingAndPaymentTracking: null };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        const {
            basket: { basketId },
        } = this.props;
        const {
            basket: { basketId: prevBasketId },
        } = prevProps;

        if (basketId !== prevBasketId) {
            this.init();
        }
    }

    async setPaymentMethod(paymentMethod) {
        const {
            basket: { selectPaymentMethod },
        } = this.props;
        await selectPaymentMethod(paymentMethod);
    }

    getPaymentMethod() {
        const {
            basket: { paymentMethods, paymentMethodId },
            selectedPaymentMethod,
        } = this.props;

        // If the paymentMethod already is set on the basket, just return it.
        if (paymentMethodId) {
            return paymentMethodId;
        }

        let paymentMethod = null;
        if (selectedPaymentMethod) {
            if (Array.isArray(selectedPaymentMethod)) {
                for (let i = 0; i < selectedPaymentMethod.length && !paymentMethod; i++) {
                    const foundPaymentMethod = paymentMethods.find(p => p.id.indexOf(selectedPaymentMethod[i]) !== -1);
                    if (foundPaymentMethod) {
                        paymentMethod = foundPaymentMethod.id;
                    }
                }
            } else if (typeof selectedPaymentMethod === 'string') {
                for (let i = 0; i < paymentMethods.length; i++) {
                    if (paymentMethods[i].id.indexOf(selectedPaymentMethod) !== -1) {
                        paymentMethod = paymentMethods[i].id;
                    }
                }
            }
        } else {
            console.error('Missing selectedPaymentMethod.');
        }

        if (!paymentMethod) {
            paymentMethod = 'adyen-drop-in-byon';
        }

        return paymentMethod;
    }

    init() {
        const {
            basket: { basketId, paymentMethodId, items },
        } = this.props;

        Events.trigger(CheckoutEvents.VIEW, { items });

        // Set the paymentMethodId if it hasn't been set yet.
        if (basketId && !paymentMethodId) {
            const paymentMethod = this.getPaymentMethod();

            if (paymentMethod) {
                this.setPaymentMethod(paymentMethod);
            }
        }

        this.setState({
            triggerShippingAndPaymentTracking: true,
        });
    }

    setTriggerShippingAndPaymentTracking = value => {
        this.setState({
            triggerShippingAndPaymentTracking: value,
        });
    };

    render() {
        const { render: Render, basket, renderData } = this.props;

        const { basketId, centraCheckoutScript, paymentMethodId } = basket;

        if (basketId === null || paymentMethodId === null) {
            return null;
        }

        const isIngridEnabled = !!basket.shippingMethods.ingrid;

        // Haven't decided if we should pass basket as a prop to Render or let them inject it themselves.
        // For now, let them inject it themselves.
        return (
            <Fragment>
                <CentraCheckoutScript script={centraCheckoutScript} isIngridEnabled={isIngridEnabled} />
                <Render
                    data={renderData}
                    tracking={{
                        trigger: this.state.triggerShippingAndPaymentTracking,
                        setTrigger: this.setTriggerShippingAndPaymentTracking,
                    }}
                />
            </Fragment>
        );
    }
}

export default injectModel('basket')(CheckoutContainer);
