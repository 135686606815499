import Above from 'components/breakpoints/Above';
import AddMultipleToBasketButton from 'components/buttons/functionalButtons/AddMultipleToBasketButton';
import BackgroundImage from 'components/background/BackgroundImage';
import Below from 'components/breakpoints/Below';
import Close from 'assets/icons/Close';
import Heading from 'components/text/Heading';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import { above } from 'utils/mediaqueries';
import contentMargins from 'config/theme/contentMargins';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import useIsShowroom from 'hooks/useIsShowroom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Wrapper = styled('div')`
    width: 100%;
    height: 100%;
    background: var(--default-background-color);
    color: var(--default-text-color);

    ${above.tabletSm} {
        padding: 24px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

const Button = styled('button')`
    ${above.tabletSm} {
        position: fixed;
        top: 24px;
        right: 24px;
    }
`;

const Looks = styled('div')`
    display: flex;
    gap: 24px 16px;
    margin-bottom: 48px;

    /* To get a space at the end of the content blocks */
    &::after,
    &::before {
        content: '';
        min-width: 4px; // 4px + 12px gap = 16px padding
        display: block;
        height: 1px;
    }

    ${above.tabletSm} {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 24px 16px;

        &::after,
        &::before {
            display: none;
        }
    }
`;

const OverlayContent = ({ look = {} }) => {
    const { t } = useTranslation();
    const overlay = useSelector(state => state.overlay);
    const numberOfProducts = look.products.length;
    const showBuyAllCard = numberOfProducts > 1;
    const isShowroom = useIsShowroom();

    return (
        <Wrapper>
            <Div position="relative">
                <Div
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    marginBottom="16px"
                    padding={[contentMargins[0], null, '0']}
                    position="relative"
                >
                    <Heading fontKeys="Value Serif/32">
                        {t('utils.products')} ({numberOfProducts})
                    </Heading>
                    <Button type="button" onClick={() => overlay.hide()}>
                        <Close width="24px" />
                    </Button>
                </Div>
                <BackgroundImage
                    aspectRatio={ratios.horizontal}
                    className="background-look"
                    display={['none', null, 'block']}
                    marginBottom="24px"
                    position={look.background.landscape.backgroundPosition}
                    query={mediaQueries.threeColumn}
                    size={look.background.landscape.backgroundSize}
                    src={look.background.landscape.url}
                />
                <Below
                    breakpoint="tabletSm"
                    render={() => (
                        <ScrollWrapper
                            displayFadeEffect
                            direction="horizontal"
                            scrollContentProps={{ whiteSpace: 'normal' }}
                        >
                            <Looks>
                                {look.products?.map(product => (
                                    <ProductCard
                                        quickshop
                                        imageSizes={['50vw', null, '200px']}
                                        key={`${product?.id}-${product?.sku}`}
                                        srcWidths={[282, 538, 277, 476, 640]}
                                        width="33.33333333vw"
                                        {...product}
                                    />
                                ))}
                                {!isShowroom && showBuyAllCard && (
                                    <Div as="article" width="33.33333333vw">
                                        <BackgroundImage
                                            aspectRatio={ratios.vertical}
                                            height="auto"
                                            position={look.background.portrait.backgroundPosition}
                                            query={[{ w: 282 }, { w: 538 }, { w: 277 }, { w: 476 }, { w: 640 }]}
                                            size={look.background.portrait.backgroundSize}
                                            src={look.background.portrait.url}
                                        />
                                        <AddMultipleToBasketButton marginTop="8px" multipleIds={look.productIds} />
                                    </Div>
                                )}
                            </Looks>
                        </ScrollWrapper>
                    )}
                />
                <Above
                    breakpoint="tabletSm"
                    render={() => (
                        <Looks>
                            {look.products?.map(product => (
                                <ProductCard
                                    quickshop
                                    key={`${product?.id}-${product?.sku}`}
                                    imageSizes={['50vw', null, '200px']}
                                    srcWidths={[282, 538, 277, 476, 640]}
                                    {...product}
                                />
                            ))}
                            {!isShowroom && showBuyAllCard && (
                                <Div as="article">
                                    <BackgroundImage
                                        aspectRatio={ratios.vertical}
                                        height="auto"
                                        position={look.background.landscape.backgroundPosition}
                                        query={[{ w: 282 }, { w: 538 }, { w: 277 }, { w: 476 }, { w: 640 }]}
                                        size={look.background.landscape.backgroundSize}
                                        src={look.background.landscape.url}
                                    />
                                    <AddMultipleToBasketButton marginTop="8px" multipleIds={look.productIds} />
                                </Div>
                            )}
                        </Looks>
                    )}
                />
            </Div>
        </Wrapper>
    );
};

OverlayContent.propTypes = {
    look: PropTypes.object,
};

export default OverlayContent;
