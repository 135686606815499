import PropTypes from 'prop-types';
import Radio from 'components/forms/Radio';
import React from 'react';
import dropShadows from 'config/theme/dropShadows';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: absolute;
    top: 32px;
    right: 24px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: var(--theme-background-color);
    transition: opacity ${transitions.primary.medium};
    z-index: ${zIndex.filterBar};
    filter: drop-shadow(${dropShadows.primary} var(--theme-box-shadow-color));
`;

const SortOptions = ({ sorts = [], show = false, handleChange }) => {
    if (sorts.length < 1) {
        return null;
    }

    const defaultActiveSort = sorts.find(sort => sort.selected);

    return (
        <Wrapper opacity={show ? 1 : 0} visibility={show ? 'visible' : 'hidden'}>
            <Radio
                defaultActive={defaultActiveSort?.id}
                display="flex"
                flexDirection="column"
                gap={['8px 0', null, null, null, '16px 0']}
                handleChange={item => handleChange(item.id)}
                items={sorts}
                name="productSort"
            />
        </Wrapper>
    );
};

SortOptions.propTypes = {
    handleChange: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    sorts: PropTypes.array.isRequired,
};

export default SortOptions;
