import { desktopHeaderHeight, mobileHeaderHeight, tabletHeaderHeight } from 'hooks/useHeaderHeights';
import { useDispatch, useSelector } from 'react-redux';

import Basket from 'assets/icons/Basket';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`;

const BasketHeaderIcon = () => {
    const dispatch = useDispatch();
    const overlay = useSelector(state => state.overlay);
    const totalNumberOfProducts = useSelector(state => state.basket.totalNumberOfProducts);
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);

    const toggleBasket = () => {
        const openOverlay = overlay.current;

        if (menuIsOpen) {
            dispatch(closeMenu());
        }

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', { zIndex: zIndex.basket });
        } else {
            overlay.hide();
        }
    };

    return (
        <Wrapper
            height={[`${mobileHeaderHeight}px`, null, `${tabletHeaderHeight}px`, null, `${desktopHeaderHeight}px`]}
            onClick={() => toggleBasket()}
        >
            {totalNumberOfProducts > 0 && (
                <Paragraph
                    as="span"
                    display="flex"
                    justifyContent="flex-end"
                    margin={['3px 4px 0 0', null, null, null, '1px 4px 0 0']}
                >
                    {totalNumberOfProducts}
                </Paragraph>
            )}
            <Basket color="var(--header-text-color)" width={['32px', null, null, null, '24px']} />
        </Wrapper>
    );
};

export default BasketHeaderIcon;
