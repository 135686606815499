import { ColorThemes, themes } from 'config/theme/themes';

import { inServer } from 'config/constants';

const cssVariables = [
    { variable: '--theme-background-color', property: 'backgroundColor' },
    { variable: '--theme-color', property: 'color' },
    { variable: '--theme-opacity-color-primary', property: 'opacityColor' },
    { variable: '--theme-error-color', property: 'errorColor' },
    { variable: '--theme-color-active', property: 'activeColor' },
];

export const changeSiteColorTheme = (colorTheme: ColorThemes = 'default') => {
    const theme = themes[colorTheme] || themes['default'];
    const root = !inServer ? document.documentElement : null;

    if (root) {
        cssVariables.forEach(({ variable, property }) => {
            root.style.setProperty(variable, theme[property]);
        });
    }
};
