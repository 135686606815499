/**
 * Use this as a guidence in catacombs of the the z-index horror.
 * We have partet this in to three sections
 * background - as name says, backgrounds and content that going to act as a "canvas" for something else
 * middleground - here we want to display the "main" content, such as header, footer search etc
 * forground - Use as example states below for overlays sticky elements etc. Displays above anything else.
 * if ex: you want something displayed just below the header you can use zIndex.header - 1
 */

const zIndex = {
    // background
    background: 1,
    overlayHeader: 5,

    // middleground
    middleground: 500,
    imageGallery: 500,
    filterBar: 500,
    logoMobile: 502,
    stickyAddToBasket: 503,
    searchOverlay: 510,
    basket: 515,
    siteSelector: 516,
    uspBanner: 517,
    menuDesktop: 518,
    menuMobile: 519,
    header: 520,
    logoDesktop: 521,
    stickyLogo: 522,
    popup: 550,

    // forground
    overlayShopTheLook: 990,
    forground: 1000,
};

export default zIndex;
