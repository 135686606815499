import React, { useEffect } from 'react';

import AddedToBasket from 'components/AddedToBasket';
import { inServer } from 'config/constants';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useClientHeight from 'hooks/useClientHeight';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const TransitionElement = styled('div')`
    position: fixed;
    top: 0;
    right: 0;
    overflow: auto;
`;

const AddedToBasketPopup = () => {
    const latestAddedProduct = useSelector(state => state.latestAddedProduct);
    const overlay = useSelector(state => state.overlay);
    const popups = useSelector(state => state.popups);
    const aboveDesktopSm = useAboveBreakpoint('desktopSm');
    const clientHeight = useClientHeight();

    const openBasket = () => {
        const openOverlay = overlay.current;

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', { zIndex: zIndex.basket });
        }
    };

    const closePopup = () => {
        popups.hide('addedToBasketPopup');
    };

    const startTimer = () => {
        if (!inServer && latestAddedProduct.product !== null) {
            window.timer = setTimeout(() => {
                closePopup();
            }, 3000);
        }
    };

    const stopTimer = () => {
        if (!inServer) {
            clearTimeout(window.timer);
        }
    };

    useEffect(() => {
        if (latestAddedProduct) {
            startTimer();
        }
        return () => {
            if (!inServer) {
                clearTimeout(window.timer);
            }
        };
    }, [latestAddedProduct]);

    return (
        <TransitionElement
            className={aboveDesktopSm ? 'slide-in right' : 'slide-in top'}
            height={['auto', null, null, null, clientHeight]}
            width={['100vw', null, null, null, 'auto']}
            onMouseEnter={() => stopTimer()}
            onMouseLeave={() => startTimer()}
        >
            <AddedToBasket
                closePopup={() => closePopup()}
                openBasket={() => openBasket()}
                product={latestAddedProduct.product}
            />
        </TransitionElement>
    );
};

export default AddedToBasketPopup;
