import AdyenForm from 'components/pages/checkout/CheckoutView/Payment/paymentMethods/Adyen/AdyenForm';
import Heading from 'components/text/Heading';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const AdyenFormWrapper = ({
    headingFontKeys = '',
    isLoaded = false,
    scrollToShippingSection,
    setShowShippingAndPayment,
    showShippingAndPayment,
    tracking = {},
}) => {
    const heading = useSelector(state => state.page.data.checkout.information?.heading || {});

    return (
        <>
            <Heading fontKeys={headingFontKeys} marginTop="16px" to={heading.type}>
                01. {heading.text}
            </Heading>
            <AdyenForm
                scrollToShippingSection={scrollToShippingSection}
                showShippingAndPayment={showShippingAndPayment}
                setShowShippingAndPayment={setShowShippingAndPayment}
                tracking={tracking}
            />
            <Loading hide={isLoaded} />
        </>
    );
};

AdyenFormWrapper.propTypes = {
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isLoaded: PropTypes.bool,
    scrollToShippingSection: PropTypes.func.isRequired,
    setShowShippingAndPayment: PropTypes.func.isRequired,
    showShippingAndPayment: PropTypes.bool,
    tracking: PropTypes.shape({
        trigger: PropTypes.bool,
        setTrigger: PropTypes.func,
    }),
};

export default AdyenFormWrapper;
