import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')``;

const Input = styled('input')`
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute; // Don't take up space in dom

    &:checked + label::before {
        background-color: var(--default-color-active);
    }

    &:checked + label::after {
        right: 2px;
    }
`;

const Label = styled('label')`
    position: relative;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;

    ::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 40px;
        height: 20px;
        background: var(--overlay-border-color);
        border-radius: 16px;
        transition: all ${transitions.primary};
    }

    ::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 22px; // 40px switch width - 2px space to edge - 16px switch width
        width: 16px;
        height: 16px;
        background-color: var(--overlay-background-color);
        border-radius: 90px;
        transition: all ${transitions.primary};
    }
`;

/**
 * Switch input - Works like a checkbox box looks like a switch
 *
 * @param {bool} checked - The state of switch
 * @param {string} label - Visible text
 * @param {string} name - Used to connect input and label
 * @param {func} handleChange - Function that runs on click
 */

const SwitchInput = ({ checked = false, label = '', name = '', handleChange = () => {}, ...rest }) => (
    <Wrapper onClick={handleChange} {...rest}>
        <Input checked={checked} name={name} type="checkbox" />
        <Label htmlFor={name} {...rest}>
            <Paragraph as="span" fontKeys="Value Sans/16">
                {label}
            </Paragraph>
        </Label>
    </Wrapper>
);

SwitchInput.propTypes = {
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default SwitchInput;
