import {
    CLOSE_MENU,
    HIDE_BREADCRUMBSBAR,
    HIDE_HEADER,
    OPEN_MENU,
    RESET_MENU,
    SET_ACTIVE_MENU,
    SHOW_BREADCRUMBSBAR,
    SHOW_HEADER,
} from './constants';

import { RESOLVE_APP_SUCCESS } from '../Application/constants';
import { transformHeaderData } from 'utils/dataTransformers/header';

// The initial state of this store.
const initialState = {
    data: {},
    state: {
        activeMenu: null,
        breadcrumbsBarIsVisible: true,
        hasBanner: false,
        headerIsVisible: true,
        menuIsOpen: false,
    },
};

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case RESOLVE_APP_SUCCESS: {
            return {
                ...state,
                data: transformHeaderData(action.config.header),
                state: {
                    ...state.state,
                    hasBanner: action.config.header.banner.messages?.length > 0,
                },
            };
        }

        case SET_ACTIVE_MENU: {
            return {
                ...state,
                state: {
                    ...state.state,
                    activeMenu: action.activeMenu,
                },
            };
        }

        case RESET_MENU: {
            return {
                ...state,
                state: {
                    ...state.state,
                    activeMenu: null,
                },
            };
        }

        case OPEN_MENU: {
            return {
                ...state,
                state: {
                    ...state.state,
                    menuIsOpen: true,
                },
            };
        }

        case CLOSE_MENU: {
            return {
                ...state,
                state: {
                    ...state.state,
                    activeMenu: null,
                    menuIsOpen: false,
                },
            };
        }

        case SHOW_HEADER: {
            return {
                ...state,
                state: {
                    ...state.state,
                    headerIsVisible: true,
                },
            };
        }

        case HIDE_HEADER: {
            return {
                ...state,
                state: {
                    ...state.state,
                    headerIsVisible: false,
                },
            };
        }

        case SHOW_BREADCRUMBSBAR: {
            return {
                ...state,
                state: {
                    ...state.state,
                    breadcrumbsBarIsVisible: true,
                },
            };
        }

        case HIDE_BREADCRUMBSBAR: {
            return {
                ...state,
                state: {
                    ...state.state,
                    breadcrumbsBarIsVisible: false,
                },
            };
        }

        default: {
            return state;
        }
    }
};
