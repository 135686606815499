import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import { useLottie } from 'lottie-react';

const Wrapper = styled('div')``;

/**
 * Component used for Lottie animations
 *
 * @param  {object} [animationData={}] -  Animation as a JSON file
 * @param  {boolean} [autoplay=true] - Autoplay animation on load
 * @param  {boolean} [controls=false] - Show controls
 * @param  {number} [direction=1] - Direction of animation as number
 * @param  {boolean} [hover=false] - Whether to play on mouse hover
 * @param  {boolean} [loop=true] - Whether to loop animation
 * @param  {number} [speed=1] - Animation speed
 */
const Lottie = ({
    animationData = {},
    autoplay = true,
    controls = false,
    direction = 1,
    hover = false,
    loop = true,
    speed = 1,
    ...rest
}) => {
    const options = { animationData, autoplay, controls, direction, hover, loop, speed };
    const { View } = useLottie(options);

    return <Wrapper {...rest}>{View}</Wrapper>;
};

Lottie.propTypes = {
    animationData: PropTypes.object.isRequired,
    autoplay: PropTypes.bool,
    controls: PropTypes.bool,
    direction: PropTypes.number,
    hover: PropTypes.bool,
    loop: PropTypes.bool,
    speed: PropTypes.number,
};

export default Lottie;
