import React, { useEffect, useRef, useState } from 'react';
import { allowScroll, preventScroll } from 'utils/scrollFunctions';
import Above from 'components/breakpoints/Above';
import CloseIcon from 'assets/icons/Close';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import close from 'assets/icons/cursors/close.svg';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import zIndex from 'config/theme/z-index';
import generateImageSrc from 'libs/Image/generateImageSrc';
import ratios from 'config/theme/ratios';
import imageMaxWidths from 'config/theme/imageMaxWidths';

const ZoomWrapper = styled('div', {
    shouldForwardProp: prop => ['isZoomActive', 'revealTime', 'isSmoothScroll'].indexOf(prop) === -1,
})`
    position: fixed;
    width: 100%;
    max-height: 100%;
    opacity: 0;
    transition: ${({ revealTime }) => `opacity ${revealTime}ms ease`};
    z-index: ${zIndex.imageGallery};
    background: ${colors.grey.one};

    ${({ isSmoothScroll }) =>
        isSmoothScroll
            ? `
        // don't allow clicking & scrolling during zoom-in & out animations
        pointer-events: auto;
        overflow: auto;
        scroll-behavior: smooth;
    `
            : `
        pointer-events: none;
        overflow: hidden;
        scroll-behavior: auto;
    `}

    ${({ isZoomActive }) =>
        isZoomActive &&
        `
        // The zoom is open, set position to fill screen
        top: 0;
        left: 0;
        max-width: 100%;
        min-height: 100%;
        opacity: 1;
    `}
`;

const CloseButton = styled('button')`
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: ${zIndex.logoMobile};

    ${above.desktopSm} {
        display: none;
    }
`;

const MainImageWrapper = styled('div')``;

const MainImage = styled('img')`
    user-select: none; // Prevents ::selection from marking the image
    position: relative;
    width: 100%;
    object-fit: cover;
    z-index: 2;
`;

const Thumbnails = styled('div', {
    shouldForwardProp: prop => ['isZoomActive', 'revealTime'].indexOf(prop) === -1,
})`
    position: fixed;
    top: 50%;
    left: 30px;
    width: 48px;
    max-height: 100vh;
    transform: translateY(-50%);
    transition: ${({ revealTime, isZoomActive }) =>
        isZoomActive ? `all ${revealTime}ms ease ${revealTime * 0.8}ms` : `all ${revealTime * 0.5}ms ease`};
    visibility: ${({ isZoomActive }) => (isZoomActive ? 'visible' : 'hidden')};
    opacity: ${({ isZoomActive }) => (isZoomActive ? '1' : '0')};
    overflow: auto;
    z-index: 3;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const ThumbnailImage = styled(MainImage, {
    shouldForwardProp: prop => ['isActive'].indexOf(prop) === -1,
})`
    margin: 6px 0;
    opacity: 0.5;
    transition: opacity ${transitions.primaryFade};
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    ${({ isActive }) =>
        isActive &&
        `
        opacity: 1;
        cursor: default;
    `}
`;

const ProductImageZoom = ({
    currentImageIndex = null,
    images = [],
    revealTime = 500,
    setCurrentImage = () => null,
}) => {
    const zoomWrapperRef = useRef(null);

    const [isReadyToScroll, setReadyToScroll] = useState(false);
    const [isSmoothScroll, setSmoothScroll] = useState(false);
    const [isZoomActive, setIsZoomActive] = useState(false);
    const [isZoomedOut, setZoomedOut] = useState(false);
    const [shouldRender, setShouldRender] = useState(currentImageIndex !== null);

    let scrollEndTimeout;
    let scrollTimeout;
    let smoothScrollTimeout;
    let zoomAnimationTimeout;
    let zoomInTimeout;
    let zoomOutTimeout;

    // Take the src from the image node and update it with correct image size.
    const transformSrc = (src, size) => generateImageSrc({ src, width: size, quality: 70 });

    // Scroll to active image when current index gets updated. Runs when clicking on thumbnail
    const autoScrollToImage = (index, isZoomedOut) => {
        if (zoomWrapperRef?.current?.children) {
            let activeImgIndex;

            if (index || index === 0) {
                activeImgIndex = index + 1;
            } else {
                activeImgIndex = currentImageIndex + 1;
            }

            zoomWrapperRef.current.scrollTop = zoomWrapperRef.current.children[activeImgIndex].offsetTop;
            isZoomedOut && setZoomedOut(true);
        }
    };

    const zoomIn = () => {
        preventScroll();
        setShouldRender(true);

        // A small delay on setIsZoomActive to make sure that the reveal animation runs correctly
        zoomInTimeout = setTimeout(() => setIsZoomActive(true), 100);

        // setSmoothScroll adds smooth-scroll to scroll nicely between images when using thumbnail
        scrollTimeout = setTimeout(() => {
            setReadyToScroll(true);
            autoScrollToImage();
            setSmoothScroll(true);
        }, 1000);
    };

    const zoomOut = () => {
        setZoomedOut(true);

        // A small delay to make sure that the reveal animation runs correctly
        zoomAnimationTimeout = setTimeout(() => {
            setIsZoomActive(false);
            setReadyToScroll(false);
            setSmoothScroll(false);
        }, 100);

        // A small delay to let the conceal animation run its course before removing the html-elements
        zoomOutTimeout = setTimeout(() => {
            allowScroll();
            setCurrentImage(null);
            setShouldRender(false);
            setZoomedOut(false);
        }, 500);
    };

    useEffect(() => {
        const hasIndex = currentImageIndex !== null;
        const currentZoomWrapper = zoomWrapperRef.current;

        // Trigger zoom-in (only when zoom-mode isn't activated)
        if (hasIndex && !shouldRender) {
            zoomIn();
        }

        // Update current image index during user scrolling through images
        let previousImagePosition = 0;

        const handleScroll = () => {
            if (isZoomedOut) {
                zoomOut();
            }

            const imageHeight = currentZoomWrapper.children[1].clientHeight;
            const scrollTop = currentZoomWrapper.scrollTop;

            // Round to zero decimals, returns a number which matches the current image-index
            // Calculation below switches current-image depending on which image is in the middle of the viewport height.
            const currentImagePosition = Math.round((scrollTop - imageHeight / 4) / imageHeight);

            if (previousImagePosition !== currentImagePosition && imageHeight) {
                previousImagePosition = currentImagePosition;

                if (currentImagePosition || currentImagePosition === 0) {
                    setCurrentImage(currentImagePosition);
                }
            }
        };

        if (currentZoomWrapper && isReadyToScroll) {
            currentZoomWrapper.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentZoomWrapper) {
                currentZoomWrapper.removeEventListener('scroll', handleScroll);
            }
        };
    }, [currentImageIndex, images, zoomWrapperRef, shouldRender, isReadyToScroll, isZoomedOut]);

    useEffect(() => {
        let preloadTimeout;

        // Preload all fullsize images so that the animation will run smoother
        if (images && images[0]?.src) {
            const preloadImages = [];
            images.forEach((image, i) => {
                const { src: rawSrc, width, height } = image;

                // Avoid massive images on big screens
                const maxImageWidth = width > height ? imageMaxWidths.horizontal : imageMaxWidths.vertical;
                const imageWidth = window.innerWidth < maxImageWidth ? window.innerWidth : maxImageWidth;

                preloadImages[i] = new Image();
                preloadImages[i].src = transformSrc(rawSrc, imageWidth);
            });
        }

        return () => {
            clearTimeout(preloadTimeout);
        };
    }, [images]);

    // Handles keyup
    useEffect(() => {
        const handleKeyup = e => {
            const hasPressedEscape = e.keyCode === 27;
            if (hasPressedEscape) {
                zoomOut();
            }
        };

        document.addEventListener('keyup', handleKeyup);
        return () => {
            document.removeEventListener('keyup', handleKeyup);
        };
    }, []);

    useEffect(() => {
        // Reset on unmount
        return () => {
            allowScroll();
            clearTimeout(zoomInTimeout);
            clearTimeout(zoomAnimationTimeout);
            clearTimeout(zoomOutTimeout);
            clearTimeout(scrollTimeout);
            clearTimeout(smoothScrollTimeout);
            clearTimeout(scrollEndTimeout);
        };
    }, []);

    return shouldRender ? (
        <ZoomWrapper
            isSmoothScroll={isSmoothScroll}
            isZoomActive={isZoomActive}
            ref={zoomWrapperRef}
            revealTime={revealTime}
        >
            <CloseButton cursor="pointer" onClick={() => zoomOut()}>
                <CloseIcon />
            </CloseButton>
            {images.map((image, i) => {
                const { src: rawSrc, width, height } = image;
                const aspectRatio = width > height ? ratios.horizontal : ratios.vertical;

                // Avoid massive images on big screens
                const maxImageWidth = width > height ? imageMaxWidths.horizontal : imageMaxWidths.vertical;
                const imageWidth = window.innerWidth < maxImageWidth ? window.innerWidth : maxImageWidth;
                const src = transformSrc(rawSrc, imageWidth);

                return (
                    <MainImageWrapper key={`${src}-${i}`}>
                        <MainImage
                            aspectRatio={aspectRatio}
                            cursor={`url('${close}') 20 20, pointer`}
                            display={currentImageIndex === i || isReadyToScroll ? 'block' : 'none'}
                            isZoomActive={isZoomActive}
                            src={src}
                            onClick={() => zoomOut()}
                        />
                    </MainImageWrapper>
                );
            })}
            {images?.length > 1 && (
                <Above
                    breakpoint="desktopSm"
                    render={() => (
                        <Thumbnails isZoomActive={isZoomActive} revealTime={revealTime}>
                            {images?.map((thumbnail, i) => {
                                const thumbnailSrc = transformSrc(thumbnail?.src, 64);

                                return (
                                    <ThumbnailImage
                                        isActive={i === currentImageIndex}
                                        key={thumbnailSrc}
                                        src={thumbnailSrc}
                                        onClick={() => {
                                            setCurrentImage(i);
                                            autoScrollToImage(i);
                                        }}
                                    />
                                );
                            })}
                        </Thumbnails>
                    )}
                />
            )}
        </ZoomWrapper>
    ) : null;
};

ProductImageZoom.propTypes = {
    currentImageIndex: PropTypes.number,
    images: PropTypes.array,
    revealTime: PropTypes.number,
    setCurrentImage: PropTypes.func.isRequired,
};

export default ProductImageZoom;
