import Filter from 'components/Filter';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const TransitionElement = styled('div')`
    position: absolute;
    top: 0;
    width: 100vw;
    max-width: 400px;
    background-color: var(--overlay-background-color);
    transition: max-width ${transitions.secondary};

    // Used to prevent a height 100vh bug on android phones.
    // This element will overflow the TransitionElement and hide content behind when viewport changes height.
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: var(--overlay-background-color);
    }
`;

const FilterOverlay = ({ ...rest }) => (
    <TransitionElement className="slide-in left">
        <Filter {...rest} />
    </TransitionElement>
);

FilterOverlay.propTypes = {
    clear: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    handleChange: PropTypes.shape({ value: PropTypes.func, range: PropTypes.func }).isRequired,
    hitsCount: PropTypes.number.isRequired,
    sorts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default FilterOverlay;
