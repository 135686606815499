import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Chevron = ({ color = colors.black, height = '100%', width = '16px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 16 27" width={width} {...rest}>
        <Path d="m1.5 1.5 12 12-12 12" stroke={color} strokeWidth="4" />
    </SVG>
);

Chevron.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Chevron;
