import Piggy from 'assets/icons/Piggy.tsx';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    align-items: flex-end;
    aspect-ratio: 1 / 0.8;
`;

const PiggyWrapper = styled('div', {
    shouldForwardProp: prop => ['delay'].indexOf(prop) === -1,
})`
    animation: move 0.8s linear ${({ delay }) => delay}s infinite alternate;

    width: 25%;

    @keyframes move {
        0% {
            transform: translateX(0) translateY(40%) scaleY(30%);
        }
        2% {
            transform: translateX(0) translateY(40%) scaleY(30%);
        }
        25% {
            transform: translateX(30%) translateY(-90%) rotate(45deg) scaleY(120%) scaleX(78.5%);
        }
        40% {
            transform: translateX(120%) translateY(-170%) rotate(72deg);
        }
        50% {
            transform: translateX(150%) translateY(-180%) rotate(90deg) scaleY(100%) scaleX(100%);
        }
        60% {
            transform: translateX(180%) translateY(-170%) rotate(108deg);
        }
        75% {
            transform: translateX(270%) translateY(-90%) rotate(135deg) scaleY(120%) scaleX(78.5%);
        }
        98% {
            transform: translateX(300%) translateY(40%) rotate(180deg) scaleY(30%) scaleX(100%);
        }
        100% {
            transform: translateX(300%) translateY(40%) rotate(180deg) scaleY(30%) scaleX(100%);
        }
    }
`;

const JumpingPiggy = ({ delay = '0', ...rest }) => (
    <Wrapper display="flex" {...rest}>
        <PiggyWrapper delay={delay}>
            <Piggy />
        </PiggyWrapper>
    </Wrapper>
);

JumpingPiggy.propTypes = {
    delay: PropTypes.string,
};

export default JumpingPiggy;
