import Defs from './base/Defs';
import G from './base/G';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Close = ({ color = colors.black, height = '100%', width = '24px', ...rest }) => (
    <SVG className="close-icon" fill="none" height={height} viewBox="0 0 24 24" width={width} {...rest}>
        <G clipPath="close-icon">
            <Path
                className="close-icon-path"
                d="m19.424 2.454 2.122 2.121-6.364 6.364a1.5 1.5 0 0 0 0 2.122l6.364 6.364-2.122 2.12-6.364-6.363a1.5 1.5 0 0 0-2.12 0l-6.365 6.364-2.121-2.121 6.364-6.364a1.5 1.5 0 0 0 0-2.122L2.454 4.575l2.121-2.12 6.364 6.363a1.5 1.5 0 0 0 2.121 0l6.364-6.364Z"
                fill={color}
            />
        </G>
        <Defs>
            <clipPath id="close-icon">
                <Path className="close-icon-path" fill={color} transform="rotate(-90 12 12)" d="M0 0h24v24H0z" />
            </clipPath>
        </Defs>
    </SVG>
);

Close.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Close;
