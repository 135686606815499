import React, { useState } from 'react';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import Image from 'components/Image';
import IncreaseOrDecrease from 'components/products/ProductCardMini/IncreaseOrDecrease';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import Price from '../Price';
import PropTypes from 'prop-types';
import SpinnerIcon from 'assets/icons/Spinner';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['productTypes'].indexOf(prop) === -1,
})`
    display: flex;
    position: relative;
    padding: 16px 0;
`;

const ProductLink = styled(Link)`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const InfoAndRemove = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    flex-grow: 1;
    padding-left: 8px;
    width: 50%;
`;

const Info = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 4px 0;
`;

const PriceAndControls = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 50%;
`;

const Spinner = styled(SpinnerIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const RemoveButton = styled('button')`
    display: inline-flex
    textAlign: left
`;

const ProductCardMini = ({
    color = '',
    detailedColor = '',
    imageSrc = '',
    isOnSale = false,
    last = false,
    line,
    name = '',
    priceWithCurrency = '',
    quantity,
    salePriceWithCurrency = '',
    size = '',
    to = '',
    onClickPermalink = () => {},
    ...rest
}) => {
    const { t } = useTranslation();
    const basket = useSelector(state => state.basket);
    const [isLoading, setIsLoading] = useState(false);
    const srcWidths = [76, 95, 152, 190, 228, 285];
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    const handleIncrease = line => {
        if (line) {
            setIsLoading(true);

            basket.updateBasketLineQuantity(line, +1).then(() => {
                setIsLoading(false);
            });
        }
    };

    const handleDecrease = line => {
        if (line) {
            setIsLoading(true);

            basket.updateBasketLineQuantity(line, -1).then(() => {
                setIsLoading(false);
            });
        }
    };

    const handleRemove = (line, quantity) => {
        if (line) {
            setIsLoading(true);

            basket.removeFromBasket(line, quantity).then(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <Wrapper
            borderBottom={!last ? '1px solid var(--basket-border-color-light)' : 'none'}
            opacity={isLoading ? 0.6 : 1}
            {...rest}
        >
            <Link to={to} onClick={() => onClickPermalink()}>
                <AspectWrapper
                    backgroundColor="var(--default-placeholder-color)"
                    ratio={ratios.vertical}
                    width={['76px', null, null, null, '95px']}
                    overflow="hidden"
                >
                    <Image
                        alt={name}
                        fm="jpg"
                        sizes="75px"
                        src={{ url: imageSrc, width: srcWidths, height: srcHeights }}
                        title={name}
                    />
                </AspectWrapper>
            </Link>
            <InfoAndRemove>
                <Info>
                    <ProductLink to={to} onClick={onClickPermalink}>
                        <Paragraph as="span" fontKeys="Value Serif/16">
                            {name}
                        </Paragraph>
                    </ProductLink>
                    {detailedColor && (
                        <Paragraph as="span" fontKeys="Value Sans/14">
                            {detailedColor}
                        </Paragraph>
                    )}
                    {(color || size) && (
                        <Paragraph as="span" color="var(--basket-text-color-light)" fontKeys="Value Sans/14">
                            {color && size ? `${color} – ${size}` : color || size}
                        </Paragraph>
                    )}
                </Info>
                <RemoveButton disabled={isLoading} onClick={() => handleRemove(line, quantity)}>
                    ({t('checkout_basket.remove')})
                </RemoveButton>
                {isLoading && <Spinner />}
            </InfoAndRemove>
            <PriceAndControls>
                <Price
                    flexDirection="column"
                    fontKeys={['Value Sans/14', null, null, null, 'Value Sans/16']}
                    gap="0 2px"
                    isOnSale={isOnSale}
                    priceWithCurrency={priceWithCurrency}
                    salePriceWithCurrency={salePriceWithCurrency}
                    textColor="var(--basket-text-color)"
                    textColorLight="var(--basket-text-color-light)"
                />
                <IncreaseOrDecrease
                    disabled={isLoading}
                    quantity={quantity}
                    decrease={() => handleDecrease(line)}
                    increase={() => handleIncrease(line)}
                />
            </PriceAndControls>
        </Wrapper>
    );
};

ProductCardMini.propTypes = {
    color: PropTypes.string,
    detailedColor: PropTypes.string,
    first: PropTypes.bool,
    imageSrc: PropTypes.string.isRequired,
    isOnSale: PropTypes.bool.isRequired,
    last: PropTypes.bool,
    line: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClickPermalink: PropTypes.func,
    priceWithCurrency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    salePriceWithCurrency: PropTypes.string.isRequired,
    size: PropTypes.string,
    to: PropTypes.string.isRequired,
};

export default ProductCardMini;