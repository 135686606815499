// Breadcrumbsbar
export const HIDE_BREADCRUMBSBAR = 'HIDE_BREADCRUMBSBAR';
export const SHOW_BREADCRUMBSBAR = 'SHOW_BREADCRUMBSBAR';

// Header
export const HIDE_HEADER = 'HIDE_HEADER';
export const SHOW_HEADER = 'SHOW_HEADER';

// Menu
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const OPEN_MENU = 'OPEN_MENU';
export const RESET_MENU = 'RESET_MENU';
