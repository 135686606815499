import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const StyledLink = styled(Link)``;

const StyledLinkWysisyg = styled(StyledLink)`
    color: var(--theme-color-active);
`;

/**
 * TextLink
 *
 * @param {number} duration - Transition duration in ms
 * @param {string} easing - Transition timing function
 * @param {bool} noParanthesis - If true, the link will not have parenthesis
 * @param {bool} noUnderline - If true, the link will not have a underline as default, it will only appear on hover
 * @param {string} size - Predefined sizes for the link.
 * @param {string} textColor - Set text color
 * @param {string} to - Link to internal or external url. Acts like a href.
 */

const TextLink = ({
    children,
    noParanthesis = false,
    size = 'md',
    textColor = 'var(--theme-color-active)',
    to,

    ...rest
}) => {
    const availableSizes = { sm: '1.4rem', md: '1.6rem' };
    const fontSize = availableSizes[size];

    const linkStyles = {
        position: 'relative',
        display: 'inlineBlock',
        fontWeight: 500,
    };

    if (to) {
        return (
            <Link color={textColor} fontSize={fontSize} to={to} {...linkStyles} {...rest}>
                {noParanthesis ? children : `(${children})`}
            </Link>
        );
    }

    return (
        <Link as="button" color={textColor} fontSize={fontSize} {...linkStyles} {...rest}>
            {noParanthesis ? children : `(${children})`}
        </Link>
    );
};

TextLink.propTypes = {
    children: PropTypes.node.isRequired,
    hoverColor: PropTypes.string,
    noParanthesis: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md']),
    textColor: PropTypes.string,
    to: PropTypes.string,
};

// Used to replace the default link when using WYSIWYG
export const TextLinkWysisyg = ({ attrs, children, ...rest }) => (
    <StyledLinkWysisyg tabIndex={attrs['tabIndex']} to={attrs['data-url']} {...rest}>
        {`(${children})`}
    </StyledLinkWysisyg>
);

TextLinkWysisyg.propTypes = {
    attrs: PropTypes.object,
};

// Used to replace the default link when using WYSIWYG and forces all links to taget="_black"
export const TextLinkWysisygForceTargetBlank = ({ attrs, children, ...rest }) => (
    <StyledLinkWysisyg
        rel="noopener noreferrer"
        tabIndex={attrs['tabIndex']}
        target="_black"
        to={attrs['data-url']}
        {...rest}
    >
        {`(${children})`}
    </StyledLinkWysisyg>
);

TextLinkWysisygForceTargetBlank.propTypes = {
    attrs: PropTypes.object,
};

export default TextLink;
