import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { media } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div', { shouldForwardProp: prop => ['error'].indexOf(prop) === -1 })`
    position: relative;
    --input-color: ${({ error }) => (error ? 'var(--theme-error-color)' : 'var(--theme-text-color)')};
    --label-color: ${({ error }) => (error ? 'var(--theme-error-color)' : 'var(--theme-input-label-color)')};

    ${media.hover} {
        :hover {
            > label {
                color: var(--label-color);
            }
        }
    }
`;

const Label = styled('label', { shouldForwardProp: prop => ['required'].indexOf(prop) === -1 })`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    transition: all ${transitions.secondary};
    cursor: text;
    user-select: none;

    ${({ required }) =>
        required &&
        `
        ::after {
            content: '*';
            display: block;
            position: absolute;
            top: 0;
            right: -7px;
        }
    `}
`;

const StyledInput = styled('input', { shouldForwardProp: prop => !['hasInput'].includes(prop) })`
    width: 100%;
    padding-bottom: 8px;
    text-decoration: none;
    border: none;
    border-radius: 0;
    font-size: 16px;
    border-bottom: 1px solid var(--theme-input-border-color);
    background-color: transparent;
    -webkit-appearance: none;
    color: var(--input-color);

    :focus {
        outline: none;
        + label {
            top: -8px;
            font-size: 12px;
            color: var(--label-color);

            ::after {
                right: -8px;
            }
        }
    }

    ${({ hasInput }) =>
        hasInput &&
        `
        outline: none;
        & + label {
            top: -8px;
            font-size: 12px;
            color: var(--label-color);

            ::after {
                right: -8px;
            }
        }`}

    ::placeholder {
        color: var(--label-color);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        box-shadow: 0 0 0 30px var(--theme-background-color) inset !important;
    }
`;

const Input = ({
    defaultValue,
    error,
    handleBlur = () => {},
    handleChange = () => {},
    label,
    name,
    required = false,
    type,
}) => {
    const [value, setValue] = useState(defaultValue);
    const fontStyles = styleObjectToProps('Value Sans/16');

    const change = e => {
        setValue(e.target.value);
        handleChange(e);
    };

    const blur = e => {
        if (!e.target.value) {
            setValue(undefined);
        }
        handleBlur(e);
    };

    return (
        <Wrapper error={error}>
            <StyledInput
                id={name}
                name={name}
                type={type}
                aria-label={label}
                required={required}
                hasInput={!!value}
                value={value}
                onChange={change}
                onBlur={blur}
                {...fontStyles}
            />
            <Label htmlFor={name} required={required} {...fontStyles}>
                {error?.message || label}
            </Label>
        </Wrapper>
    );
};

Input.propTypes = {
    color: PropTypes.string,
    defaultValue: PropTypes.string,
    error: PropTypes.exact({
        message: PropTypes.string,
        name: PropTypes.string,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

export default Input;
