import React, { useLayoutEffect, useMemo } from 'react';
import { above, media } from 'utils/mediaqueries';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import Badges from 'components/products/ProductCard/Badges';
import Events from 'libs/Events';
import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import Price from 'components/products/Price';
import ProductCardImage from 'components/products/ProductCard/ProductCardImage';
import { ProductEvents } from 'libs/Events/constants';
import PropTypes from 'prop-types';
import QuickShopButton from 'components/buttons/functionalButtons/QuickShopButton';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useIsShowroom from 'hooks/useIsShowroom';
import { withRouter } from 'react-router-dom';

const Div = styled('div')``;

const Card = styled('article', {
    shouldForwardProp: prop => ['history', 'loading', 'location', 'match', 'staticContext'].indexOf(prop) === -1,
})`
    align-self: start;
`;

const InnerWrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px 0;

    ${media.hover} {
        &:hover {
            .hover-image {
                opacity: 1;
            }

            ${above.tabletSm} {
                .quick-shop-button {
                    opacity: 1;
                }
            }
        }
    }
`;

const Name = styled(Heading)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const ProductCard = ({
    badges = [],
    category = '',
    detailedColor = '',
    hoverImage = '',
    id,
    imageSizes,
    inStock = false,
    loadImage = true,
    name,
    position,
    price: { price, salePrice, isOnSale, priceWithCurrency, salePriceWithCurrency },
    primaryImage,
    quickshop = false,
    sku,
    srcWidths,
    url,
    variationId = '',
    ...rest
}) => {
    const product = useMemo(() => ({ category, name, price, salePrice, sku, position }), [
        category,
        name,
        price,
        salePrice,
        sku,
        position,
    ]);

    const isShowroom = useIsShowroom();

    /**
     * Used for tracking
     */

    const trackHandler = () => {
        Events.trigger(ProductEvents.CLICK, { product });
    };

    const [entry, ref] = useIntersectionObserver({ triggerOnce: true });
    useLayoutEffect(() => {
        if (entry.target && entry.isIntersecting) {
            Events.trigger(ProductEvents.IMPRESSION, {
                products: [product],
            });
        }
    }, [entry, product]);

    /***/

    return (
        <Card ref={ref} {...rest}>
            <InnerWrapper>
                <Div display="flex" flexDirection="column" gap="8px 0" position="relative">
                    <AspectWrapper ratio={ratios.vertical}>
                        <Link to={url} onClick={trackHandler}>
                            <ProductCardImage
                                alt={name}
                                hoverImage={hoverImage ?? ''}
                                imageSizes={imageSizes}
                                loadImage={loadImage}
                                primaryImage={primaryImage}
                                srcWidths={srcWidths}
                                title={name}
                            />
                            {badges.length > 0 && (
                                <Badges
                                    badges={badges}
                                    flexDirection={['column', null, null, null, 'row']}
                                    left={['4px', null, null, null, '8px']}
                                    position="absolute"
                                    top="-8px"
                                />
                            )}
                        </Link>
                    </AspectWrapper>
                    {!isShowroom && quickshop && (
                        <QuickShopButton
                            bottom={['unset', null, '8px']}
                            className="quick-shop-button"
                            inStock={inStock}
                            left={['unset', null, '8px']}
                            position={['relative', null, 'absolute']}
                            variationId={variationId}
                            width={['100%', null, 'calc(100% - 16px)']}
                            opacity={['1', null, '0']}
                        />
                    )}
                </Div>
                <Link to={url} onClick={trackHandler}>
                    <Div display="flex" flexDirection="column">
                        <Div display="flex" flexDirection="column" flexGrow="1" onClick={trackHandler}>
                            {name && (
                                <Name as="h3" fontKeys="Value Serif/16" lineHeight="130%">
                                    {name}
                                </Name>
                            )}
                            {detailedColor && (
                                <Paragraph fontKeys="Value Sans/14" marginTop="2px">
                                    {detailedColor}
                                </Paragraph>
                            )}
                        </Div>
                        {!isShowroom && (
                            <Price
                                fontKeys="Value Sans/14"
                                isOnSale={!!isOnSale}
                                marginTop="2px"
                                priceWithCurrency={priceWithCurrency}
                                salePriceWithCurrency={salePriceWithCurrency}
                                textColor="currentColor"
                            />
                        )}
                    </Div>
                </Link>
            </InnerWrapper>
        </Card>
    );
};

ProductCard.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    category: PropTypes.string,
    detailedColor: PropTypes.string,
    hoverImage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    inStock: PropTypes.bool,
    loadImage: PropTypes.bool,
    name: PropTypes.string.isRequired,
    position: PropTypes.number, // tracking position
    price: PropTypes.object.isRequired,
    primaryImage: PropTypes.string.isRequired,
    quickshop: PropTypes.bool,
    sku: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    srcWidths: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    url: PropTypes.string.isRequired,
    variationId: PropTypes.string,
};

export default withRouter(ProductCard);
