import Circle from 'assets/icons/base/Circle';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Increase = ({ color = colors.black, height = '100%', width = '24px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 24 24" width={width} {...rest}>
        <Path
            d="M18 11.25v1.5h-4.5a.75.75 0 0 0-.75.75V18h-1.5v-4.5a.75.75 0 0 0-.75-.75H6v-1.5h4.5a.75.75 0 0 0 .75-.75V6h1.5v4.5a.75.75 0 0 0 .75.75H18Z"
            fill={color}
        />
        <Circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2" />
    </SVG>
);

Increase.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Increase;
