import {
    desktopCheckoutHeaderHeight,
    mobileCheckoutHeaderHeight,
    tabletCheckoutHeaderHeight,
} from 'hooks/useHeaderHeights';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import Heading from 'components/text/Heading';
import JumpingPiggy from 'assets/icons/animatedIcons/JumpingPiggy';
import Lottie from 'components/Lottie';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollButton from 'components/scrollComponents/ScrollButton';
import { TextLinkWysisyg } from 'components/text/TextLink';
import ThemeButton from 'components/buttons/ThemeButton';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const HeroContent = ({
    animation = {},
    button = {},
    cssAnimation = false,
    heading = {},
    headingFontKeys = [
        'Value Serif/40-scalable-mobile',
        null,
        'Value Serif/56-scalable-tablet',
        null,
        'Value Serif/80-scalable-desktop',
    ],
    height = '',
    includeContentMargins = true,
    lottieMaxWidth = ['50%', null, '60%', 'none'],
    orderText = '',
    scrollButton = false,
    text = '',
    ...rest
}) => {
    const clientHeight = useClientHeight();

    return (
        <MaxWidthWrapper
            includeContentMargins={includeContentMargins}
            display="flex"
            flexDirection={['column', null, null, 'row-reverse']}
            height={
                height || [
                    `calc(${clientHeight} - ${mobileCheckoutHeaderHeight}px)`,
                    null,
                    `calc(${clientHeight} - ${tabletCheckoutHeaderHeight}px)`,
                    null,
                    `calc(${clientHeight} - ${desktopCheckoutHeaderHeight}px)`,
                ]
            }
            justifyContent="space-between"
            position="relative"
            {...rest}
        >
            <Div
                alignItems="center"
                display="flex"
                justifyContent="center"
                my={['auto', null, null, 'none']}
                marginBottom={[null, null, null, '48px']}
                position="relative"
                width={['100%', null, null, 'calc(50% - 8px)']}
            >
                {animation && <Lottie animationData={animation} maxWidth={lottieMaxWidth} />}
                {cssAnimation && (
                    <Div display="flex" justifyContent={['center', null, null, 'space-between']} width="100%">
                        <JumpingPiggy width="50%" display={['none', null, null, 'flex']} />
                        <JumpingPiggy width={['70%', null, null, '30%']} delay="-0.5" />
                    </Div>
                )}
                {scrollButton && (
                    <Above
                        breakpoint="tabletLg"
                        render={() => <ScrollButton bottom="0" position="absolute" right="0" scrollDirection="down" />}
                    />
                )}
            </Div>
            <Div
                alignItems="flex-start"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                marginBottom={['12px', null, null, '48px']}
                marginTop={['12px', null, null, 'none']}
                width={['100%', null, 'calc(83.33333333% - 8px)', 'calc(50% - 8px)']} // 12/12, 10/12, 6/12
            >
                {orderText && <Paragraph paddingBottom="16px">{orderText}</Paragraph>}
                {heading && (
                    <Heading as={heading.type} fontKeys={headingFontKeys} paddingBottom={['16px', null, '24px']}>
                        {heading.text}
                    </Heading>
                )}
                {text && (
                    <Wysiwyg
                        data={text}
                        tagComponents={{ url: TextLinkWysisyg }}
                        textComponent={props => (
                            <WysiwygParagraph fontKeys={['Value Sans/16', null, 'Value Sans/20']} {...props} />
                        )}
                    />
                )}
                {button && (
                    <ThemeButton marginTop="24px" theme="outlinedBlack" to={button.to}>
                        {button.label}
                    </ThemeButton>
                )}
                {scrollButton && (
                    <Below
                        breakpoint="tabletLg"
                        render={() => (
                            <ScrollButton
                                bottom="24px"
                                position="absolute"
                                right={['16px', null, '24px']}
                                scrollDirection="down"
                            />
                        )}
                    />
                )}
            </Div>
        </MaxWidthWrapper>
    );
};

HeroContent.propTypes = {
    animation: PropTypes.object,
    button: PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string,
    }),
    cssAnimation: PropTypes.bool,
    heading: PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
    }),
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    includeContentMargins: PropTypes.bool,
    lottieMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    orderText: PropTypes.string,
    scrollButton: PropTypes.bool,
    text: wysiwygProp,
};

export default HeroContent;
