import React, { Fragment, useCallback, useLayoutEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { asArray } from 'utils/array';
import debounce from 'lodash/debounce';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';

const MarqueeOuter = styled('div')`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

const MarqueeInner = styled('div', {
    shouldForwardProp: prop => ['pauseOnHover', 'translateX'].indexOf(prop) === -1,
})`
    display: flex;
    position: relative;
    width: fit-content;
    animation-name: ${({ translateX }) => (translateX ? `marquee-${translateX}` : '')};
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    ${({ translateX }) =>
        translateX &&
        `
        @keyframes marquee-${translateX} {
            0% {
                transform: translate3d(0, 0, 0);
            }

            100% {
                transform: translate3d(-${translateX}px, 0, 0);
            }
        }
    `}

    ${({ pauseOnHover }) =>
        pauseOnHover &&
        `
        ${media.hover} {
            &:hover {
                animation-play-state: paused;
            }
        }
    `}
`;

/**
 * Marquee
 * @version 2.0
 *
 * Will create a seamless loop of animated elements (text, images etc depending on children) from left to right.
 *
 * @param {node} children -  JSX children. The children can't have a percentage width.
 * @param {object} innerStyles -  Styling applied to innerWrapper.
 * @param {boolean} pauseOnHover - Will stop the animation on hover if true.
 * @param {number} speed - The duration it takes the animation to move one pixel. Values is in milliseconds.
 * @param {boolean} updateOnPageChange - Refreshes the marquee when changing page.
 * @param {boolean} updateOnResize - Add a resize handler.
 */

const Marquee = ({
    children,
    innerStyles = {},
    pauseOnHover = false,
    speed = 15,
    updateOnPageChange = false,
    updateOnResize = true,
    ...rest
}) => {
    const outerRef = useRef(null);
    const innerRef = useRef(null);
    const prevWidth = useRef(null);
    const readyForCalculation = useRef(true); // Used to prevent infinite loops for responsive layout changes
    const calculationTimeout = useRef(null); // Used to minimize number of calculations

    const initialState = {
        duration: 0, //ms, Will be calculated so that {translateX} matches {speed}.
        translateX: 0, //px, differs depending on childrens width.
        marqueeChildren: asArray(children), // When we use SSR marqueeChildren can't contain any clones. Clones will be added in client.
    };
    const [state, setState] = useState(initialState);

    // Calculation function that handles the final animation variables
    const calculateAnimationValues = useCallback(() => {
        // The childArray is used to create clones in the client
        const childArray = asArray(children);

        // Check so that the bounding elements exists
        // Also check that the animation is reset to prevent an infinite loop
        if (outerRef.current && innerRef.current && readyForCalculation.current) {
            const innerWidth = innerRef.current.scrollWidth;
            const outerWidth = outerRef.current.scrollWidth;

            // Target width is the width of the outerWrapper + the width of two extra child-element loops
            // The two extra child element-loops will create the seamless loop effect
            const targetWidth = innerWidth * 2 + outerWidth;

            // Set readyForCalculation to false again to prevent inifinte loop
            readyForCalculation.current = false;

            let clonedChildren = [...childArray];
            let currentInnerWidth = innerWidth;

            // Add clones of children until the innerWrapper is larger than the targetWidth
            while (currentInnerWidth <= targetWidth) {
                clonedChildren = clonedChildren.concat(childArray);
                currentInnerWidth += innerWidth;
            }

            setState({
                duration: innerWidth * speed, // The animation will move {speed}px per millisecond.
                translateX: innerWidth, // The translateX will be the same as the original innerWidth.
                marqueeChildren: clonedChildren, // Add all clones as children
            });
        }
    }, [children, speed]);

    // Will trigger a new calculation once resizeing of window is done. Also adds a debounce for better performance.
    const debounceResizeHandler = debounce(() => {
        clearTimeout(calculationTimeout.current);
        calculationTimeout.current = setTimeout(() => {
            readyForCalculation.current = true;
            calculateAnimationValues();
        }, 550);
    }, 500);

    // If the animation is active, reset it
    // This will make it possible to calculate new animation values
    const resetAnimationValues = useCallback(() => {
        const currentWidth = outerRef.current.clientWidth;

        // Only trigger a new debounceResizeHandler if the width changes
        if (prevWidth.current !== currentWidth && prevWidth.current !== null) {
            if (!readyForCalculation.current) {
                setState(initialState);
            }

            debounceResizeHandler();
        }

        prevWidth.current = currentWidth;
    }, [debounceResizeHandler, initialState]);

    useLayoutEffect(() => {
        // Active resize listener if that is necessary
        if (updateOnResize) {
            window.addEventListener('resize', resetAnimationValues);
        }

        return () => {
            clearTimeout(calculationTimeout.current);
            debounceResizeHandler.cancel();
            window.removeEventListener('resize', resetAnimationValues);
        };
    }, [updateOnResize, debounceResizeHandler, resetAnimationValues]);

    useLayoutEffect(() => {
        // If false, the animation just goes on as is when changing page, for example our banner
        if (updateOnPageChange) {
            // Timeout in order to give our component time to update before calculating
            // the new inner and outer widths
            const timer = setTimeout(() => {
                // Reset state so the calculation is always based on the new children
                setState(initialState);

                // Only trigger a calculation in the client, SSR should not have any clones as children
                calculateAnimationValues();

                // Updates marqueeChildren on page change
                readyForCalculation.current = true;
            }, 25);

            return () => {
                clearTimeout(timer);
            };
        }

        calculateAnimationValues();
    }, [calculateAnimationValues]);

    return (
        <MarqueeOuter ref={outerRef} opacity={state.translateX ? 1 : 0} {...rest}>
            <MarqueeInner
                ref={innerRef}
                animationDuration={`${state.duration}ms`}
                pauseOnHover={pauseOnHover}
                translateX={state.translateX}
                {...innerStyles}
            >
                {state.marqueeChildren.map((child, index) => (
                    <Fragment key={`${child.key}${index}`}>{child}</Fragment>
                ))}
            </MarqueeInner>
        </MarqueeOuter>
    );
};

Marquee.propTypes = {
    children: PropTypes.node,
    innerStyles: PropTypes.object,
    pauseOnHover: PropTypes.bool,
    speed: PropTypes.number,
    updateOnPageChange: PropTypes.bool,
    updateOnResize: PropTypes.bool,
};

export default Marquee;
