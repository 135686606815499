import React, { useEffect, useState } from 'react';
import { above, media } from 'utils/mediaqueries';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ByonLogo from 'assets/icons/logos/ByonLogo';
import Close from 'assets/icons/Close';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import SearchContainer from 'containers/SearchContainer';
import SearchContent from 'Header/Default/Search/SearchContent';
import SearchInput from 'components/pages/search/SearchInput';
import contentMargins from 'config/theme/contentMargins';
import sanitizeUserInput from 'utils/sanitizeUserInput';
import searchAndReplaceWysiwyg from 'libs/wordpress/utils/searchAndReplaceWysiwyg';
import { setSuggestions } from 'state/models/Search/actions';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const StyledMaxWrapper = styled(MaxWidthWrapper, {
    shouldForwardProp: prop => ['hasSearchString'].indexOf(prop) === -1,
})`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    background-color: var(--search-background-color);
    color: var(--search-text-color);

    ${above.tabletSm} {
        height: ${({ hasSearchString }) => (hasSearchString ? '50vh' : '100%')};
        padding-top: 24px;
    }

    ${above.desktopSm} {
        height: ${({ hasSearchString }) => (hasSearchString ? '70vh' : '100%')};
    }

    // Force overlay to 100vh if the device has touch
    ${media.touch} {
        height: 100% !important;
    }
`;

const CloseButton = styled('button', { shouldForwardProp: prop => ['hasSearchString'].indexOf(prop) === -1 })`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
`;

const Line = styled('hr')`
    position: fixed;
    left: ${contentMargins[0]};
    bottom: 0;
    width: calc(100% - (${contentMargins[0]} * 2));
    height: 2px;
    margin: 0;
    z-index: 1;
    border-color: var(--search-border-color);

    ${above.tabletSm} {
        left: ${contentMargins[2]};
        width: calc(100% - (${contentMargins[2]} * 2));
    }
`;

const Search = () => {
    // Translation
    const { t } = useTranslation();

    // Dispatch
    const dispatch = useDispatch();

    // Selectors
    const hideOverlay = useSelector(state => state.overlay.hide);
    const { placeholder, noResultText } = useSelector(state => state.header.data.search) || {};
    const defaultSuggestions = useSelector(state => state.search.suggestions) || {};

    // Custom hooks
    const history = useHistory();
    const { search } = useLocation();

    // Search params
    const params = new URLSearchParams(search);

    // States
    const [searchstring, setSearchstring] = useState(null);

    // Replace {search} with the searchstring
    const replacedNoResultText = searchAndReplaceWysiwyg(noResultText, '{search}', searchstring);

    // Handles keyup
    useEffect(() => {
        const handleKeyup = e => {
            // ESC
            if (e.keyCode === 27) {
                history.replace();
                hideOverlay();
            }
        };

        document.addEventListener('keyup', handleKeyup);
        return () => {
            document.removeEventListener('keyup', handleKeyup);
        };
    }, []);

    // Handles search string updates
    useEffect(() => {
        // Update search value in url
        const string = sanitizeUserInput(searchstring, 100);

        if (string) {
            params.set('s', string);
            history.push({
                search: params.toString(),
            });
        }
    }, [searchstring]);

    return (
        <StyledMaxWrapper includeContentMargins hasSearchString={!!searchstring}>
            <Link to="/" onClick={() => hideOverlay()}>
                <ByonLogo
                    color="currentColor"
                    display={['block', null, 'none']}
                    height="24px"
                    margin="0 auto 64px"
                    width="102px"
                />
            </Link>
            <Div
                borderBottom="2px solid var(--search-border-color)"
                paddingBottom="8px"
                width={['100%', null, 'calc(50% - 8px)', null, 'calc(41.66666667% - 8px)']}
            >
                <SearchInput
                    focusOnMount
                    backgroundColor="var(--search-background-color)"
                    color="var(--search-text-color)"
                    editButtonStyling={{ bottom: ['10%', null, null, null, '15%'], right: '0', top: 'unset' }}
                    handleSubmit={setSearchstring}
                    placeholderColor="var(--search-placeholder-text-color)"
                    placeholderText={placeholder}
                    searchFieldStyling={{
                        ...styleObjectToProps(['Value Serif/32', null, 'Value Serif/32', null, 'Value Serif/48']),
                    }}
                    searchString={searchstring}
                    updateSearch={setSearchstring}
                />
            </Div>
            <CloseButton hasSearchString={!!searchstring} type="button" onClick={() => hideOverlay()}>
                <Close color="var(--search-text-color)" height="24px" width="24px" />
            </CloseButton>
            <SearchContainer
                pageSize={8}
                render={({ response }) => (
                    <SearchContent
                        articles={response?.articles}
                        categories={response?.categories?.slice(0, 5)}
                        defaultSuggestions={defaultSuggestions}
                        fallbackProducts={response?.fallbackProducts}
                        noResultText={replacedNoResultText}
                        products={response?.products}
                        productsHitsCount={response?.productsHitsCount}
                        searchString={searchstring}
                        suggestions={response?.suggestions}
                        updateSearch={setSearchstring}
                    />
                )}
                searchString={searchstring || ''}
                setSuggestions={suggestions => dispatch(setSuggestions(suggestions))}
                t={t}
            />
            <Line />
        </StyledMaxWrapper>
    );
};

export default Search;
