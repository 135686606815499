import { above, media } from 'utils/mediaqueries';
import { css, jsx } from '@emotion/core';

import BaseLink from 'components/base/Link';
import PropTypes from 'prop-types';
import { buttonThemeProp } from 'utils/proptypes/modules/buttonsProps';
/** @jsx jsx */
import { buttonThemes } from 'components/buttons/ThemeButtonConfig';
import colors from 'config/theme/colors';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import textStylesConfig from 'config/theme/textStyles';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    display: inline-block;
    overflow: hidden;
    --background-color: ${colors.white};
    --border-size: 2px;
    // border-corner-cut-position moves borderCornerCut boxes half outside the button container
    --border-corner-cut-position: calc(((var(--border-corner-cut-size) / 2) + (var(--border-size))) * -1);
    --border-corner-cut-size: 12px;
`;

const StyledButton = styled('button', {
    shouldForwardProp: prop => ['hover', 'dontGrowOnHover', 'theme', 'size'].indexOf(prop) === -1,
})`
    ${above.tabletSm} {
        ${media.hover} {
            :hover {
                color: ${({ hover, theme }) => (hover ? hover.color : theme.color)};
                border-color: ${({ hover, theme }) => (hover ? hover.borderColor : theme.borderColor)};
                background-color: ${({ hover, theme }) => (hover ? hover.backgroundColor : theme.backgroundColor)};
                --border-color: ${({ hover }) => hover && hover.borderColor};
                padding-left: ${({ dontGrowOnHover }) => !dontGrowOnHover && '20px'};
                padding-right: ${({ dontGrowOnHover }) => !dontGrowOnHover && '20px'};
            }
        }
    }

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    font-family: 'Value Serif Pro';
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    user-select: none;
    transition: all ${transitions.primary};
    --border-color: ${({ theme }) => theme.borderColor};
    border: var(--border-size) solid var(--border-color);
`;

const TopLeftCorner = styled('div')`
    transform: rotate(135deg);
    top: var(--border-corner-cut-position);
    left: var(--border-corner-cut-position);
`;
const TopRightCorner = styled('div')`
    transform: rotate(-135deg);
    top: var(--border-corner-cut-position);
    right: var(--border-corner-cut-position);
`;
const BottomLeftCorner = styled('div')`
    transform: rotate(45deg);
    bottom: var(--border-corner-cut-position);
    left: var(--border-corner-cut-position);
`;
const BottomRightCorner = styled('div')`
    transform: rotate(-45deg);
    bottom: var(--border-corner-cut-position);
    right: var(--border-corner-cut-position);
`;

/**
 * @version 1.1
 * @param {string} as - Change element to ex Link with: as={Link} to get the button Styling as a <a>-tag.
 * @param {object} availableSizes - Getting the avaliable sizes from the sizes object in config.
 * @param {boolean} disabled - Disables button from use.
 * @param {boolean} dontGrowOnHover - Removes the sideways growing effect on hover.
 * @param {object} styles - Button styles.
 * @param {string} theme - Change theme accordingly, can be conditioned in the component of usage.
 * @param {string} type - pass down the button type, default button.
 * @param {string} to - url to link to
 * @param {string|string[]} rest - pass down any specific styling will overwrite default settings from config.
 * @returns <StyledButton>
 */
const Button = ({
    as,
    children,
    cornerBackground,
    disabled = false,
    dontGrowOnHover = false,
    styles = {},
    target,
    theme = 'outlined',
    to,
    type = 'button',
    ...rest
}) => {
    // Update props
    theme = buttonThemes[theme];
    as = as || typeof to === 'string' ? BaseLink : undefined;

    // Deconstruct the theme object
    const { hover, disabledTheme, ...restTheme } = theme || {};

    // Styling for disabled
    if (disabled && disabledTheme) {
        restTheme[':disabled'] = {
            ...disabledTheme,
            cursor: 'not-allowed',
        };
    }

    // Text styling
    const buttonTextStyles = styleObjectToProps('Value Serif/16', textStylesConfig.paragraph);

    // Merge all button styles
    const buttonStyles = css({
        ...buttonTextStyles,
        ...restTheme,
        ...styles,
    });

    // Corner Styling
    const cornerStyles = {
        position: 'absolute',
        width: 'var(--border-corner-cut-size)',
        height: 'var(--border-corner-cut-size)',
        clipPath: 'polygon(100% 0, 0% 100%, 0 0)',
        backgroundColor: cornerBackground || 'var(--theme-background-color)',
        borderTop: `var(--border-size) solid ${disabled ? disabledTheme.borderColor : 'var(--border-color)'}`,
        transition: `all ${transitions.secondary}`, // Same as for body in global.css
    };

    return (
        <Wrapper {...rest}>
            <StyledButton
                as={as}
                css={buttonStyles}
                disabled={disabled}
                dontGrowOnHover={dontGrowOnHover}
                hover={hover}
                theme={theme}
                to={to}
                target={target}
                type={!as ? type : null}
            >
                {children}
                <TopLeftCorner {...cornerStyles} />
                <TopRightCorner {...cornerStyles} />
                <BottomLeftCorner {...cornerStyles} />
                <BottomRightCorner {...cornerStyles} />
            </StyledButton>
        </Wrapper>
    );
};

Button.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    cornerBackground: PropTypes.string,
    disabled: PropTypes.bool,
    dontGrowOnHover: PropTypes.bool,
    styles: PropTypes.object,
    target: PropTypes.string,
    theme: buttonThemeProp,
    to: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
};

export default Button;
