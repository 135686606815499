import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { below } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { injectModel } from 'state';
import overlayProps from 'utils/proptypes/overlay';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import { unlockScroll } from 'state/models/Site/actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';
import zoomOutCursor from 'assets/icons/cursors/zoom-out.svg';

const zIndexValues = {
    lowest: 1,
    lower: 800,
    low: 900,
    default: 1000,
    high: 1100,
    higher: 1200,
    highest: 9990,
};

const excludedProps = ['zIndex', 'transparent'];

export const Wrapper = styled('div', { shouldForwardProp: prop => excludedProps.indexOf(prop) === -1 })`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    transition: transform ${transitions.secondary};
    z-index: ${({ zIndex }) => (isNaN(zIndex) ? zIndexValues[zIndex] : zIndex)};

    > .slide-in {
        transition: all ${transitions.secondary};
    }

    > .fade-in {
        transform: translateY(24px);
        opacity: 0;
        transition: all ${transitions.secondary};
    }
    > .top {
        top: 0;
        transform: translateY(-100%);
        &--right {
            top: 0;
            right: 0;
            transform: translateY(-100%);
        }
        &--left {
            top: 0;
            left: 0;
            transform: translateY(-100%);
        }
    }
    > .bottom {
        bottom: 0;
        transform: translateY(100%);
        &--right {
            bottom: 0;
            right: 0;
            transform: translateY(100%);
        }
        &--left {
            bottom: 0;
            left: 0;
            transform: translateY(100%);
        }
    }
    > .right {
        right: 0;
        transform: translateX(100%);
        &--top {
            right: 0;
            top: 0;
            transform: translateX(100%);
        }
        &--bottom {
            right: 0;
            bottom: 0;
            transform: translateX(100%);
        }
    }
    > .left {
        left: 0;
        transform: translateX(-100%);
        &--top {
            left: 0;
            top: 0;
            transform: translateX(-100%);
        }
        &--bottom {
            left: 0;
            bottom: 0;
            transform: translateX(-100%);
        }
    }
    > .center {
        width: auto;
        height: auto;
        margin: auto;
    }
    &.entered {
        ${below.md} {
            overflow-y: ${({ filter }) => (filter ? 'scroll' : 'unset')};
        }
        > .fade-in {
            transform: translateY(0);
            opacity: 1;
        }
        > .right,
        > .right--top,
        > .right--bottom,
        > .left,
        > .left--top,
        > .left--bottom {
            transform: translateX(0);
        }
        > .top,
        > .top--left,
        > .top--right,
        > .bottom,
        > .bottom--left,
        > .bottom--right {
            transform: translateY(0);
        }
    }
    &.exiting {
        /* Exiting styles */
        /* Same as default styles */
    }
    &.entering {
        /* Entering styles */
        /* Same as default styles */
    }
`;

const OverlayBackground = styled('div', {
    shouldForwardProp: prop => excludedProps.indexOf(prop) === -1,
})`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${colors.opacity.blue.high};
    opacity: 0;
    transition: opacity ${transitions.secondary};
    z-index: -1;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(16px);
    cursor: url('${zoomOutCursor}') 40 40, pointer;

    &.entered {
        opacity: ${({ transparent }) => (!transparent ? 1 : 0)};
    }

    &.exiting {
        /* Exiting styles */
        /* Same as default styles */
    }
    &.entering {
        /* Entering styles */
        /* Same as default styles */
    }
`;

const OverlayWrapper = ({
    children,
    className = '',
    overlay,
    overlayName,
    transparent = false,
    zIndex = 'default',
}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    //Sets previous location-history
    const prevLocationRef = useRef(location.pathname);

    //Checks if the new targeted location matches the old one. If it doesn't, it closes the overlay.
    useEffect(() => {
        if (location.pathname !== prevLocationRef.current) {
            overlay.hide(overlayName);
            dispatch(unlockScroll());
        }
    }, [location]);

    const closeOverlay = () => {
        overlay.hide(overlayName);
        dispatch(unlockScroll());
    };

    return (
        <Wrapper className={className} zIndex={zIndex}>
            <OverlayBackground
                className={`${className} ${overlayName}`}
                transparent={transparent}
                zIndex={zIndex}
                onClick={closeOverlay}
            />
            {children}
        </Wrapper>
    );
};

OverlayWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    overlay: overlayProps.isRequired,
    overlayName: PropTypes.string.isRequired,
    transparent: PropTypes.bool,
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withRouter(injectModel('overlay')(OverlayWrapper));
