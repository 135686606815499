import ColorSelectItem from './ColorSelectItem';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import SizeSelectItem from './SizeSelectItem';
import styled from 'libs/styled';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { variantSelectorConfig } from 'config/variants';

const Wrapper = styled('div')`
    width: 100%;
    border-top: 2px solid var(--theme-border-color);
    padding-top: 12px;
`;

const Ul = styled('ul')`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

const components = {
    color: ColorSelectItem,
    size: SizeSelectItem,
};

const VariantSelector = ({ handleMissingCombination, selectionIsNeeded = false, variants = {}, ...rest }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    // Hide variant selector if the current variant has no related variants
    const hasRelatedVariants = Object.keys(variantSelectorConfig).some(key => variants[key].length > 1);
    if (!selectionIsNeeded && !hasRelatedVariants) {
        return null;
    }

    return Object.keys(variants).map(type => {
        if (variants[type].length === 0 || !(type in variantSelectorConfig)) {
            return null;
        }

        const { label, componentType, selectionLabel } = variantSelectorConfig[type];
        const Component = components[componentType];
        const currentVariant = variants[type].find(variant => variant.currentVariant);

        // If the variant doesn't exist in the same combination as the previously selected variant, display selectionLabel
        const variantName = currentVariant ? currentVariant.name : t(selectionLabel);

        return (
            <Wrapper key={type} {...rest}>
                <Paragraph marginBottom="16px">{`${t(`${label}`)} — ${variantName}`}</Paragraph>
                <Ul>
                    {variants[type].map(variant => {
                        /* Enable onClick if we don't have a link or we do but selection is needed 
                        and we have switched back to the original product */
                        const enableOnClick =
                            (!variant.to || (variant.to === pathname && selectionIsNeeded)) && handleMissingCombination;

                        return (
                            <Component
                                key={variant.id}
                                variant={variant}
                                onClick={() => {
                                    if (enableOnClick) {
                                        handleMissingCombination(variant);
                                    }
                                }}
                            />
                        );
                    })}
                </Ul>
            </Wrapper>
        );
    });
};

VariantSelector.propTypes = {
    handleMissingCombination: PropTypes.func,
    selectionIsNeeded: PropTypes.bool,
    variants: PropTypes.object,
};

export default VariantSelector;
