import React, { useRef, useState } from 'react';

import AdyenDropInContainer from 'containers/AdyenDropInContainer';
import AdyenFormWrapper from 'components/pages/checkout/CheckoutView/Payment/paymentMethods/Adyen/AdyenFormWrapper';
import Below from 'components/breakpoints/Below';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import ShippingForm from 'components/pages/checkout/CheckoutView/Payment/Shipping';
import Summary from 'components/pages/checkout/CheckoutView/Summary';
import { inServer } from 'config/constants';
import { scrollTo } from 'utils/scrollFunctions';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Div = styled('div')``;

const Line = styled('hr')`
    width: 100%;
    height: 2px;
    margin: 0;
    border-color: var(--checkout-border-color);
`;

const AdyenPayment = ({ tracking = {} }) => {
    const [isLoaded, setIsLoading] = useState(true);
    const [showShippingAndPayment, setShowShippingAndPayment] = useState(false);
    const heading = useSelector(state => state.page.data.checkout.payment?.heading);
    const shippingSectionRef = useRef(null);
    const scrollToShippingSection = () => {
        shippingSectionRef.current && scrollTo({ top: !inServer ? shippingSectionRef.current.offsetTop : 0 });
    };

    // Styling used for all headings
    const headingFontKeys = [
        'Value Serif/24',
        null,
        'Value Serif/32-scalable-tablet',
        null,
        'Value Serif/32-scalable-desktop',
    ];

    return (
        <Div marginBottom={['48px', null, '80px', null, '104px']}>
            <Line />
            <Div py="16px">
                <AdyenFormWrapper
                    headingFontKeys={headingFontKeys}
                    isLoaded={isLoaded}
                    scrollToShippingSection={scrollToShippingSection}
                    setShowShippingAndPayment={setShowShippingAndPayment}
                    showShippingAndPayment={showShippingAndPayment}
                    tracking={tracking}
                />
            </Div>
            <Div display={isLoaded ? 'block' : 'none'} ref={shippingSectionRef}>
                <Line />
                <Div py="16px">
                    <ShippingForm headingFontKeys={headingFontKeys} showShippingAndPayment={showShippingAndPayment} />
                </Div>
                <Line />
                <Div py="16px">
                    <Heading fontKeys={headingFontKeys} type={heading.type}>
                        03. {heading.text}
                    </Heading>
                    <Div display={showShippingAndPayment ? 'block' : 'none'} marginTop="36px">
                        <Below
                            breakpoint="desktopSm"
                            render={() => <Summary marginBottom={['24px', null, '48px']} showInfoBox={false} />}
                        />
                        <AdyenDropInContainer
                            render={({ loading }) => {
                                setIsLoading(!loading);
                                return <>{loading && <p>loading</p>}</>;
                            }}
                        />
                        <div id="dropin-container" />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

AdyenPayment.propTypes = {
    tracking: PropTypes.shape({
        trigger: PropTypes.bool,
        setTrigger: PropTypes.func,
    }),
};

export default AdyenPayment;
