import Above from 'components/breakpoints/Above';
import Breadcrumbs from 'components/Breadcrumbs';
import InformationSection from './InformationSection';
import ProductImages from './ProductImages';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;

    ${above.desktopSm} {
        flex-direction: row;
    }
`;

const ProductHero = ({
    attributesLabel = '',
    badges = [],
    basketProductData = {},
    breadcrumbs = [],
    characteristics = [],
    description = '',
    images = [],
    informationLabel = '',
    inStock = false,
    comingSoon = false,
    name = '',
    price = '',
    relatedVariationAttributes = [],
    sku = '',
    usps = [],
    variantData = {},
    variationId = '',
}) => {
    const bannerHeight = useHeaderHeights().banner;

    return (
        <Wrapper marginTop={[null, null, null, null, `${bannerHeight}px`]}>
            <Above
                breakpoint="desktopSm"
                render={() => (
                    <Breadcrumbs
                        currentTitle={name}
                        items={breadcrumbs}
                        left="24px"
                        position="absolute"
                        top={`calc(${bannerHeight}px + 24px)`}
                        zIndex={zIndex.menuMobile}
                    />
                )}
            />
            <ProductImages badges={badges} images={images} sku={sku} />
            <InformationSection
                attributesLabel={attributesLabel}
                badges={badges}
                basketProductData={basketProductData}
                characteristics={characteristics}
                comingSoon={comingSoon}
                description={description}
                informationLabel={informationLabel}
                inStock={inStock}
                name={name}
                price={price}
                relatedVariationAttributes={relatedVariationAttributes}
                usps={usps}
                variantData={variantData}
                variationId={variationId}
            />
        </Wrapper>
    );
};

ProductHero.propTypes = {
    attributesLabel: PropTypes.string,
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    basketProductData: PropTypes.object,
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    characteristics: PropTypes.array,
    comingSoon: PropTypes.bool,
    description: PropTypes.string,
    images: PropTypes.array,
    inStock: PropTypes.bool,
    informationLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    price: PropTypes.shape({
        isOnSale: PropTypes.bool,
        priceWithCurrency: PropTypes.string.isRequired,
        salePriceWithCurrency: PropTypes.string,
    }),

    relatedVariationAttributes: PropTypes.array,
    sku: PropTypes.string,
    usps: PropTypes.array,
    variantData: PropTypes.object,
    variationId: PropTypes.string,
};

export default ProductHero;
