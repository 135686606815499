import React, { useRef, useState } from 'react';
import styled from 'libs/styled';
import PropTypes from 'prop-types';
import Info from 'assets/icons/Info';
import Paragraph from 'components/text/Paragraph';
import colors from 'config/theme/colors';
import { above } from 'utils/mediaqueries';

const InfoboxContainer = styled('div')`
    cursor: help;
`;

const Infobox = styled('div')`
    width: 100%;
    max-width: 285px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 12px;
    white-space: normal;
    z-index: 1;

    ${above.tabletSm} {
        max-width: 293px;
        left: unset;
        right: 0;
        padding: 16px;
    }

    ${above.desktopSm} {
        left: 0;
    }
`;

const Toolip = ({ content, closeDelay = 250 }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const closeDelayTimeout = useRef();

    const handleMouseEnter = () => {
        if (closeDelayTimeout.current) {
            clearTimeout(closeDelayTimeout.current);
        }
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        closeDelayTimeout.current = setTimeout(() => {
            setTooltipVisible(false);
        }, closeDelay);
    };

    const handleClick = () => {
        if (closeDelayTimeout.current) {
            clearTimeout(closeDelayTimeout.current);
        }
        setTooltipVisible(prevState => !prevState);
    };

    return (
        <InfoboxContainer
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleClick()}
        >
            <Info width="14px" height="14px" marginTop="4px" color="var(--default-text-color-light)" />
            {tooltipVisible && (
                <Infobox>
                    <Paragraph fontKeys={['Value Sans/14']} as="span">
                        {content}
                    </Paragraph>
                </Infobox>
            )}
        </InfoboxContainer>
    );
};

Toolip.propTypes = {
    closeDelay: PropTypes.number,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Toolip;
