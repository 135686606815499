import React, { useEffect } from 'react';
import { hideBreadcrumbsBar, hideHeader, showBreadcrumbsBar, showHeader } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import Above from 'components/breakpoints/Above';
import BasketButton from 'Header/Default/Menu/buttons/BasketButton';
import Below from 'components/breakpoints/Below';
import BreadcrumbsBar from 'components/Breadcrumbs/BreadcrumbsBar';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Menu from 'Header/Default/Menu';
import MenuButton from './Menu/buttons/MenuButton';
import SearchButton from 'Header/Default/Menu/buttons/SearchButton';
import TopLevel from 'Header/Default/Menu/MenuDesktop/TopLevel';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';
import useIsShowroom from 'hooks/useIsShowroom';
import zIndex from 'config/theme/z-index';

const Header = styled('header')`
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: ${zIndex.header};
    background: var(--header-background-color);
    color: var(--header-text-color);
    transition: transform ${transitions.primary};
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`;

const IconsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Default = () => {
    const dispatch = useDispatch();
    const headerHeights = useHeaderHeights();
    const headerIsVisible = useSelector(state => state.header.state.headerIsVisible);
    const breadcrumbsBarIsVisible = useSelector(state => state.header.state.breadcrumbsBarIsVisible);
    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const pageTemplate = useSelector(state => state.page.template);
    const isFrontPage = pageTemplate === 'frontpage';
    const hasOverlay = useSelector(state => state.overlay.current);
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const isShowroom = useIsShowroom();

    // Hide BreadcrumbsBar in desktop
    if (isAboveDesktopSm) {
        dispatch(hideBreadcrumbsBar());
    }

    // Handle header and breadcrumbs appearance
    useEffect(() => {
        const updateHeader = () => {
            const bottomOffset = 100;
            let newShowBreadcrumbsState = breadcrumbsBarIsVisible;
            let newIsHeaderVisible = headerIsVisible;

            const aboveTheFold = window.pageYOffset < window.outerHeight;
            const bottomOfPage = window.innerHeight + window.pageYOffset + bottomOffset > document.body.offsetHeight;

            if (bottomOfPage) {
                newIsHeaderVisible = false;
            } else {
                newIsHeaderVisible = true;
            }

            if (!isAboveDesktopSm) {
                if (aboveTheFold) {
                    newShowBreadcrumbsState = true;
                } else {
                    newShowBreadcrumbsState = false;
                }

                if (newShowBreadcrumbsState !== breadcrumbsBarIsVisible) {
                    if (newShowBreadcrumbsState) {
                        dispatch(showBreadcrumbsBar());
                    } else {
                        dispatch(hideBreadcrumbsBar());
                    }
                }
            }

            if (newIsHeaderVisible !== headerIsVisible) {
                if (newIsHeaderVisible) {
                    dispatch(showHeader());
                } else {
                    dispatch(hideHeader());
                }
            }
        };

        window.addEventListener('scroll', updateHeader);
        return () => window.removeEventListener('scroll', updateHeader);
    }, [breadcrumbsBarIsVisible, headerIsVisible]);

    return (
        <>
            <Below
                breakpoint="desktopSm"
                render={() => <BreadcrumbsBar show={!isFrontPage && breadcrumbsBarIsVisible} />}
            />
            <Header
                boxShadow={!hasOverlay && !menuIsOpen && `0px -4px 16px ${colors.opacity.black.high}`}
                height={[
                    `${headerHeights.mobile}px`,
                    null,
                    `${headerHeights.tablet}px`,
                    null,
                    `${headerHeights.desktop}px`,
                ]}
                transform={!headerIsVisible ? 'translateY(100%)' : 'none'}
            >
                <StyledMaxWidthWrapper includeContentMargins>
                    <Above breakpoint="desktopMd" render={() => <TopLevel />} />
                    <Below breakpoint="desktopMd" render={() => <MenuButton />} />
                    <IconsWrapper>
                        <SearchButton />
                        {!isShowroom && <BasketButton />}
                    </IconsWrapper>
                </StyledMaxWidthWrapper>
            </Header>
            <Menu />
        </>
    );
};

export default Default;
