import Close from 'assets/icons/Close';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Button = styled('button')`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${media.hover} {
        &:hover {
            .remove-filter-text {
                color: var(--default-color-active);
            }
        }

        &:hover {
            .close-icon {
                transform: rotate(90deg);
                .close-icon-path {
                    fill: var(--default-color-active) !important;
                }
            }
        }

        &:active {
            .close-icon {
                transform: rotate(360deg);
            }
        }
    }

    ${({ active }) =>
        active &&
        `
        .close-icon {
                transform: rotate(45deg);
            }
    `}
`;

const RemoveFilterButton = ({ children, handleClick = () => {}, type = 'button', withIcon = false, ...rest }) => (
    <Button type={type} onClick={() => handleClick()} {...rest}>
        <Paragraph
            as="span"
            className="remove-filter-text"
            fontKeys={['Value Serif/20', null, null, null, 'Value Serif/24']}
            transition={`color ${transitions.primary.medium}`}
        >
            {children}
        </Paragraph>
        {withIcon && (
            <Close height="16px" ml="4px" transition={`transform ${transitions.primary.medium}`} width="16px" />
        )}
    </Button>
);

RemoveFilterButton.propTypes = {
    handleClick: PropTypes.func,
    type: PropTypes.string,
    withIcon: PropTypes.bool,
};

export default RemoveFilterButton;
