import PropTypes from 'prop-types';
import React from 'react';
import { srcProp } from 'utils/proptypes/modules/textProps';
import styled from 'libs/styled';

/** EXAMPLE
 <div> (Wrappper Outside of Component)
     <div> (Background Image)
        (Children)
         <p>test</p>
         <p>test</p>
         <p>test</p>
         <p>test</p>
     </div>
 </div>
 */

/** EXAMPLE with Absolute (EXPERIMENTAL - Has some problem with zIndex etc. )
 <div> (Wrappper Outside of Component) <-- Must be set to "position: relative;" and "z-index: 0" -->
     <div /> (Background Image)
     <p>test</p>
     <p>test</p>
     <p>test</p>
     <p>test</p>
 </div>
 */

const BackgroundImageStyle = styled('div', {
    shouldForwardProp: prop => ['absolute', 'src'].indexOf(prop) === -1,
})`
    background-repeat: no-repeat;
    ${props =>
        props.absolute ? `z-index: -1; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);` : ''}
`;

const BackgroundImage = ({ absolute, src, ...rest }) => {
    let sources = src;
    if (!Array.isArray(src)) {
        sources = [src];
    }
    sources = sources.map(source => `url(${source});`);

    return <BackgroundImageStyle absolute={absolute} backgroundImage={sources} {...rest} />;
};
BackgroundImage.propTypes = {
    absolute: PropTypes.bool,
    backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    backgroundPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    backgroundSize: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    src: srcProp,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

BackgroundImage.defaultProps = {
    absolute: false,
    backgroundColor: 'transparent',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
};

export default BackgroundImage;
