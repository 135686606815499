import React, { useEffect, useRef } from 'react';

import FilterBar from 'components/Filter/FilterBar';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import NoResultComponent from 'components/NoResultComponent';
import Pagination from 'components/Pagination';
import ProductGrid from 'components/products/ProductGrid';
import PropTypes from 'prop-types';
import SelectedFilter from 'components/Filter/SelectedFilter';
import { inServer } from 'config/constants';
import { scrollTo } from 'utils/scrollFunctions';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    overflow-anchor: none;
`;

const NumberOfProducts = styled('div')`
    position: relative;

    > span {
        position: absolute;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
        color: var(--default-text-color-light);
        text-transform: lowercase;
        z-index: ${zIndex.logoMobile - 1};
    }
`;

const ProductListing = ({ noResultText, response, searchString }) => {
    const { t } = useTranslation();
    const allProducts = useSelector(state => state.products.allProducts || {});
    const overlay = useSelector(state => state.overlay);
    const postType = useSelector(state => state.page.data?.post_type || {});
    const wrapperRef = useRef({});

    const {
        clear = {},
        filters,
        hitsCount,
        page,
        pageSize,
        productsCount,
        pagination = {},
        selectedFilters = [],
        sorts = [],
        handleChange = {},
    } = response;

    useEffect(() => {
        if (overlay.current === 'filterOverlay') {
            overlay.update('filterOverlay', {
                clear,
                filters,
                handleChange,
                hitsCount,
            });
        }
    }, [filters]);

    const openFilterOverlay = () => {
        overlay.show('filterOverlay', {
            clear,
            filters,
            handleChange,
            hitsCount,
        });
    };

    const clickPagination = () => {
        // Scroll back to top of wrapper on pagination
        if (wrapperRef && wrapperRef.current) {
            scrollTo({
                smooth: false,
                // -65 to align properly with position sticky filter
                top: wrapperRef.current.offsetTop - 65,
            });
        }
    };

    // Transform sorts to fit the general radio buttons for forms
    const transformedSorts = sorts.map(({ index, label, selected }) => ({
        id: index,
        selected,
        text: label,
    }));

    // Use response.products on server because allProducts is empty sometimes on server
    const productsToUse = inServer ? response.products : allProducts;
    const loading = Object.keys(response).length === 0 && response.constructor === Object;

    return (
        <Wrapper ref={wrapperRef}>
            {hitsCount ? (
                <NumberOfProducts>
                    <span>{`${hitsCount} ${
                        hitsCount !== 1 ? t('filter_config.products') : t('filter_config.product')
                    }`}</span>
                </NumberOfProducts>
            ) : null}
            <FilterBar
                handleChange={handleChange}
                hitsCount={hitsCount}
                openFilterOverlay={openFilterOverlay}
                sorts={transformedSorts}
            />
            {selectedFilters.length > 0 && (
                <SelectedFilter
                    clearFilter={clear.filter}
                    clearByKey={clear.byKey}
                    handleChangeValue={handleChange.filter.value}
                    selectedFilters={selectedFilters}
                />
            )}
            <MaxWidthWrapper includeContentMargins>
                {productsToUse && productsToUse.length > 0 ? (
                    <ProductGrid products={productsToUse} />
                ) : (
                    <NoResultComponent loading={loading} searchString={searchString} text={noResultText} />
                )}
            </MaxWidthWrapper>
            {pagination?.pages?.length > 1 && (
                <Pagination
                    currentIndex={page}
                    handleClick={clickPagination}
                    marginTop="64px"
                    maxSiblings={2}
                    message={t('pagination.products_pagination')}
                    pageItemCount={productsCount}
                    pages={pagination.pages}
                    pageSize={pageSize}
                    totalItemCount={hitsCount}
                    usePaginationMeta={postType === 'ecommerce_category'}
                />
            )}
        </Wrapper>
    );
};

ProductListing.propTypes = {
    noResultText: PropTypes.oneOfType([PropTypes.string, wysiwygProp]),
    response: PropTypes.shape({
        clear: PropTypes.object,
        filters: PropTypes.arrayOf(PropTypes.object),
        handleChange: PropTypes.object,
        hasMore: PropTypes.bool,
        hitsCount: PropTypes.number,
        page: PropTypes.number,
        pageSize: PropTypes.number,
        pagination: PropTypes.exact({
            nextUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
            pages: PropTypes.arrayOf(PropTypes.object),
            previousUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        }),
        productsCount: PropTypes.number,
        products: PropTypes.arrayOf.isRequired,
        selectedFilters: PropTypes.arrayOf(PropTypes.object),
        sorts: PropTypes.arrayOf(
            PropTypes.exact({ index: PropTypes.string, label: PropTypes.string, selected: PropTypes.bool })
        ),
    }).isRequired,
    searchString: PropTypes.string,
};

export default ProductListing;
