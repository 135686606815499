import Badges from 'components/products/ProductCard/Badges';
import Below from 'components/breakpoints/Below';
import ProductGallery from './ProductGallery';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    height: auto;
    margin-bottom: 36px;
    margin-left: -16px;
    width: calc(100% + 32px);
    top: 0;

    ${above.desktopSm} {
        margin-left: -24px;
        width: calc(66.666% + 24px);
        z-index: ${zIndex.imageGallery};
    }
`;

// Responsive logic for images because the Image service wants parameters for image sizes
const imageConfig = {
    imageWidths: [414, 768, 512, 640, 683, 960, 1024, 1280, 1366, 1536, 1920, 2560],
    imageSizes: ['100vw', null, '50vw'],
    srcWidths: [375, 720, 768, 960, 1024, 1300],
};

const { imageWidths, imageSizes, srcWidths } = imageConfig;

const ProductImages = ({ badges = [], images = [] }) => {
    if (!images.length) {
        return null;
    }

    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    // Loop through media array and add widths to each object
    const imagesWithWidths = images.map(image => {
        return {
            ...image,
            widths: imageWidths,
        };
    });

    return (
        <Wrapper position={['relative', null, null, null, 'sticky']}>
            <ProductGallery imageConfig={{ srcHeights, srcWidths, imageSizes }} images={imagesWithWidths} />
            {badges.length > 0 && (
                <Below
                    breakpoint="desktopSm"
                    render={() => <Badges badges={badges} bottom="-12px" left="12px" position="absolute" />}
                />
            )}
        </Wrapper>
    );
};

ProductImages.propTypes = {
    badges: PropTypes.array,
    images: PropTypes.array,
};

export default ProductImages;