import { addTheme, removeTheme } from 'state/models/Site/actions';

import { ColorThemes } from 'config/theme/themes';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import usePrevious from 'hooks/usePrevious';

/**
 * Adds and removes themes from Redux.
 * Used in theme modules.
 */

const useSiteTheme = (theme: ColorThemes, animateIn: boolean) => {
    const dispatch = useDispatch();
    const prevAnimateIn = usePrevious(animateIn) || false;
    const shouldUpdate = animateIn !== prevAnimateIn;

    useEffect(() => {
        if (shouldUpdate) {
            if (animateIn) {
                dispatch(addTheme(theme));
            } else {
                dispatch(removeTheme(theme));
            }
        }
         
    }, [animateIn]);
};

export default useSiteTheme;
