import Arrow from 'assets/icons/Arrow';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import Heading from 'components/text/Heading';
import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import ratios from 'config/theme/ratios';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    background-color: var(--popup-background-color);
    color: var(--popup-text-color);

    ${above.desktopSm} {
        flex-direction: column;
        padding: 24px 24px 72px 24px;
    }
`;

const ButtonWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${above.desktopSm} {
        flex-direction: column;
        height: 100%;
    }
`;

const AddedToBasketHeading = styled(Heading)`
    display: flex;
    width: 50%;
    margin-left: 12px;

    ${above.desktopSm} {
        width: auto;
        height: 50%;
        writing-mode: vertical-rl;
        margin: 24px 0 0;
    }
`;

const GoToBasketButton = styled('button')`
    display: flex;
    gap: 0 16px;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    line-height: 1;

    ${above.desktopSm} {
        flex-direction: column;
        gap: 24px 0;
        width: auto;
        height: 50%;
    }
`;

const AddedToBasket = ({ closePopup = () => {}, openBasket = () => {}, product = {} }) => {
    const { imageSrc, name, to } = product;
    const { t } = useTranslation();
    const goToBasketFontStyling = styleObjectToProps('Value Sans/16');

    return (
        <Wrapper>
            <Link to={to} onClick={() => closePopup()}>
                <AspectWrapper
                    backgroundColor="var(--default-placeholder-color)"
                    ratio={ratios.vertical}
                    width={['42px', null, null, null, '70px']}
                    overflow="hidden"
                >
                    <Image
                        alt={name}
                        fm="jpg"
                        sizes={['42px', null, null, null, '70px']}
                        src={{ url: imageSrc, width: [75, 150, 225] }}
                        title={name}
                    />
                </AspectWrapper>
            </Link>
            <ButtonWrapper>
                <AddedToBasketHeading fontKeys={['Value Serif/20', null, null, null, 'Value Serif/32']}>
                    {t('checkout_basket.added_to')}
                    <br />
                    {t('checkout_basket.basket')}
                </AddedToBasketHeading>
                <GoToBasketButton
                    type="button"
                    onClick={() => {
                        closePopup();
                        openBasket();
                    }}
                    {...goToBasketFontStyling}
                >
                    {t('checkout_basket.basket')}
                    <Arrow
                        height={['24px', null, null, null, '48px']}
                        marginTop={['-4px', null, null, null, '0']}
                        width={['24px', null, null, null, '48px']}
                    />
                </GoToBasketButton>
            </ButtonWrapper>
        </Wrapper>
    );
};

AddedToBasket.propTypes = {
    closePopup: PropTypes.func.isRequired,
    openBasket: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

export default AddedToBasket;
