interface Value {
    label: string;
    prefix?: string;
    selected: boolean;
    value: string;
}

interface Filter {
    key: string;
    label: string;
    option: string;
    priority: number;
    prefix?: string;
    totalSelected?: number;
    selected?: any;
    values?: Value[] | Filter[] | number[];
}

/**
 * Sorts filter
 *
 * @param {array} filters - Unsorted filters
 * @param {string} sortBy - Filters should be sorted by
 * @returns {array} - Returns a sorted array of filters
 */

export const getSortedFilters = (filters: Filter[], sortBy: string) => {
    if (!filters || filters.length < 1) {
        return [];
    }

    switch (sortBy) {
        case 'alphabetically':
            return filters.sort((a: Filter, b: Filter) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
        case 'priority':
            return filters.sort((a: Filter, b: Filter) => {
                if (a.priority && b.priority) {
                    return a.priority - b.priority;
                }
                return 0;
            });

        default:
            return filters;
    }
};

/**
 * Transforms filters
 *
 * @param {array} filters - Raw filter data
 * @returns {array} - Returns a transformed array of filters
 */

export const getTransformedFilters = (filters: Filter[], t: (translation: string) => string): Filter[] => {
    let transformedFilters: Filter[] = [];

    // Start of "Group dimensions and weight together" -->
    const measureFilters = filters.filter(
        filter =>
            filter.key === '_diameter_value' ||
            filter.key === '_height_value' ||
            filter.key === '_volume_value' ||
            filter.key === '_width_value'
    );

    const withoutMeasureFilters = filters.filter(
        filter =>
            filter.key !== '_diameter_value' &&
            filter.key !== '_height_value' &&
            filter.key !== '_volume_value' &&
            filter.key !== '_width_value'
    );

    transformedFilters = [
        ...withoutMeasureFilters,
        {
            key: 'measure',
            label: t('filter_config.measures'),
            option: 'multirangeGroup',
            priority: 4,
            values: [...measureFilters],
        },
    ];

    // <-- End of "Group dimensions and weight together"

    return transformedFilters;
};
