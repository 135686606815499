/* eslint-disable camelcase */
import PropTypes from 'prop-types';

export const headingSizeProp = PropTypes.oneOf(['xs', 'sm', 'md', 'lg']);

export const headingTagProp = PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']);

export const headingObjectProp = PropTypes.shape({
    text: PropTypes.string,
    type: headingTagProp,
});

export const linkProp = PropTypes.exact({
    to: PropTypes.string,
    label: PropTypes.string,
});

export const wysiwygProp = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])),
    PropTypes.string,
]);

export const srcProp = PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array]);

export const textContentProp = PropTypes.arrayOf(
    PropTypes.oneOfType([
        PropTypes.shape({
            heading: PropTypes.string,
            headingTag: headingTagProp,
            horizontalAlignment: PropTypes.string,
            layout: PropTypes.string,
        }),
        PropTypes.shape({
            layout: PropTypes.string,
            horizontalAlignment: PropTypes.string,
            text: wysiwygProp,
        }),
    ])
);

export const cmsTextContentProp = PropTypes.arrayOf(
    PropTypes.oneOfType([
        PropTypes.exact({
            layout: PropTypes.string,
            heading: headingObjectProp,
        }),
        PropTypes.exact({
            layout: PropTypes.string,
            sub_heading: headingObjectProp,
        }),
        PropTypes.exact({
            layout: PropTypes.string,
            ingress: wysiwygProp,
        }),
        PropTypes.exact({
            layout: PropTypes.string,
            paragraph: wysiwygProp,
        }),
    ])
);
