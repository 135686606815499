import Arrow from 'assets/icons/Arrow';
import PropTypes from 'prop-types';
import React from 'react';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import { above } from 'utils/mediaqueries';
import { inServer } from 'config/constants';
import { scrollTo } from 'utils/scrollFunctions';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Button = styled('button')`
    display: flex;
    white-space: nowrap;
    > span {
        margin-right: 8px;
        display: none;
        ${above.desktopSm} {
            display: block;
        }
    }
`;

const IconWrapper = styled('div')`
    animation: upAndDown 1000ms ease-in-out infinite alternate;

    @keyframes upAndDown {
        from {
            transform: translateY(10%);
        }
        to {
            transform: translateY(-10%);
        }
    }
`;

/**
 * ScrollButton
 *
 * @param {string|string[]} fontKeys - font styles for text
 * @param {string} iconWidth - Icon width
 * @param {string} scrollDirection - Option 'up'/'down', which direction icon and scroll function will face
 * @param {number} scrollDistance - How far down to scroll
 *
 */

const ScrollButton = ({
    fontKeys = 'Value Sans/16',
    iconWidth = '16px',
    scrollDirection = 'up',
    scrollDistance = !inServer ? window.innerHeight : 99999,
    ...rest
}) => {
    const transform = scrollDirection === 'down' ? 'rotate(90deg)' : 'rotate(-90deg)';
    const { t } = useTranslation();
    const itemsTextStyles = styleObjectToProps(fontKeys);

    const handleClick = e => {
        e.preventDefault();

        if (scrollDirection === 'up') {
            scrollTo({ top: 0 });
        } else if (scrollDirection === 'down') {
            scrollTo({ top: scrollDistance });
        }
    };

    return (
        <Button onClick={e => handleClick(e)} {...rest}>
            <TextLinkUnderline as="span" color="currentColor" display="inline-block" {...itemsTextStyles}>
                {scrollDirection === 'down' ? t('header.scroll_down') : t('footer.scroll_to_top')}
            </TextLinkUnderline>
            <IconWrapper>
                <Arrow color="currentColor" width={iconWidth} transform={transform} />
            </IconWrapper>
        </Button>
    );
};

ScrollButton.propTypes = {
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf([PropTypes.string])]),
    iconWidth: PropTypes.string,
    scrollDirection: PropTypes.oneOf(['up', 'down']),
    scrollDistance: PropTypes.number,
};
export default ScrollButton;
