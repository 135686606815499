import Close from 'assets/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import RecursiveMenuMobileView from './RecursiveMenuMobileView';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: ${zIndex.menuMobile};
`;

const CloseButton = styled('button')`
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 53px;
    cursor: pointer;
    z-index: 2; // z-index 2 makes sure that the close-button is always visible
`;

const Line = styled('hr')`
    position: fixed;
    left: ${contentMargins[0]};
    bottom: 0;
    width: calc(100% - (${contentMargins[0]} * 2));
    height: 2px;
    margin: 0;
    z-index: 1;
    border-color: var(--header-border-color);
`;

const MenuMobile = ({ closeMenu = () => {}, totalAnimationDuration }) => {
    const { extraLinks, featuredLinks, primaryMenu, secondaryMenu } = useSelector(
        state => state.header.data.navigation || {}
    );

    return (
        <Wrapper>
            <CloseButton type="button" onClick={closeMenu}>
                <Close color="var(--header-text-color)" />
            </CloseButton>
            <RecursiveMenuMobileView
                isActive
                isTopLevel
                closeMenu={closeMenu}
                extraLinks={extraLinks}
                featuredLinks={featuredLinks}
                primarySubMenu={primaryMenu}
                secondarySubMenu={secondaryMenu}
                totalAnimationDuration={totalAnimationDuration}
            />
            <Line />
        </Wrapper>
    );
};

MenuMobile.propTypes = {
    closeMenu: PropTypes.func,
    totalAnimationDuration: PropTypes.number,
};

export default MenuMobile;
