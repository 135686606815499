import MultiRangeSlider from 'components/forms/MultiRangeSlider';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('li')`
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    padding: 12px 0;
`;

const FilterMultiRangeOption = ({ clearByKey = () => {}, filters = [], handleChange = () => {} }) => (
    <Wrapper>
        {filters.map(filter => {
            if (filter.option === 'multirange' && filter.stats.min === filter.stats.max) {
                return null;
            }

            return (
                <MultiRangeSlider
                    clearByKey={clearByKey}
                    handleChange={(min, max) => handleChange(filter.key, min, max)}
                    handleClearByKey={() => clearByKey(filter.key)}
                    key={filter.key}
                    label={filter.label}
                    max={filter.values?.[1]}
                    min={filter.values?.[0]}
                    name={filter.label}
                    unit={filter.unit}
                />
            );
        })}
    </Wrapper>
);

FilterMultiRangeOption.propTypes = {
    clearByKey: PropTypes.func,
    filters: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, selected: PropTypes.bool })).isRequired,
    handleChange: PropTypes.func,
};

export default FilterMultiRangeOption;
