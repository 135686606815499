import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';

/**
 * Takes category map object and transforms them into comma seperated list.
 * The sort function will put the primary category as the first before the alphabetical order.
 * @param {object} categories - Category map object
 * @param {string} primaryCategory - Primary category
 * @returns string
 */

export const transformArticleCategories = (categoriesMap: Record<string, string>, primaryCategory: string) => {
    const hiddenCategories = ['Uncategorized', 'Okategoriserad'];
    return Object.values(categoriesMap)
        .filter(categoryName => hiddenCategories.indexOf(categoryName) === -1)
        .sort((a: string, b: string): number => {
            if (a === primaryCategory) {
                return -1;
            }
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
        .join(', ');
};

/**
 * Takes a date string and formats the date
 * @param {string} dateString - Date string in format YYYY-MM-DD HH:mm:ss
 * @returns string - YY.MM.DD
 */

export const transformArticleDate = (dateString: string) => {
    if (!dateString) {
        return null;
    }

    const iosDateFormatFix = `${dateString.replace(' ', 'T')}Z`;
    const date = new Date(iosDateFormatFix);

    return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
        -2
    )}.${date.getFullYear().toString().slice(-2)}`;
};

/**
 * Transform the algoliaArticleData returned from algolia
 *
 * @param {object} algoliaArticleData - Article data from Algolia
 * @returns object
 */

export const transformArticleCard = (algoliaArticleData: Record<string, any>) => {
    if (!algoliaArticleData) {
        return null;
    }

    return {
        category: transformArticleCategories(
            algoliaArticleData.categories_names,
            algoliaArticleData.categories[algoliaArticleData.primary_category]
        ),
        date: transformArticleDate(algoliaArticleData.created_at),
        heading: algoliaArticleData.preview?.title,
        id: algoliaArticleData.id,
        image: transformBackgroundImage(algoliaArticleData.preview?.image_portrait?.background_image),
        to: algoliaArticleData.uri,
    };
};

/**
 * Transform article data from Aligola Response
 *
 * @param {object} response - Response data from Algolia
 * @returns object
 */

export const transformAlgoliaArticleResponse = (response: Record<string, any>) => {
    const clonedResponse = JSON.parse(JSON.stringify(response));

    if (clonedResponse.hits?.length) {
        clonedResponse.hits = clonedResponse.hits.map(article => transformArticleCard(article));
    }

    return clonedResponse;
};
