import { Discount, Product } from 'libs/centra/types';

export const getUpsellItems = (items: Product[], discounts: Discount[]) => {
    return items &&
        discounts &&
        items.length > 0 &&
        discounts.reduce((acc, curr) => {
            return acc || curr.name.toLowerCase().indexOf('custom deal') === 0;
        }, false)
        ? items
              .map(i => i.product_reference.related_products.filter(j => j.relation === 'custom deal').map(j => j.id))
              .filter(i => i !== null)
              .reduce((p, c) => p.concat(c), [])
        : [];
};

export const getTotalNumberOfProducts = (items: Product[]) => {
    if (items?.length < 1) {
        return 0;
    }
    return items.reduce((acc, curr) => {
        return acc + curr.quantity;
    }, 0);
};
