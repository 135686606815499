import Heading from 'components/text/Heading';
import Info from 'assets/icons/Info';
import Paragraph from 'components/text/Paragraph';
import Price from 'components/products/Price';
import PropTypes from 'prop-types';
import React from 'react';
import Vouchers from 'components/pages/checkout/CheckoutView/Summary/Vouchers';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Ul = styled('ul')`
    display: flex;
    flex-direction: column;
    gap: 8px 0;
`;

const Li = styled('li')`
    display: flex;
    justify-content: space-between;
`;

const Summary = ({ showInfoBox = true, ...rest }) => {
    const { t } = useTranslation();

    // Selectors
    const basketItems = useSelector(state => state.basket.items || []);
    const paymentMethodId = useSelector(state => state.basket.paymentMethodId);
    const { information, show_information: showInformation } = useSelector(
        state => state.page.data.checkout.summary || {}
    );
    const { hasDiscountOnProductsPrice, productsPriceBeforeDiscount, totalDiscountIncludingSale } = useSelector(
        state => state.basket.customPrices
    );
    const { final_price: finalPrice, products_sum: productsSum, shipping_price: shippingPrice } = useSelector(
        state => state.basket.totals || {}
    );

    // Variables
    const numberOfItems = basketItems.reduce((total, item) => total + item.quantity, 0);

    return (
        <Div {...rest}>
            <Div
                backgroundColor="var(--checkout-summary-background-color)"
                padding={['16px', null, null, null, '24px']}
            >
                <Ul my="8px">
                    {productsSum && (
                        <Li>
                            <Paragraph as="span" textTransform="lowercase">
                                {`${numberOfItems} ${numberOfItems === 1 ? t('utils.product') : t('utils.products')}`}
                            </Paragraph>
                            <Paragraph as="span" textAlign="right">
                                <Price
                                    flexDirection="row-reverse"
                                    fontKeys="Value Sans/16"
                                    isOnSale={hasDiscountOnProductsPrice}
                                    priceWithCurrency={productsPriceBeforeDiscount || productsSum}
                                    salePriceWithCurrency={productsSum}
                                />
                            </Paragraph>
                        </Li>
                    )}
                    {shippingPrice && paymentMethodId && (
                        <Li>
                            <Paragraph as="span">{t('checkout_basket.shipping')}</Paragraph>
                            <Paragraph as="span" textAlign="right">
                                {shippingPrice}
                            </Paragraph>
                        </Li>
                    )}
                    <Vouchers />
                </Ul>
                <Ul borderTop="1px solid var(--checkout-border-color)" justifyContent="space-between" paddingTop="16px">
                    <Li>
                        <Heading as="span" fontKeys="Value Sans/24">
                            {t('checkout_basket.to_pay')}
                        </Heading>
                        <Heading as="span" fontKeys="Value Sans/24" textAlign="right">
                            {finalPrice}
                        </Heading>
                    </Li>
                    {totalDiscountIncludingSale && (
                        <Li color="var(--checkout-text-color-light)">
                            <Paragraph as="span">{t('checkout_basket.total_discount')}</Paragraph>
                            <Paragraph as="span" textAlign="right">
                                {totalDiscountIncludingSale}
                            </Paragraph>
                        </Li>
                    )}
                </Ul>
            </Div>
            {showInfoBox && showInformation && (
                <Div
                    alignItems="flex-start"
                    backgroundColor="var(--checkout-information-background-color)"
                    display="flex"
                    gap="16px"
                    marginTop="16px"
                    padding="16px"
                >
                    <Info minWidth="24px" />
                    <Wysiwyg
                        data={information}
                        textComponent={props => <WysiwygParagraph fontKeys="Value Sans/14" {...props} />}
                    />
                </Div>
            )}
        </Div>
    );
};

Summary.propTypes = {
    showInfoBox: PropTypes.bool,
};

export default Summary;
