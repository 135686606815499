/* eslint-disable camelcase */
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';
import { recursiveDeleteKeyByValue } from 'utils/object';
import { useSelector } from 'react-redux';

/**
 * ArticleStructuredData for Byon
 * @version 1.2
 *
 * ArticleStructuredData Init in views/Post
 * @param {string} protocol - application.protocol string. always https
 * @param {string} domain - application.domain, current domain
 * @param {string} path - application.path, current path
 * @param {object} organization - Only getting the company name and logo here
 * @param {string} datePublished - Original date the article were published
 * @param {string} dateModified - Latest modified date.
 * @param {object} articleSummary - Getting the specified article information here
 * @param {string} author - Author of the article
 * @param {string} permalink - link ex /magazine-article-about, used to build up the url
 * @param {string} title - This is used as a fallback from the Article title
 * @param {object} seo - Only used to add any keywords, if present.
 */

const ArticleStructuredData = React.memo(
    ({ datePublished, dateModified, articleSummary, author, permalink, title, keywords }) => {
        const { domain, path, protocol } = useSelector(
            state => state.application,
            (prev, next) =>
                prev.application?.domain !== next.application?.domain ||
                prev.application?.protocol !== next.application?.protocol ||
                prev.application?.path !== next.application?.path
        );

        const companyName = useSelector(
            state => state.application.config.options?.company_data?.organization?.company_name || null
        );
        const companyLogo = useSelector(
            state => state.application.config.options?.company_data?.organization?.logo?.url || null
        );

        // Setup url
        const protocolPath = protocol ? `${protocol}://` : '';
        const localePath = path || '';
        const baseUrl = `${protocolPath}${domain}` || '';
        const pageUrl = `${baseUrl}${localePath}${permalink}`;

        // Get article/company info
        const { excerpt, imageSrc } = articleSummary;

        // Add the publisher
        const publisher = {
            '@type': 'Organization',
            name: companyName,
            logo: {
                '@type': 'ImageObject',
                url: companyLogo,
            },
        };

        // Add and rewrite the author
        const transformedAuthor =
            author.first_name || author.last_name
                ? {
                      '@type': 'Person',
                      'name': Object.values(author).join(' '),
                  }
                : publisher;

        // We rewrite this below, article schema based on above information.
        const article = {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': pageUrl,
            },
            headline: title,
            abstract: excerpt || null,
            image: imageSrc || null,
            datePublished,
            dateModified,
            author: transformedAuthor,
            publisher,
            keywords: keywords || null,
        };

        // Remove null values from article
        recursiveDeleteKeyByValue(article, null);

        return (
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(article, null, 2)}</script>
            </Helmet>
        );
    },
    // Use React.memo() to only re-render if permalink changes.
    (prevProps, nextProps) => prevProps.permalink === nextProps.permalink
);

ArticleStructuredData.propTypes = {
    articleSummary: PropTypes.shape({
        alternativeTitle: PropTypes.string,
        excerpt: PropTypes.string,
        imageSrc: PropTypes.string,
    }),
    author: PropTypes.string,
    dateModified: PropTypes.string,
    datePublished: PropTypes.string,
    keywords: PropTypes.string,
    permalink: PropTypes.string.isRequired,
    title: PropTypes.string,
};

ArticleStructuredData.defaultProps = {
    articleSummary: {
        alternativeTitle: '',
        excerpt: '',
        imageSrc: '',
    },
    author: '',
    dateModified: '',
    datePublished: '',
    keywords: '',
    title: '',
};

export default ArticleStructuredData;
