/* eslint-disable camelcase */
import {
    NEWSLETTER_SUBSCRIBE,
    NEWSLETTER_SUBSCRIBE_ERROR,
    NEWSLETTER_SUBSCRIBE_SUCCESS,
    PRODUCTREMIND,
    PRODUCTREMIND_ERROR,
    PRODUCTREMIND_SUCCESS,
} from './constants';
import { ProductRemind, Subscribe } from 'libs/GrebbCommerceAPI/Newsletter';
import { getState } from 'state';
export const subscribe = (listId, data) => async dispatch => {
    const application = getState('application');
    const locale = application?.locale;
    const userCountry = application?.shop_config?.user_country;

    if (userCountry) {
        data.country = userCountry || 'SE';
    }

    dispatch({ type: NEWSLETTER_SUBSCRIBE });

    try {
        const response = await Subscribe(listId, {
            email: data.email,
            language: locale,
            country: userCountry,
        });

        if (response.status >= 200 || response.status < 300) {
            dispatch({ type: NEWSLETTER_SUBSCRIBE_SUCCESS });
            return response;
        }
        throw response.error; // Should we really throw here?
    } catch (e) {
        dispatch({ type: NEWSLETTER_SUBSCRIBE_ERROR });
        throw e;
    }
};

export const productRemind = (listId, data) => async dispatch => {
    dispatch({ type: PRODUCTREMIND });

    try {
        const response = await ProductRemind(listId, data);
        if (response.status >= 200 || response.status < 300) {
            dispatch({ type: PRODUCTREMIND_SUCCESS });
            return response;
        }
        throw response.error; // Should we really throw here?
    } catch (e) {
        dispatch({ types: PRODUCTREMIND_ERROR });
        throw e;
    }
};
