import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const MinusIcon = ({ color = colors.black, height = '100%', width = '25px', ...rest }) => (
    <SVG height={height} viewBox="0 0 25 25" width={width} {...rest}>
        <Path d="M12.006 12v.5m0 0v.5m0-.5h12m-12 0h-12" stroke={color} strokeWidth="3" />
    </SVG>
);

MinusIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default MinusIcon;
