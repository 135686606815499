import { Global, css } from '@emotion/core';
import React, { Fragment } from 'react';

import { MediaProvider } from 'react-media-universal';
import Site from 'components/Site';
import colors from 'config/theme/colors';
import sanitize from 'sanitize.css';

const App = () => (
    <Fragment>
        <Global
            styles={css`
                ${sanitize}

                * {
                    box-sizing: border-box;
                }

                html {
                    height: 100%;
                }

                body {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    overflow-x: hidden;

                    &.prevent-overflow {
                        height: 100vh;
                        overflow-y: hidden;
                    }
                }

                :root {
                    height: 100%;

                    /* Template styles */
                    /* --where-what-color-state/variant */

                    /* Default styles */ // Doesnt change with theme
                    --default-background-color: ${colors.white};
                    --default-text-color: ${colors.black};
                    --default-box-shadow-color: ${colors.opacity.black.high};
                    --default-color-active: ${colors.blue};
                    --default-border-color: ${colors.black};
                    --default-color: ${colors.black};
                    --default-error-color: ${colors.red};
                    --default-input-border-color: ${colors.opacity.black.medium};
                    --default-opacity-color-primary: ${colors.opacity.black.low};
                    --default-opacity-color-secondary: ${colors.opacity.black.medium};
                    --default-opacity-color-tertiary: ${colors.opacity.black.high};
                    --default-placeholder-color: ${colors.placeholder};
                    --default-text-color-light: ${colors.grey.five};

                    /* Basket styles */
                    --basket-background-color: ${colors.tint.red};
                    --basket-border-color-light: var(--default-opacity-color-secondary);
                    --basket-border-color: var(--default-color);
                    --basket-text-color-light: var(--default-opacity-color-primary);
                    --basket-text-color: var(--default-color);
                    --basket-remove-button-color: var(--default-color);

                    /* Breadcrumbs bar styles */
                    --breadcrumbs-bar-background-color: ${colors.tint.yellow};
                    --breadcrumbs-bar-text-color: var(--default-color-active);
                    --breadcrumbs-bar-text-color-active: var(--default-color-active);

                    /* Cookie Information Icon */
                    --cookie-information-icon-color: var(--default-color-active);

                    /* Checkout styles */
                    --checkout-border-color-light: var(--default-opacity-color-secondary);
                    --checkout-border-color: var(--default-color);
                    --checkout-error-color: var(--default-error-color);
                    --checkout-information-background-color: ${colors.grey.one};
                    --checkout-summary-background-color: ${colors.tint.red};
                    --checkout-text-color-light: var(--default-opacity-color-primary);
                    --checkout-text-color: var(--default-color);

                    /* Filter styles */
                    --filter-border-color: ${colors.grey.two};

                    /* Footer styles */
                    --footer-background-color: ${colors.blue};
                    --footer-error-color: ${colors.tint.red};
                    --footer-link-color: ${colors.yellow};
                    --footer-linklist-heading-color: ${colors.opacity.white.low};
                    --footer-text-color: ${colors.white};

                    /* Header Default styles */
                    --header-background-color: ${colors.blue};
                    --header-border-color: ${colors.white};
                    --header-text-color: ${colors.white};
                    --header-text-highlight-color: ${colors.tint.red};

                    /* Heaader Checkout styles */
                    --header-checkout-text-color: ${colors.black};

                    /* Module system styles */
                    --module-text-color-on-media: ${colors.white};

                    /* Overlay styles */
                    --overlay-background-color: ${colors.white};
                    --overlay-text-color: ${colors.black};
                    --overlay-border-color: ${colors.black};

                    /* Popup styles */
                    --popup-background-color: ${colors.yellow};
                    --popup-text-color: ${colors.black};

                    /* Search Overlay styles */
                    --search-background-color: ${colors.blue};
                    --search-border-color: ${colors.white};
                    --search-placeholder-text-color: ${colors.opacity.white.high};
                    --search-text-color-error: ${colors.error};
                    --search-text-color: ${colors.white};

                    /* Size selector styles */
                    --size-selector-background-color: ${colors.grey.one};

                    /* Variant selector styles */
                    --variant-selector-extra-border-color: ${colors.grey.three};

                    /* Base theme colors, changes with theme  */
                    --theme-background-color: ${colors.white};
                    --theme-color-active: ${colors.blue};
                    --theme-color: ${colors.black};
                    --theme-error-color: ${colors.red};
                    --theme-opacity-color-primary: ${colors.opacity.black.low};
                    --theme-opacity-color-secondary: ${colors.opacity.black.medium};
                    --theme-opacity-color-tertiary: ${colors.opacity.black.high};

                    /* Base theme dependent theme colors  */
                    --pagination-text-color-light: var(--theme-opacity-color-secondary);
                    --theme-border-color: var(--theme-color);
                    --theme-box-shadow-color: var(--theme-opacity-color-tertiary);
                    --theme-input-border-color: var(--theme-opacity-color-secondary);
                    --theme-input-label-color: var(--theme-opacity-color-primary);
                    --theme-logo-color: var(--theme-color);
                    --theme-text-color: var(--theme-color);

                    /* Top Header styles */
                    --top-background-color: var(--theme-color);
                    --top-text-color: ${colors.white};
                    --top-logo-default-color: var(--theme-color);
                    --top-logo-theme-color: ${colors.white};
                }

                /* Remove input clear cross in IE */
                ::-ms-clear {
                    display: none;
                }
            `}
        />
        <MediaProvider>
            <Site />
        </MediaProvider>
    </Fragment>
);

export default App;
