import Badge from 'components/Badge';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    gap: 4px 8px;
    flex-wrap: wrap;
    align-items: flex-start;
`;

const Badges = ({ badges = [], ...rest }) => (
    <Wrapper {...rest}>
        {badges.map(badge => (
            <Badge key={badge.text} theme={badge.theme}>
                {badge.text}
            </Badge>
        ))}
    </Wrapper>
);

Badges.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ).isRequired,
};

export default Badges;
