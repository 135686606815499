import Checkbox from 'components/forms/Checkbox';
import { CheckoutEvents } from 'libs/Events/constants';
import Events from 'libs/Events';
import Input from 'components/forms/Input';
import PhoneInput from 'components/forms/PhoneInput';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'components/forms/Select';
import { TextLinkWysisygForceTargetBlank } from 'components/text/TextLink';
import ThemeButton from 'components/buttons/ThemeButton';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { defaultShippingMethod } from 'views/Page/Checkout';
import styled from 'libs/styled';
import useFormSubmit from 'hooks/useFormSubmit';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('form')`
    flex-wrap: wrap;
    margin-top: 24px;
`;

const Box = styled('div')`
    margin: 16px 0;
`;

const StyledSelect = styled(Select)`
    margin: 16px 0;
`;

const AdyenForm = ({
    hide = false,
    scrollToShippingSection = () => {},
    showShippingAndPayment = false,
    setShowShippingAndPayment = () => {},
    tracking = {},
}) => {
    const application = useSelector(state => state.application);
    const basket = useSelector(state => state.basket);
    const paymentMethodId = useSelector(state => state.basket?.paymentMethodId);
    const { newsletter, privacy } = useSelector(state => state.page.data.checkout.information || {});
    const { t } = useTranslation();

    const onSuccessfulSubmit = async data => {
        try {
            scrollToShippingSection();
            setShowShippingAndPayment(true);

            // GTM Tracking
            if (tracking?.trigger) {
                Events.trigger(CheckoutEvents.SHIPPING, { option: defaultShippingMethod });
                if (paymentMethodId) {
                    Events.trigger(CheckoutEvents.PAYMENT, { option: paymentMethodId });
                }
                tracking.setTrigger(false);
            }

            // Remove consent because the API does not care
            delete data['consent'];
            await basket.addBasketInformation({ address: data });
        } catch (error) {
            console.error(error);
        } finally {
            if (!statesAvailable) {
                basket.setAddressState('');
            }
        }
    };

    const { error, selectedValueArray, handleSubmit } = useFormSubmit({
        action: onSuccessfulSubmit,
        required: ['consent'],
        ignore: ['newsletter'],
    });
    const selectedValue = selectedValueArray[0];
    const setSelectedValue = selectedValueArray[1];

    const userCountry = application.shop_config.user_country;
    const country = application.shop_config.countries.find(country => country.id === userCountry);
    const statesAvailable = country && country.states && Object.keys(country.states).length > 0;

    const handleNewsletter = e => {
        localStorage.setItem('newsletter_signup', e.target.checked);
        localStorage.setItem('newsletter_signup_list', 'default');
    };

    const handleChange = () => {
        // Hide the shipping and payment options after change to force a new /payment update
        if (showShippingAndPayment) {
            setShowShippingAndPayment(false);
        }
    };

    const stateOptions =
        statesAvailable &&
        Object.keys(country.states).map(state => {
            return {
                label: country.states[state],
                value: state,
            };
        });

    const fields = [
        {
            defaultValue: basket.address.first_name,
            handleChange,
            label: t('checkout_basket.adyen.first_name'),
            name: 'firstName',
            required: true,
            styling: { paddingRight: ['0', null, null, null, '8px'], width: ['100%', null, null, null, '50%'] },
            type: 'input',
        },
        {
            defaultValue: basket.address.last_name,
            handleChange,
            label: t('checkout_basket.adyen.last_name'),
            name: 'lastName',
            required: true,
            styling: { paddingLeft: ['0', null, null, null, '8px'], width: ['100%', null, null, null, '50%'] },
            type: 'input',
        },
        {
            defaultValue: basket.address.address1,
            handleChange,
            label: t('checkout_basket.adyen.address'),
            name: 'address1',
            required: true,
            type: 'input',
        },
        {
            defaultValue: basket.address.zip,
            handleChange,
            label: t('checkout_basket.adyen.zip_code'),
            name: 'zipCode',
            required: true,
            type: 'input',
        },
        {
            defaultValue: basket.address.city,
            handleChange,
            label: t('checkout_basket.adyen.city'),
            name: 'city',
            required: true,
            type: 'input',
        },
        {
            // Select lists can not be required at the moment, that is why first stateOptions is used as "default"
            defaultValue: stateOptions && stateOptions[0] && stateOptions[0].label,
            handleChange,
            label: t('checkout_basket.adyen.state'),
            name: 'state',
            options: stateOptions,
            required: true,
            type: 'select',
        },
        {
            defaultValue: basket.address.email,
            handleChange,
            label: t('checkout_basket.adyen.email'),
            name: 'email',
            required: true,
            type: 'email',
        },
        {
            defaultValue: basket.address.phone,
            handleChange,
            label: t('checkout_basket.adyen.phone_number'),
            name: 'phoneNumber',
            required: true,
            type: 'tel',
            userCountry: basket.country,
        },
        {
            handleChange,
            label: (
                <Wysiwyg
                    data={privacy?.text}
                    tagComponents={{ url: TextLinkWysisygForceTargetBlank }}
                    textComponent={props => <span {...props} />}
                />
            ),
            name: 'consent',
            type: 'checkbox',
        },
        {
            handleChange: handleNewsletter,
            label: (
                <Wysiwyg
                    data={newsletter?.text}
                    tagComponents={{ url: TextLinkWysisygForceTargetBlank }}
                    textComponent={props => <span {...props} />}
                />
            ),
            name: 'newsletter',
            type: 'checkbox',
        },
    ];

    return (
        <Wrapper display={hide ? 'none' : 'flex'} onSubmit={handleSubmit}>
            {fields.map(
                ({
                    active = true,
                    defaultValue = '',
                    handleChange = () => {},
                    label = '',
                    name = '',
                    options = {},
                    required = false,
                    styling = { width: '100%' },
                    type = 'input',
                    userCountry = '',
                }) => {
                    if (active === false) {
                        return null;
                    }

                    switch (type) {
                        case 'select':
                            return statesAvailable ? (
                                <StyledSelect
                                    isAdyenForm
                                    defaultValue={statesAvailable ? defaultValue : ''}
                                    error={error.find(e => e.name === name)}
                                    handleChange={handleChange}
                                    key={name}
                                    label={label}
                                    name={name}
                                    options={options}
                                    selectedValue={selectedValue}
                                    setSelectedValue={setSelectedValue}
                                    {...styling}
                                />
                            ) : null;

                        case 'checkbox':
                            return (
                                <Checkbox
                                    error={error.find(e => e.name === name)}
                                    handleChange={handleChange}
                                    key={name}
                                    label={label}
                                    name={name}
                                    required={required}
                                    type={type}
                                    {...styling}
                                />
                            );
                        case 'tel':
                            return (
                                <PhoneInput
                                    defaultValue={defaultValue}
                                    error={error.find(e => e.name === name)}
                                    handleChange={handleChange}
                                    key={name}
                                    label={label}
                                    name={name}
                                    required={required}
                                    type={type}
                                    userCountry={userCountry}
                                    {...styling}
                                />
                            );

                        default:
                            return (
                                <Box key={name} {...styling}>
                                    <Input
                                        defaultValue={defaultValue}
                                        error={error.find(e => e.name === name)}
                                        handleChange={handleChange}
                                        label={label}
                                        name={name}
                                        required={required}
                                        type={type}
                                    />
                                </Box>
                            );
                    }
                }
            )}
            <ThemeButton disabled={showShippingAndPayment} mt="24px" size="xxl" theme="black" type="submit">
                {t('checkout_basket.next_step')}
            </ThemeButton>
        </Wrapper>
    );
};

AdyenForm.propTypes = {
    hide: PropTypes.bool,
    scrollToShippingSection: PropTypes.func.isRequired,
    setShowShippingAndPayment: PropTypes.func.isRequired,
    showShippingAndPayment: PropTypes.bool,
    tracking: PropTypes.shape({
        trigger: PropTypes.bool,
        setTrigger: PropTypes.func,
    }),
};

export default AdyenForm;
