import G from 'assets/icons/base/G';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Arrow = ({ color = colors.black, height = '100%', width = '12px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 12 12" width={width} {...rest}>
        <G>
            <Path
                d="M12 5.75v1.5L6 12.5h-.75V11l3.6-2.7a2.25 2.25 0 0 0 .75-1.05H0v-1.5h9.623a2.25 2.25 0 0 0-.75-1.05L5.25 2V.5H6l6 5.25Z"
                fill={color}
            />
        </G>
    </SVG>
);

Arrow.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Arrow;
