import React, { useEffect, useState } from 'react';

import ArticleCard from 'components/ArticleCard';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import ratios from 'config/theme/ratios';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Cards = ({ articles = [], hideOverlay = () => {}, products = [], ...rest }) => {
    // Translation
    const { t } = useTranslation();

    // Variables
    const hasArticles = articles.length > 0;
    const hasProducts = products.length > 0;

    // Tabs - Set the correct default state depending on what is available
    const defaultActiveTab = hasProducts ? 'products' : hasArticles ? 'articles' : null;
    const [activeTab, setActiveTab] = useState();

    // Make sure tabs updates if articles or products changes
    useEffect(() => {
        if (defaultActiveTab !== activeTab) {
            setActiveTab(defaultActiveTab);
        }
    }, [articles, products]);

    // Styling
    const tabsFontStyles = styleObjectToProps('Value Sans/16');

    return (
        <Div width="100%" {...rest}>
            <Div
                alignItems="flex-start"
                display="flex"
                gap="0 12px"
                marginBottom="16px"
                width="100%"
                {...tabsFontStyles}
            >
                {hasProducts && (
                    <TextLinkUnderline noUnderline={activeTab !== 'products'} onClick={() => setActiveTab('products')}>
                        {t('search.products')}
                    </TextLinkUnderline>
                )}
                {hasArticles && (
                    <TextLinkUnderline noUnderline={activeTab !== 'articles'} onClick={() => setActiveTab('articles')}>
                        {t('search.articles')}
                    </TextLinkUnderline>
                )}
            </Div>
            <Div
                display="grid"
                flexGrow="1"
                gap="24px 8px"
                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                marginBottom={!hasProducts ? '48px' : null}
                width="100%"
            >
                {activeTab === 'products' &&
                    products.map(product => (
                        <ProductCard
                            color="var(--search-text-color)"
                            key={`${product.sku}_${product.url}`}
                            onClick={hideOverlay}
                            {...product}
                        />
                    ))}
                {activeTab === 'articles' &&
                    articles.map(article => <ArticleCard key={article.id} ratio={ratios.vertical} {...article} />)}
            </Div>
        </Div>
    );
};

Cards.propTypes = {
    articles: PropTypes.array,
    hideOverlay: PropTypes.func,
    products: PropTypes.array,
};

export default Cards;
