import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import RemoveFilterButton from './RemoveFilterButton';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import { above } from 'utils/mediaqueries';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Line = styled('hr')`
    height: 2px;
    margin: 0;
    border-color: var(--theme-border-color);
    width: calc(100% - (${contentMargins[0]} * 2));

    ${above.tabletSm} {
        width: calc(100% - (${contentMargins[2]} * 2));
    }
`;

const ItemsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 12px;
    padding: 16px 0;

    /* To get a space at the end of the content blocks */
    &::after,
    &::before {
        content: '';
        min-width: 4px; // 4px + 12px gap = 16px padding
        display: block;
        height: 1px;

        ${above.tabletSm} {
            min-width: 12px; // 12px + 12px gap = 24px padding
        }
    }
`;

const SelectedFilter = ({
    clearByKey = () => {},
    clearFilter = () => {},
    handleChangeValue = () => {},
    selectedFilters = [],
    ...rest
}) => {
    const { t } = useTranslation();

    const handleOnClick = filter => {
        if (filter.option === 'multirange') {
            clearByKey(filter.key);
        } else {
            handleChangeValue(filter.key, filter.value);
        }
    };

    return (
        <MaxWidthWrapper alignItems="center" display="flex" flexDirection="column" {...rest}>
            <Line mx={contentMargins} />
            <ScrollWrapper
                displayFadeEffect
                direction="horizontal"
                height="unset"
                scrollContentProps={{ id: 'SelectedFilterScroll' }}
            >
                <ItemsWrapper>
                    {selectedFilters.length > 0 && (
                        <RemoveFilterButton color="var(--default-text-color-light)" handleClick={clearFilter}>
                            {t('filter_config.clear_filter')}
                        </RemoveFilterButton>
                    )}
                    {selectedFilters.map(filter => (
                        <RemoveFilterButton
                            withIcon
                            key={`${filter.key}-${filter.label}`}
                            onClick={() => handleOnClick(filter)}
                        >
                            {filter.label}
                        </RemoveFilterButton>
                    ))}
                </ItemsWrapper>
            </ScrollWrapper>
        </MaxWidthWrapper>
    );
};

SelectedFilter.propTypes = {
    clearByKey: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    handleChangeValue: PropTypes.func.isRequired,
    selectedFilters: PropTypes.arrayOf(
        PropTypes.exact({ key: PropTypes.string, label: PropTypes.string, value: PropTypes.string })
    ).isRequired,
};

export default SelectedFilter;
