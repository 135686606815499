/**
 * Use this util to get the english name of an application (application_aliases).
 *
 * key = db -> application_aliases -> id.
 * value = name of application (in English).
 *
 * Note that these are lists of application aliases for QA and Prod, not your local database.
 * If you want to use this with your local db, you have to change this file and add them yourself.
 * DO NOT PUSH THEM TO GIT, because they will not make any sense to anyone else besides you.
 */

const aliasNames = {
    local: {},
    qa: {
        1: 'Sweden', //Remove once we switch urls to /se (this alias_id is for .se)
        3: 'Sweden',
        4: 'Finland',
        5: 'Denmark',
        6: 'Global',
    },
    production: {
        3: 'Denmark',
        4: 'Finland',
        5: 'Global',
        6: 'Sweden',
    },
};

export default (applicationAliasId, env: string | undefined): string | undefined => {
    if (!env) {
        env = process.env.REACT_APP_ENV;
    }

    if (!env) {
        console.warn(`There are no valid enviroments in your application`);
        return;
    }

    const aliasName = aliasNames[env][applicationAliasId] || false;
    if (aliasName) {
        return aliasName;
    }

    console.warn(
        `The supplied applicationAliasId ${applicationAliasId} had no match in utils/getApplicationAliasNameById.js`
    );

    return;
};
