import ChevronSVG from './Chevron';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';

const Chevron = ({ color = colors.black, direction = 'right', width = '16px', ...rest }) => {
    let transform;

    switch (direction) {
        case 'up':
            transform = 'rotate(-90deg)';
            break;
        case 'right':
            transform = 'rotate(0)';
            break;
        case 'down':
            transform = 'rotate(90deg)';
            break;
        case 'left':
            transform = 'rotate(180deg)';
            break;

        default:
            break;
    }

    return <ChevronSVG color={color} transform={transform} transformOrigin="center" width={width} {...rest} />;
};

Chevron.propTypes = {
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Chevron;
