import Above from 'components/breakpoints/Above';
import AddToBasketButton from 'components/buttons/functionalButtons/AddToBasketButton';
import Badges from '../ProductCard/Badges';
import Below from 'components/breakpoints/Below';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import Price from '../Price';
import ProductInformation from '../ProductInformation';
import PropTypes from 'prop-types';
import React from 'react';
import VariantSelector from '../VariantSelector';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import useIsShowroom from 'hooks/useIsShowroom';
import useVariantSelector from 'hooks/useVariantSelector';
import { variantSelectorConfig } from 'config/variants';
import Tooltip from './Tooltip';
import { useTranslation } from 'react-i18next';

const StyledInformationSection = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;

    ${above.desktopSm} {
        width: calc(33.333% - 16px);
        margin-left: 16px;
        margin-bottom: 0;
    }
`;

const HeadingAndPrice = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    ${above.tabletSm} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const PriceWrapper = styled('div')`
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 4px;

    ${above.tabletSm} {
        min-width: 293px;
        justify-content: flex-end;
    }

    ${above.desktopSm} {
        height: auto;
        justify-content: flex-start;
    }
`;

const Usps = styled('div')`
    margin-bottom: 8px;

    span:not(:first-of-type) {
        ::before {
            content: ' – ';
        }
    }
`;

const InformationSection = ({
    attributesLabel = '',
    badges = [],
    basketProductData = {},
    characteristics = [],
    comingSoon = false,
    description = '',
    informationLabel = '',
    inStock = false,
    name = '',
    price = '',
    usps = [],
    variantData = {},
    variationId = '',
}) => {
    const { t } = useTranslation();

    const { variants, setSelectedVariant, selectionState } = useVariantSelector(
        variantData,
        Object.keys(variantSelectorConfig)
    );

    const isShowroom = useIsShowroom();

    return (
        <StyledInformationSection>
            <HeadingAndPrice>
                <Heading
                    as="h1"
                    fontKeys={[
                        'Value Serif/32-scalable-mobile',
                        null,
                        'Value Serif/32-scalable-tablet',
                        null,
                        'Value Serif/32-scalable-desktop',
                        null,
                        null,
                        'Value Serif/48',
                    ]}
                    margin={['0 0 26px 0', null, null, null, '24px 0 26px']}
                >
                    {name}
                </Heading>
                {!isShowroom && (
                    <Below
                        breakpoint="desktopSm"
                        render={() => (
                            <PriceWrapper marginBottom="32px">
                                <Price
                                    fontKeys={['Value Sans/20', null, 'Value Sans/24', null, 'Value Sans/20']}
                                    isOnSale={price.isOnSale}
                                    priceWithCurrency={price.priceWithCurrency}
                                    salePriceWithCurrency={price.salePriceWithCurrency}
                                />
                                {!!price.isOnSale && (
                                    <Tooltip
                                        content={`${t('product_page.hero.tooltip_description')} ${
                                            price.priceHistoryWithCurrency
                                        }`}
                                    />
                                )}
                            </PriceWrapper>
                        )}
                    />
                )}
            </HeadingAndPrice>
            {badges.length > 0 && (
                <Above
                    breakpoint="desktopSm"
                    render={() => <Badges badges={badges} flexDirection="row" marginBottom="26px" />}
                />
            )}
            {!isShowroom && (
                <Above
                    breakpoint="desktopSm"
                    render={() => (
                        <PriceWrapper marginBottom="104px">
                            <Price
                                height="100%"
                                fontKeys={['Value Sans/20', null, 'Value Sans/24', null, 'Value Sans/20']}
                                isOnSale={price.isOnSale}
                                marginBottom="0"
                                priceWithCurrency={price.priceWithCurrency}
                                salePriceWithCurrency={price.salePriceWithCurrency}
                            />
                            {!!price.isOnSale && (
                                <Tooltip
                                    content={`${t('product_page.hero.tooltip_description')} ${
                                        price.priceHistoryWithCurrency
                                    }`}
                                />
                            )}
                        </PriceWrapper>
                    )}
                />
            )}
            {Object.keys(variants).length > 0 && (
                <VariantSelector
                    handleMissingCombination={setSelectedVariant}
                    marginBottom="32px"
                    selectionIsNeeded={selectionState.selectionIsNeeded}
                    variants={variants}
                />
            )}
            {usps.length > 0 && (
                <Usps>
                    {usps.map(usp => (
                        <Paragraph
                            as="span"
                            key={usp.text}
                            color="var(--default-text-color-light)"
                            fontKeys="Value Sans/12"
                            marginBottom="8px"
                        >
                            {usp.text}
                        </Paragraph>
                    ))}
                </Usps>
            )}
            {!isShowroom && (
                <>
                    <Below
                        breakpoint="desktopSm"
                        render={() => (
                            <AddToBasketButton
                                comingSoon={comingSoon}
                                currentProduct={basketProductData}
                                inStock={inStock}
                                marginBottom="40px"
                                selectionState={selectionState}
                                styles={{ padding: '22px 0' }}
                                variationId={variationId}
                            />
                        )}
                    />
                    <Above
                        breakpoint="desktopSm"
                        render={() => (
                            <AddToBasketButton
                                comingSoon={comingSoon}
                                currentProduct={basketProductData}
                                inStock={inStock}
                                marginBottom="40px"
                                selectionState={selectionState}
                                styles={{ padding: '22px 0' }}
                                theme="solid"
                                variationId={variationId}
                            />
                        )}
                    />
                </>
            )}
            <ProductInformation
                characteristics={characteristics}
                attributesLabel={attributesLabel}
                description={description}
                informationLabel={informationLabel}
                paddingRight={[0, null, '4px', null, 0]}
                width={['100%', null, '75%', null, '100%']}
            />
        </StyledInformationSection>
    );
};

InformationSection.propTypes = {
    attributesLabel: PropTypes.string,
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    basketProductData: PropTypes.object,
    characteristics: PropTypes.array,
    comingSoon: PropTypes.bool,
    description: PropTypes.string,
    inStock: PropTypes.bool,
    informationLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    price: PropTypes.shape({
        isOnSale: PropTypes.bool,
        priceWithCurrency: PropTypes.string.isRequired,
        salePriceWithCurrency: PropTypes.string,
    }),
    usps: PropTypes.array,
    variantData: PropTypes.object,
    variationId: PropTypes.string,
};

export default InformationSection;
