import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';

const H1 = ({ children }) => (
    <Heading as="h1" fontKeys={['Value Sans/32', null, null, 'Value Sans/40']}>
        {children}
    </Heading>
);

const H2 = ({ children }) => (
    <Heading as="h2" fontKeys={['Value Sans/28', null, null, 'Value Sans/40']}>
        {children}
    </Heading>
);

const H3 = ({ children }) => (
    <Heading as="h3" fontKeys={['Value Sans/24', null, null, 'Value Sans/32']}>
        {children}
    </Heading>
);

const H4 = ({ children }) => (
    <Heading as="h4" fontKeys={['Value Sans/22', null, null, 'Value Sans/28']}>
        {children}
    </Heading>
);

const H5 = ({ children }) => (
    <Heading as="h5" fontKeys={['Value Sans/20', null, null, 'Value Sans/24']}>
        {children}
    </Heading>
);

const H6 = ({ children }) => (
    <Heading as="h6" fontKeys={['Value Sans/18', null, null, 'Value Sans/22']}>
        {children}
    </Heading>
);

H1.propTypes = { children: PropTypes.node.isRequired };
H2.propTypes = { children: PropTypes.node.isRequired };
H3.propTypes = { children: PropTypes.node.isRequired };
H4.propTypes = { children: PropTypes.node.isRequired };
H5.propTypes = { children: PropTypes.node.isRequired };
H6.propTypes = { children: PropTypes.node.isRequired };

export { H1, H2, H3, H4, H5, H6 };
