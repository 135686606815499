import { above, media } from 'utils/mediaqueries';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import Cards from './Cards';
import Categories from './Categories';
import NoResult from './NoResult';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import Suggestions from './Suggestions';
import ViewProductsButton from './ViewProductsButton';
import colors from 'config/theme/colors';
import { mobileHeaderHeight } from 'hooks/useHeaderHeights';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { useSelector } from 'react-redux';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'topSpaceMobile'].indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: ${({ clientHeight, topSpaceMobile }) =>
        topSpaceMobile && `calc(${topSpaceMobile + mobileHeaderHeight}px - ${clientHeight})`};

    ${above.tabletSm} {
        flex-direction: row;
        gap: 0 16px;
        height: 100%;
    }
`;

const OuterScrollWrapper = styled('div', { shouldForwardProp: prop => ['topSpaceDesktop'].indexOf(prop) === -1 })`
    width: calc(50% - 8px);
    height: calc(70vh - 2px); // 2px is border height of the input field
    margin-top: ${({ topSpaceDesktop }) => topSpaceDesktop && `-${topSpaceDesktop}px`};

    ${above.desktopSm} {
        width: calc(41.66666667% - 8px);
        margin-left: 8.33333333%;
    }

    // Force overlay to 100vh if the device has touch
    ${media.touch} {
        height: ${({ topSpaceDesktop }) => topSpaceDesktop && `calc(100vh + ${topSpaceDesktop}px)`};
    }
`;

const SearchContent = ({
    articles = [],
    categories = [],
    defaultSuggestions = [],
    noResultText = '',
    products = [],
    productsHitsCount = 0,
    searchString = '',
    suggestions = [],
    updateSearch = () => {},
}) => {
    // Selectors
    const hideOverlay = useSelector(state => state.overlay.hide);
    const searchPage = useSelector(state => state.application.config.options.specified_pages.search_results?.slug);

    // Variables
    const hasArticles = articles.length > 0;
    const hasCategories = categories.length > 0;
    const hasProducts = products.length > 0;
    const hasSuggestions = suggestions.length > 0;
    const hasDefaultSuggestions = defaultSuggestions.length > 0;
    const noResult = !hasArticles && !hasCategories && !hasProducts;
    const hasSearched = !!searchString;

    // Styling help
    const clientHeight = useClientHeight();
    const topSpaceMobile = 150;
    const topSpaceDesktop = 92;

    return (
        <Wrapper clientHeight={clientHeight} topSpaceMobile={topSpaceMobile}>
            <>
                <Below
                    breakpoint="tabletSm"
                    render={() => (
                        <>
                            {noResult && (
                                <NoResult
                                    color="var(--search-text-color-error)"
                                    marginTop="24px"
                                    noResultText={noResultText}
                                />
                            )}
                            <ScrollWrapper scrollContentProps={{ alignItems: 'flex-start' }}>
                                <Div padding="24px 0" width="100%">
                                    {hasSearched && (
                                        <>
                                            {hasProducts && (
                                                <ViewProductsButton
                                                    hideOverlay={() => hideOverlay('searchOverlay')}
                                                    hitsCount={productsHitsCount}
                                                    marginBottom="48px"
                                                    to={`${searchPage}?s=${encodeURI(searchString)}`}
                                                />
                                            )}
                                            {(hasArticles || hasProducts) && (
                                                <Cards
                                                    articles={articles}
                                                    hideOverlay={() => hideOverlay('searchOverlay')}
                                                    products={products}
                                                />
                                            )}
                                            {hasProducts && (
                                                <ViewProductsButton
                                                    hideOverlay={() => hideOverlay('searchOverlay')}
                                                    hitsCount={productsHitsCount}
                                                    margin="48px 0"
                                                    to={`${searchPage}?s=${encodeURI(searchString)}`}
                                                />
                                            )}
                                        </>
                                    )}
                                    {(hasSuggestions || hasDefaultSuggestions) && (
                                        <Suggestions
                                            categories={categories}
                                            suggestions={hasSuggestions ? suggestions : defaultSuggestions}
                                            updateSearch={updateSearch}
                                        />
                                    )}
                                    {hasSearched && hasCategories && (
                                        <Categories
                                            categories={categories}
                                            hideOverlay={() => hideOverlay('searchOverlay')}
                                            suggestions={suggestions}
                                        />
                                    )}
                                </Div>
                            </ScrollWrapper>
                        </>
                    )}
                />
                <Above
                    breakpoint="tabletSm"
                    render={() => (
                        <>
                            <Div
                                alignItems="flex-start"
                                display="flex"
                                flexDirection="column"
                                width={['100%', null, 'calc(50% - 8px)', null, 'calc(41.66666667% - 8px)']}
                            >
                                {noResult && (
                                    <NoResult
                                        color="var(--search-text-color-error)"
                                        margin="24px 0"
                                        noResultText={noResultText}
                                    />
                                )}
                                {(hasSuggestions || hasDefaultSuggestions) && (
                                    <Suggestions
                                        categories={categories}
                                        suggestions={hasSuggestions ? suggestions : defaultSuggestions}
                                        updateSearch={updateSearch}
                                        marginTop="24px"
                                        marginBottom={hasSearched ? '0' : '48px'}
                                    />
                                )}
                                {hasSearched && (
                                    <>
                                        {hasCategories && (
                                            <Categories
                                                categories={categories}
                                                hideOverlay={() => hideOverlay('searchOverlay')}
                                                suggestions={suggestions}
                                            />
                                        )}
                                        {hasProducts && (
                                            <ViewProductsButton
                                                hideOverlay={() => hideOverlay('searchOverlay')}
                                                hitsCount={productsHitsCount}
                                                marginTop="24px"
                                                to={`${searchPage}?s=${encodeURI(searchString)}`}
                                            />
                                        )}
                                    </>
                                )}
                            </Div>
                            {hasSearched && (
                                <OuterScrollWrapper topSpaceDesktop={topSpaceDesktop}>
                                    {/* fadeEffectColor is used by hexToRGBa and can therefore not be a css variable */}
                                    <ScrollWrapper displayFadeEffect fadeEffectColor={colors.blue}>
                                        <Cards
                                            articles={articles}
                                            hideOverlay={() => hideOverlay('searchOverlay')}
                                            padding="124px 0 24px"
                                            products={products}
                                        />
                                    </ScrollWrapper>
                                </OuterScrollWrapper>
                            )}
                        </>
                    )}
                />
            </>
        </Wrapper>
    );
};

SearchContent.propTypes = {
    articles: PropTypes.array,
    categories: PropTypes.array,
    defaultSuggestions: PropTypes.array,
    noResultText: wysiwygProp,
    products: PropTypes.array,
    productsHitsCount: PropTypes.number,
    searchString: PropTypes.string,
    suggestions: PropTypes.array,
    updateSearch: PropTypes.func,
};

export default SearchContent;
