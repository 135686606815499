import { above, media } from 'utils/mediaqueries';
import { linkProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import CookieInformationButton from 'components/CookieInformationButton';
import Image from 'components/Image';
import Link from 'components/base/Link';
import Newsletter from 'components/Newsletter';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import RollingPiggy from 'assets/icons/animatedIcons/RollingPiggy';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import colors from 'config/theme/colors';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Div = styled('div')``;

const Row = styled('div')`
    display: flex;
`;

const Col = styled('div')``;

const Grid = styled('div')`
    display: grid;
    grid-gap: 36px 8px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    ${above.tabletSm} {
        grid-gap: 32px 16px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

const LinkList = styled('ul')`
    display: flex;
`;

const LinkWrapper = styled('div')`
    :not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const LinkAndIcons = styled('li')`
    display: flex;
    ${above.tabletSm} {
        flex-direction: column;
    }
`;

const Icon = styled('div')`
    padding: 8px;
`;

const BottomWrapper = styled('div')`
    display: flex;
    width: 100%;
    justify-content: space-between;

    a {
        color: var(--footer-link-color);
        ${media.hover} {
            &:hover {
                color: var(--footer-text-color);
            }
        }
    }
`;

const FooterContent = ({ address = [], contact = [], newsletter = {}, menu = [], socialMedia = {}, textarea = [] }) => {
    const itemsTextStyles = styleObjectToProps('Value Sans/16');

    return (
        <>
            <Row
                flexWrap={['wrap', null, null, null, 'nowrap']}
                gap={['0', null, null, null, '16px']}
                justifyContent="space-between"
                paddingTop="64px"
            >
                <Col width={['100%', null, null, null, '50%']} paddingBottom="24px">
                    <Col width={['100%', null, 'calc(75% - 4px)', null, 'calc(66.66666% - 8px)']}>
                        <Newsletter
                            backgroundColor="var(--footer-background-color)"
                            buttonTheme="outlinedWhite"
                            hoverColor="var(--footer-text-color)"
                            inputTextColor={colors.opacity.white.low}
                            linkColor="var(--footer-link-color)"
                            textColor="var(--footer-text-color)"
                            {...newsletter}
                        />
                    </Col>
                </Col>
                <Grid width={['100%', null, null, null, '50%']} padding={['24px 0', null, null, null, '0']}>
                    {menu?.map((menuItem, index) => (
                        <Col key={`${menuItem.label}-${index}`}>
                            <Paragraph
                                as="span"
                                display="inline-block"
                                marginBottom="24px"
                                color="var(--footer-linklist-heading-color)"
                            >
                                {menuItem.label}
                            </Paragraph>
                            <ul>
                                {menuItem.links?.map((link, index) => (
                                    <LinkWrapper key={`${link.to}-${index}`}>
                                        <TextLinkUnderline
                                            color="var(--footer-text-color)"
                                            to={link.to}
                                            {...itemsTextStyles}
                                        >
                                            {link.label}
                                        </TextLinkUnderline>
                                    </LinkWrapper>
                                ))}
                            </ul>
                        </Col>
                    ))}

                    <Col>
                        <Paragraph
                            as="span"
                            color="var(--footer-linklist-heading-color)"
                            display={['none', null, 'inline-block']}
                            marginBottom="24px"
                        >
                            {socialMedia.label}
                        </Paragraph>
                        <LinkList flexDirection={['row', null, 'column']} mx={['-8px', null, '0']}>
                            {socialMedia.links?.map((link, index) => (
                                <LinkAndIcons key={`${link.to}-${index}`}>
                                    <Link volatile to={link.to}>
                                        <Icon display={['flex', null, 'none']}>
                                            <Image
                                                alt={link.name}
                                                width="auto"
                                                height="24px"
                                                sizes="24px"
                                                src={{ url: link.icon, width: [24, 48, 72] }} // Three sizes because of 1, 2 and 3 DPR
                                                title={link.name}
                                            />
                                        </Icon>
                                        <LinkWrapper display={['none', null, 'block']} marginBottom="10px">
                                            <TextLinkUnderline color="var(--footer-text-color)" {...itemsTextStyles}>
                                                {link.name}
                                            </TextLinkUnderline>
                                        </LinkWrapper>
                                    </Link>
                                </LinkAndIcons>
                            ))}
                        </LinkList>
                    </Col>
                </Grid>
            </Row>
            <Row alignItems="flex-end" justifyContent="center" width="100%">
                <RollingPiggy
                    borderColor="var(--footer-text-color)"
                    color="var(--footer-text-color)"
                    iconWidth={['15%', null, '16%', null, '17.6%']}
                />
            </Row>
            <BottomWrapper
                flexWrap={['wrap', null, 'nowrap']}
                gap={['24px', null, '16px']}
                padding={['24px 0 48px 0', null, null, null, '24px 0 64px 0']}
                {...itemsTextStyles}
            >
                <Col width={['100%', null, '45%']}>
                    {address && (
                        <Div paddingBottom="16px">
                            <Wysiwyg
                                data={address}
                                textComponent={props => <WysiwygParagraph {...props} />}
                                tagComponents={{ url: TextLinkWysisyg }}
                            />
                        </Div>
                    )}
                    {contact && (
                        <Wysiwyg
                            data={contact}
                            textComponent={props => <WysiwygParagraph {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    )}
                </Col>
                {textarea && (
                    <Col width={['100%', null, '45%']}>
                        <Div width={['100%', null, null, null, 'calc(66.666666% - 8px)']}>
                            <Wysiwyg
                                data={textarea}
                                textComponent={props => <WysiwygParagraph {...props} />}
                                tagComponents={{ url: TextLinkWysisyg }}
                            />
                        </Div>
                    </Col>
                )}
                <Col 
                    width={['100%', null, '10%']} 
                    display="flex" 
                    justifyContent={['flex-start', null, 'flex-end']} 
                    alignItems="flex-start"
                >
                    <CookieInformationButton />
                </Col>
            </BottomWrapper>
        </>
    );
};

FooterContent.propTypes = {
    address: wysiwygProp,
    contact: wysiwygProp,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.arrayOf(linkProp),
        })
    ),
    newsletter: PropTypes.shape({
        heading: PropTypes.string,
        headingTag: PropTypes.string,
        form: PropTypes.shape({
            submitLabel: PropTypes.string,
            placeholder: PropTypes.string,
            inputErrorText: PropTypes.string,
            privacyText: wysiwygProp,
            checkboxErrorText: PropTypes.string,
            formSuccessText: PropTypes.string,
            formErrorText: PropTypes.string,
        }),
    }),
    socialMedia: PropTypes.shape({
        label: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                name: PropTypes.string,
                to: PropTypes.string,
            })
        ),
    }),
    textarea: wysiwygProp,
};

export default FooterContent;
