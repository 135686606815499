import { desktopHeaderHeight, mobileHeaderHeight, tabletHeaderHeight } from 'hooks/useHeaderHeights';
import { useDispatch, useSelector } from 'react-redux';

import Paragraph from 'components/text/Paragraph';
import React from 'react';
import Search from 'assets/icons/Search';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;

    ${above.desktopMd} {
        position: relative;
        top: unset;
        left: unset;
        transform: none;
    }
`;

const SearchButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showOverlay = useSelector(state => state.overlay.show);
    const hideOverlay = useSelector(state => state.overlay.hide);
    const currentOverlay = useSelector(state => state.overlay.current);

    // Show / hide search overlay
    const handleClick = () => {
        dispatch(closeMenu());

        if (currentOverlay === 'searchOverlay') {
            hideOverlay('searchOverlay');
        } else {
            showOverlay('searchOverlay', { zIndex: zIndex.searchOverlay });
        }
    };

    return (
        <Wrapper
            height={[`${mobileHeaderHeight}px`, null, `${tabletHeaderHeight}px`, null, `${desktopHeaderHeight}px`]}
            onClick={() => handleClick()}
        >
            <Search color="var(--header-text-color)" width={['32px', null, null, null, '24px']} />
            <Paragraph as="span" display={['none', null, 'block']} fontKeys="Value Sans/16" margin="0 0 -3px 8px">
                {t('header.search')}
            </Paragraph>
        </Wrapper>
    );
};

export default SearchButton;
