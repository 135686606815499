import Arrow from 'assets/icons/Arrow';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledLink = styled(Link)`
    line-height: 1;
`;

const LabelAndArrow = styled('div')`
    display: flex;
    align-items: center;
`;

const ImageAndTextCard = ({
    backgroundMediaQueries = mediaQueries.twoColumn,
    handleClick = () => {},
    imageBackgroundPosition = '',
    imageBackgroundSize = '',
    imageRatio = ratios.vertical,
    imageUrl = '',
    label = '',
    to = '',
}) => {
    const linkTextStyles = styleObjectToProps('Value Serif/16');

    return (
        <StyledLink to={to} onClick={handleClick} {...linkTextStyles}>
            <AspectWrapper backgroundColor={colors.placeholder} ratio={imageRatio} mb="8px">
                <BackgroundImage
                    position={imageBackgroundPosition}
                    query={backgroundMediaQueries}
                    size={imageBackgroundSize}
                    src={imageUrl}
                />
            </AspectWrapper>
            <LabelAndArrow>
                {label}
                <Arrow color="currentColor" marginLeft="4px" />
            </LabelAndArrow>
        </StyledLink>
    );
};

ImageAndTextCard.propTypes = {
    backgroundMediaQueries: PropTypes.object,
    handleClick: PropTypes.func,
    imageBackgroundPosition: PropTypes.string,
    imageBackgroundSize: PropTypes.string,
    imageRatio: PropTypes.number,
    imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.string,
    to: PropTypes.string,
};

export default ImageAndTextCard;
