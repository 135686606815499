import React, { forwardRef, useEffect, useRef, useState } from 'react';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import PropTypes from 'prop-types';
import Search from 'assets/icons/Search';
import { above } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 4px;

    ${above.desktopSm} {
        width: 100%;
        position: relative;
    }
`;

const SelectDesktop = styled('div')`
    flex-direction: column;
    max-height: 177px;
    overflow: auto;
    padding: 16px 0;
`;

const SearchWrapper = styled('div')`
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--theme-input-border-color);
    overflow: auto;
`;

const SelectMobile = styled('select')`
    height: 44px;
    width: 50px;
    opacity: 0;
    z-index: 1;

    ${above.desktopSm} {
        display: none;
    }
`;

const FilterInput = styled('input')`
    width: 100%;
    padding-bottom: 8px;
    border-width: 0;

    :focus {
        outline: none;
    }
`;

const OptionDesktop = styled('p')`
    list-style: none;
    cursor: pointer;

    :not(:last-child) {
        margin-bottom: 12px;
    }
`;

const OptionMobile = styled('option')``;

const Div = styled('div')``;

const PrefixSelect = forwardRef(({ callingCodes, handleSelectedOption, isSelectOpen, ...rest }, ref) => {
    const { t } = useTranslation();
    const filterInput = useRef();
    const [needle, setNeedle] = useState('');
    const [filter, setFilter] = useState('');

    const fontStyles = styleObjectToProps('Value Sans/16');

    if (!isSelectOpen && needle.length) {
        setNeedle('');
    }

    useEffect(() => {
        // Delay input change so we don't do it while animating
        const timeOutId = setTimeout(() => setFilter(needle), 400);
        return () => clearTimeout(timeOutId);
    }, [needle]);

    if (!callingCodes.length) {
        return null;
    }

    // Filters by country or iso code
    const filterThroughOptions = option => {
        return option.country.toLowerCase().indexOf(filter) >= 0 || option.callingCode.indexOf(filter) >= 0;
    };

    return (
        <Wrapper {...rest}>
            <Below
                breakpoint="desktopSm"
                render={() => (
                    <SelectMobile
                        onChange={e => {
                            const callingCode = e.target.options?.[e.target.selectedIndex].getAttribute(
                                'data-calling-code'
                            );
                            const isoCode = e.target.options?.[e.target.selectedIndex].getAttribute('data-iso-code');

                            handleSelectedOption(isoCode, callingCode);
                        }}
                    >
                        {callingCodes.map(({ callingCode, isoCode, country }) => (
                            <OptionMobile key={isoCode} data-iso-code={isoCode} data-calling-code={callingCode}>
                                {country} ({callingCode})
                            </OptionMobile>
                        ))}
                    </SelectMobile>
                )}
            />
            <Above
                breakpoint="desktopSm"
                render={() => (
                    <Div
                        height={isSelectOpen ? 'auto' : 0}
                        overflow="hidden"
                        borderBottom={isSelectOpen ? '1px solid var(--theme-input-border-color)' : 'none'}
                        ref={ref}
                        {...fontStyles}
                    >
                        <SearchWrapper>
                            <Search width="12px" marginRight="4px" transform="translateY(3px)" />
                            <FilterInput
                                ref={filterInput}
                                value={needle}
                                placeholder={t('checkout_basket.select_an_option_or_search')}
                                onChange={e => setNeedle(e.target.value?.toLowerCase())}
                                {...styleObjectToProps('Value Sans/14')}
                            />
                        </SearchWrapper>
                        <SelectDesktop>
                            {callingCodes
                                .filter(option => (filter.length ? filterThroughOptions(option) : true))
                                .map(({ callingCode, isoCode, country }) => {
                                    return (
                                        <OptionDesktop
                                            key={isoCode}
                                            onClick={() => handleSelectedOption(isoCode, callingCode)}
                                        >
                                            {country} ({callingCode})
                                        </OptionDesktop>
                                    );
                                })}
                            {callingCodes.filter(option => (filter.length ? filterThroughOptions(option) : true))
                                .length === 0 ? (
                                <p>{t('checkout_basket.no_available_options')}</p>
                            ) : null}
                        </SelectDesktop>
                    </Div>
                )}
            />
        </Wrapper>
    );
});

PrefixSelect.propTypes = {
    callingCodes: PropTypes.arrayOf(
        PropTypes.shape({
            callingCode: PropTypes.string,
            isoCode: PropTypes.string,
            country: PropTypes.string,
        })
    ),
    handleSelectedOption: PropTypes.func,
    isSelectOpen: PropTypes.bool,
};

PrefixSelect.defaultProps = {
    callingCodes: [],
    handleSelectedOption: () => null,
    isSelectOpen: false,
};

export default PrefixSelect;
