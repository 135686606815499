import fontFamilies from 'config/theme/fontFamilies';

export interface TextStyle {
    fontFamily: string;
    fontStyle: string;
    fontSize: string;
    fontWeight?: string | number;
    letterSpacing: string;
    lineHeight: string;
}

const textStylesConfig: Record<string, TextStyle> = {
    'Value Sans/12': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '1.2rem',
        fontWeight: 500,
        letterSpacing: '6%',
        lineHeight: '130%',
    },
    'Value Sans/14': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/16': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/16 Regular': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/18': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/20': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '2rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/22': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '2.2rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/20 Regular': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '2rem',
        letterSpacing: '0%',
        lineHeight: '120%',
    },
    'Value Sans/24': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/28': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '2.8rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/32': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Sans/40': {
        fontFamily: fontFamilies.subHeading,
        fontStyle: 'normal',
        fontSize: '4rem',
        fontWeight: 500,
        letterSpacing: '0%',
        lineHeight: '130%',
    },
    'Value Serif/12': {
        fontFamily: fontFamilies.paragraph.serif,
        fontStyle: 'normal',
        fontSize: '1.2rem',
        fontWeight: 500,
        letterSpacing: '-1%',
        lineHeight: '120%',
    },
    'Value Serif/14': {
        fontFamily: fontFamilies.paragraph.serif,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 500,
        letterSpacing: '-1%',
        lineHeight: '120%',
    },
    'Value Serif/16': {
        fontFamily: fontFamilies.paragraph.serif,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        fontWeight: 500,
        letterSpacing: '-1%',
        lineHeight: '120%',
    },
    'Value Serif/20': {
        fontFamily: fontFamilies.paragraph.serif,
        fontStyle: 'normal',
        fontSize: '2rem',
        fontWeight: 500,
        letterSpacing: '-1%',
        lineHeight: '115%',
    },
    'Value Serif/24': {
        fontFamily: fontFamilies.paragraph.serif,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        fontWeight: 500,
        letterSpacing: '-1%',
        lineHeight: '115%',
    },
    'Value Serif/32': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        fontWeight: 700,
        lineHeight: '110%',
        letterSpacing: '-1%',
    },
    'Value Serif/32-scalable-mobile': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(3.2rem, 8.888vw, 6.8rem)',
        fontWeight: 700,
        lineHeight: '110%',
        letterSpacing: '-1%',
    },
    'Value Serif/32-scalable-tablet': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(3.2rem, 4.166vw, 5.3rem)',
        fontWeight: 700,
        lineHeight: '110%',
        letterSpacing: '-1%',
    },
    'Value Serif/32-scalable-desktop': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(3.2rem, 2.342vw, 6rem)',
        fontWeight: 700,
        lineHeight: '110%',
        letterSpacing: '-1%',
    },
    'Value Serif/40': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: '4rem',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/40-scalable-mobile': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(4rem, 11.11vw, 8.5rem);',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/40-scalable-tablet': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(4rem, 5.208vw, 6.7rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/48': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: '4.8rem',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/48-scalable-mobile': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(4.8rem, 13.333vw, 10.2rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/48-scalable-tablet': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(4.8rem, 6.25vw, 8rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/56': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: '5.6rem',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/56-scalable-mobile': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(5.6rem, 15.555vw, 11.9rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/56-scalable-tablet': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(5.6rem, 7.291vw, 9.3rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/56-scalable-desktop': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(5.6rem, 4.099vw, 18.7rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/80': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: '8rem',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/80-scalable-tablet': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(8rem, 10.417vw, 13.3rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/80-scalable-desktop': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(8rem, 5.856vw, 15rem)',
        fontWeight: 700,
        lineHeight: '100%',
        letterSpacing: '-1%',
    },
    'Value Serif/136': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: '13.3rem',
        fontWeight: 700,
        lineHeight: '90%',
        letterSpacing: '-3%',
    },
    'Value Serif/136-scalable-desktop': {
        fontFamily: fontFamilies.heading,
        fontStyle: 'normal',
        fontSize: 'clamp(13.6rem, 10.44vw, 25.5rem)',
        fontWeight: 700,
        lineHeight: '90%',
        letterSpacing: '-3%',
    },
};

export default textStylesConfig;
