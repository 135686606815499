import { above, media } from 'utils/mediaqueries';

import React from 'react';
import Search from 'Header/Default/Search';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import zIndex from 'config/theme/z-index';

const TransitionElement = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'headerHeights'].indexOf(prop) === -1,
})`
    position: fixed;
    bottom: ${({ headerHeights }) => `${headerHeights.mobileAndBreadcrumbsBar}px !important`};
    height: auto;
    max-height: ${({ clientHeight, headerHeights }) =>
        `calc(${clientHeight} - ${headerHeights.mobileAndBreadcrumbsBar}px)`};
    width: 100vw;
    z-index: ${zIndex.searchOverlay};

    ${above.tabletSm} {
        max-height: 50vh;
        bottom: ${({ headerHeights }) => `${headerHeights.tabletAndBreadcrumbsBar}px !important`};
    }

    ${above.desktopSm} {
        max-height: 70vh;
        bottom: ${({ headerHeights }) => `${headerHeights.desktopAndBreadcrumbsBar}px !important`};
    }

    // Force overlay to 100vh if the device has touch
    ${media.touch} {
        height: ${({ clientHeight }) => clientHeight};
        max-height: ${({ clientHeight, headerHeights }) =>
            `calc(${clientHeight} - ${headerHeights.mobileAndBreadcrumbsBar}px)`};

        ${above.desktopSm} {
            max-height: ${({ clientHeight, headerHeights }) =>
                `calc(${clientHeight} - ${headerHeights.desktopAndBreadcrumbsBar}px)`};
        }
    }
`;

const SearchOverlay = () => {
    const clientHeight = useClientHeight();
    const headerHeights = useHeaderHeights();
    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');

    return (
        <TransitionElement
            className={isAboveDesktopSm && 'slide-in bottom'}
            clientHeight={clientHeight}
            headerHeights={headerHeights}
        >
            <Search />
        </TransitionElement>
    );
};

export default SearchOverlay;
