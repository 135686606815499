import Image from 'components/Image';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Div = styled('div')``;

const ColorDotWithText = ({
    handleClick = () => {},
    imageUrl = '',
    isActive = false,
    text = '',
    useOutline = false,
    ...rest
}) => (
    <Div
        alignItems="center"
        cursor="pointer"
        display="flex"
        flexDirection="column"
        gap="4px 0"
        onClick={handleClick}
        {...rest}
    >
        <Div
            border={useOutline || isActive ? `${isActive ? '2' : '1'}px solid var(--overlay-text-color)` : null}
            borderRadius="50%"
            height="46px"
            padding={isActive ? '3px' : '0'}
            width="46px"
        >
            <Image
                alt={text}
                background="var(--default-placeholder-color)"
                border={useOutline && isActive ? '1px solid var(--overlay-text-color)' : null}
                borderRadius="50%"
                format="jpg"
                isActive={isActive}
                name={text}
                sizes="46px"
                src={{ url: imageUrl, width: [46, 92, 138] }} // Three sizes because of 1, 2 and 3 DPR
                useOutline={useOutline}
            />
        </Div>
        <Paragraph fontKeys="Value Sans/12" textAlign="center">
            {text}
        </Paragraph>
    </Div>
);

ColorDotWithText.propTypes = {
    handleClick: PropTypes.func,
    imageUrl: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    text: PropTypes.string.isRequired,
    useOutline: PropTypes.bool,
};

export default ColorDotWithText;
