import HeroContent from 'components/checkout/HeroContent';
import React from 'react';
import emptyCheckout from 'assets/animations/emptyCheckout.json';
import { useSelector } from 'react-redux';
import transformButton from 'libs/wordpress/utils/transformButton';

const EmptyCheckout = () => {
    const { button, heading, paragraph } = useSelector(state => state.page.data.checkout.empty_checkout || {});
    const transformedButton = transformButton(button);
    return (
        <HeroContent
            animation={emptyCheckout}
            button={transformedButton}
            heading={heading}
            lottieMaxWidth={['80%', null, '60%', '100%']}
            text={paragraph}
        />
    );
};

export default EmptyCheckout;
