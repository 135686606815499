import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledTag = styled('span')``;

const Tag = ({ as = 'span', children, fontKeys = 'Value Sans/16', ...rest }) => {
    const fontStyles = styleObjectToProps(fontKeys);

    return (
        <StyledTag as={as} display="block" {...fontStyles} {...rest}>
            {children}
        </StyledTag>
    );
};

Tag.propTypes = {
    as: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Tag;
