import ByonLogo from 'assets/icons/logos/ByonLogo';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import TextLink from 'components/text/TextLink';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    color: var(--header-checkout-text-color);
    margin-top: 16px;
    margin-bottom: 48px;

    ${above.tabletSm} {
        margin-top: 24px;
        align-items: center;
    }

    ${above.desktopSm} {
        margin-bottom: 64px;
    }
`;

const StyledLink = styled(Link)``;

const LogoLink = styled(StyledLink)`
    ${above.tabletSm} {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const CheckoutHeader = () => {
    const { t } = useTranslation();
    const pageTemplate = useSelector(state => state.page.template);
    const goBackLink = useSelector(state => state.header.data.checkoutLink || {});
    const checkoutLink = useSelector(state => state.application.shop_config.checkout_uri || {});

    // Success page has its own header
    if (pageTemplate === 'ecommerce_checkout_success') {
        return null;
    }

    return (
        <MaxWidthWrapper includeContentMargins as="header">
            <Wrapper>
                <LogoLink to="/">
                    <ByonLogo
                        color="currentColor"
                        height={['24px', null, '33px', null, '47px']}
                        width={['102px', null, '140px', null, '200px']}
                    />
                </LogoLink>
                {goBackLink?.label && goBackLink?.to && (
                    <TextLink color="currentColor" to={goBackLink.to}>
                        {goBackLink.label}
                    </TextLink>
                )}
                {checkoutLink && (
                    <StyledLink display={['none', null, 'block']} to={checkoutLink}>
                        <Paragraph>{t('checkout_basket.checkout')}</Paragraph>
                    </StyledLink>
                )}
            </Wrapper>
        </MaxWidthWrapper>
    );
};

export default CheckoutHeader;
