import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollButton from 'components/scrollComponents/ScrollButton';
import SiteSelector from './SiteSelector';
import { above } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Row = styled('div')`
    position: relative;
    display: flex;
    flex-wrap: wrap;

    ${above.tabletSm} {
        flex-wrap: nowrap;
    }

    ${above.tabletLg} {
        align-items: center;
    }
`;

const Div = styled('div')``;

const Col = styled('div')``;

const StyledCol = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Icons = styled('ul')`
    display: inline-flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    margin: -8px;
`;

const Icon = styled('li')`
    padding: 8px;
`;

// NOTE: Country-select will not be in use in phase one. Uncomment all styles to restore site-selector layout.

const FooterBottomRow = ({ partners = [] }) => {
    const { t } = useTranslation();
    const itemsTextStyles = styleObjectToProps('Value Sans/16');

    return (
        <Row width="100%" justifyContent="space-between" gap={['0', null, '16px']} {...itemsTextStyles}>
            <StyledCol width={['100%', null, '45%']} mb="24px">
                <SiteSelector />
                {partners.length > 0 && (
                    <Icons>
                        {partners.map(partner => (
                            <Icon key={partner.icon}>
                                <Link volatile to={partner.to}>
                                    <Image
                                        alt={partner.name}
                                        width="auto"
                                        height="24px"
                                        sizes="32px"
                                        src={{ url: partner.icon, width: [32, 64, 96] }} // Three sizes because of 1, 2 and 3 DPR
                                        title={partner.name}
                                    />
                                </Link>
                            </Icon>
                        ))}
                    </Icons>
                )}
            </StyledCol>
                
           
            <Col width={['calc(100% - 16px)', null, '45%']} mb="24px" display="flex" justifyContent="space-between">
                <Div>
                    © Byon. <span>{t('footer.all_rights_reserved')}</span> {new Date().getFullYear()}
                </Div>
            </Col>
            <Col 
                mb="24px" 
                width={['unset', null, '10%']} 
                display="flex" 
                justifyContent="flex-end" 
                alignItems="flex-start"
            >
                <ScrollButton color="var(--footer-text-color)" />
            </Col>
        </Row>
    );
};

FooterBottomRow.propTypes = {
    partners: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            name: PropTypes.string,
            to: PropTypes.string,
        })
    ),
};

export default FooterBottomRow;
