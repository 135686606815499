import Above from 'components/breakpoints/Above';
import Breadcrumbs from 'components/Breadcrumbs';
import CmsModules from 'libs/wordpress/content/CmsModules';
import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const HeadingAndMenu = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CmsModulesWrapper = styled('div')``;

const Hr = styled('hr')`
    width: 100%;
    border: 1px solid black;
    margin: 0;
`;

const LinkWrapper = styled('ul')`
    display: flex;
    word-break: normal;
    overflow-x: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    ${above.tabletSm} {
        justify-content: center;
    }
`;
const StyledLink = styled(Link)``;

const MenuLink = styled('li')`
    white-space: nowrap;
`;

const MenuWrapper = styled('div')``;

const CustomerService = ({ data }) => {
    const {
        ancestors = [],
        breadcrumbs = [],
        children = [],
        heading = '',
        id,
        page_content: pageContent = [],
        siblings = [],
        title = '',
    } = data;
    const isSubPage = ancestors.length > 0;

    // Menu array
    const menuBase = isSubPage ? siblings : children;
    const relatedLinks = menuBase.map(item => ({
        title: item.title,
        to: item.permalink,
        current: item.id === id,
    }));
    const menuItemFontStyles = styleObjectToProps('Value Serif/24');

    return (
        <>
            <Wrapper minHeight="100vh" pb={['80px', null, '120px']}>
                <MaxWidthWrapper
                    includeContentMargins
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    position="relative"
                    pt={['104px', null, '128px', null, '168px']}
                >
                    <Above
                        breakpoint="desktopSm"
                        render={() => (
                            <Breadcrumbs
                                currentTitle={title}
                                items={breadcrumbs}
                                left="0"
                                paddingLeft="24px"
                                position="absolute"
                                top="24px"
                            />
                        )}
                    />

                    <HeadingAndMenu
                        pb={['16px', null, null, null, '32px']}
                        width={['100%', null, null, null, 'calc(66.666666% - 8px)']}
                    >
                        <Heading
                            as="h1"
                            display="inline-block"
                            fontKeys={[
                                'Value Serif/40-scalable-mobile',
                                null,
                                'Value Serif/80-scalable-tablet',
                                null,
                                'Value Serif/80-scalable-desktop',
                            ]}
                            margin="0 0 24px"
                            textAlign="center"
                        >
                            {heading || title}
                        </Heading>
                        {relatedLinks.length > 0 && (
                            <MenuWrapper mx={['-16px', null, '0']} width={['calc(100% + 32px)', null, '100%']}>
                                <LinkWrapper
                                    flexWrap={['nowrap', null, 'wrap']}
                                    gap={['8px 24px', null, null, null, '8px 24px']}
                                    px={['16px', null, '0']}
                                >
                                    {relatedLinks.map((link, index) => (
                                        <MenuLink key={`${link.title}-${index}`}>
                                            <StyledLink
                                                color={link.current ? 'var(--theme-color-active)' : 'inherit'}
                                                to={link.to}
                                                {...menuItemFontStyles}
                                            >
                                                {link.title}
                                            </StyledLink>
                                        </MenuLink>
                                    ))}
                                </LinkWrapper>
                            </MenuWrapper>
                        )}
                    </HeadingAndMenu>
                    <Hr />
                </MaxWidthWrapper>
                <CmsModulesWrapper
                    mt={isSubPage ? ['32px', null, '88px', null, '64px'] : '24px'}
                    textAlign={!isSubPage ? 'center' : null}
                >
                    <CmsModules data={pageContent} />
                </CmsModulesWrapper>
            </Wrapper>
        </>
    );
};

CustomerService.propTypes = {
    data: PropTypes.object.isRequired, // CMS Data
};

export default CustomerService;
