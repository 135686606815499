import useHeaderHeights, { breadcrumbsBarHeight } from 'hooks/useHeaderHeights';

import CmsModules from 'libs/wordpress/content/CmsModules';
import HeroContent from 'components/checkout/HeroContent';
import PropTypes from 'prop-types';
import React from 'react';
import emptyAnimation from 'assets/animations/empty.json';
import styled from 'libs/styled';
import transformButton from 'libs/wordpress/utils/transformButton';
import useClientHeight from 'hooks/useClientHeight';
import useSiteTheme from 'hooks/useSiteTheme';

const Wrapper = styled('div')``;

const NotFound = ({ data }) => {
    const { hero, page_content: pageContent } = data || {};
    const button = transformButton(hero.button);
    const clientHeight = useClientHeight();
    const menuAndBreadcrumbsHeights = useHeaderHeights();

    // Change site theme
    useSiteTheme('light-red_black', true);

    return (
        <Wrapper>
            <HeroContent
                scrollButton
                animation={emptyAnimation}
                button={button}
                paddingTop={['64px', null, null, 'none']}
                marginBottom={['16px', null, null, null, '0px']}
                heading={{ text: hero.heading }}
                height={[
                    `calc(${clientHeight} - ${
                        menuAndBreadcrumbsHeights.mobile + breadcrumbsBarHeight + menuAndBreadcrumbsHeights.banner
                    }px)`,
                    null,
                    `calc(${clientHeight} - ${
                        menuAndBreadcrumbsHeights.tablet + breadcrumbsBarHeight + menuAndBreadcrumbsHeights.banner
                    }px)`,
                    null,
                    `calc(${clientHeight} - ${menuAndBreadcrumbsHeights.desktop + menuAndBreadcrumbsHeights.banner}px)`,
                ]}
                text={hero.paragraph}
            />

            <CmsModules data={pageContent} />
        </Wrapper>
    );
};

NotFound.propTypes = {
    data: PropTypes.any,
};

export default NotFound;
