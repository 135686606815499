import colors from 'config/theme/colors';

/**
 * @version 1.1
 * @param {object} buttonThemes define the button buttonThemes from design
 * @note Colors and Transitions is also to be modified to the design needs.
 */

export const buttonThemes = {
    black: {
        backgroundColor: colors.black,
        borderColor: colors.black,
        borderStyle: 'solid',
        color: colors.white,
        disabledTheme: {
            color: colors.white,
            borderColor: colors.grey.five,
            backgroundColor: colors.grey.five,
        },
        hover: {
            backgroundColor: colors.blue,
            borderColor: colors.blue,
            color: colors.white,
        },
    },
    blue: {
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        borderStyle: 'solid',
        color: colors.white,
        disabledTheme: {
            color: colors.white,
            borderColor: colors.grey.five,
            backgroundColor: colors.grey.five,
        },
    },
    outlinedBlack: {
        borderColor: colors.black,
        borderStyle: 'solid',
        color: colors.black,
        disabledTheme: {
            color: colors.grey.five,
            borderColor: colors.grey.five,
        },
    },
    outlinedWhite: {
        borderColor: colors.white,
        borderStyle: 'solid',
        color: colors.white,
        disabledTheme: {
            color: colors.grey.five,
            borderColor: colors.grey.five,
        },
    },
    white: {
        backgroundColor: colors.white,
        borderColor: colors.white,
        borderStyle: 'solid',
        color: colors.black,
        disabledTheme: {
            color: colors.black,
            borderColor: colors.grey.five,
            backgroundColor: colors.grey.five,
        },
        hover: {
            color: colors.white,
            borderColor: colors.blue,
            backgroundColor: colors.blue,
        },
    },
    outlined: {
        borderColor: 'var(--theme-border-color)',
        borderStyle: 'solid',
        color: 'var(--theme-text-color)',
        disabledTheme: {
            color: colors.grey.five,
            borderColor: colors.grey.five,
        },
    },
    solid: {
        backgroundColor: 'var(--theme-text-color)',
        borderColor: 'var(--theme-border-color)',
        borderStyle: 'solid',
        color: 'var(--theme-background-color)',
        disabledTheme: {
            color: colors.white,
            borderColor: colors.grey.five,
            backgroundColor: colors.grey.five,
        },
        hover: {
            backgroundColor: 'var(--theme-color-active)',
            borderColor: 'var(--theme-color-active)',
            borderStyle: 'solid',
            color: 'var(--theme-background-color)',
        },
    },
};
