import Basket from 'Header/Default/Basket';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';

const TransitionElement = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'headerHeights', 'headerIsVisible'].indexOf(prop) === -1,
})`
    position: absolute;
    right: 0;
    bottom: ${({ headerHeights, headerIsVisible }) =>
        headerIsVisible ? `${headerHeights.mobileAndBreadcrumbsBar}px !important` : 0};
    height: auto;
    width: 100vw;
    color: var(--basket-text-color);
    background: var(--basket-background-color);

    ${above.tabletSm} {
        bottom: ${({ headerHeights, headerIsVisible }) =>
            headerIsVisible ? `${headerHeights.tabletAndBreadcrumbsBar}px !important` : 0};
        width: calc(75vw - 16px);
    }

    ${above.desktopSm} {
        bottom: ${({ headerHeights, headerIsVisible }) =>
            headerIsVisible ? `${headerHeights.desktop}px !important` : 0};
        width: calc(50vw - 8px);
    }
`;

const BasketOverlay = () => {
    const clientHeight = useClientHeight();
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');
    const headerIsVisible = useSelector(state => state.header.state.headerIsVisible);
    const basketItems = useSelector(state => state.basket.items);
    const hasProducts = basketItems?.length > 0;
    const headerHeights = useHeaderHeights();

    return (
        <TransitionElement
            className={isAboveTabletSm && hasProducts ? 'slide-in right' : 'slide-in bottom'}
            clientHeight={clientHeight}
            headerHeights={headerHeights}
            headerIsVisible={headerIsVisible}
        >
            <Basket />
        </TransitionElement>
    );
};

export default BasketOverlay;
