import Heading from 'components/text/Heading';
import ImageAndTextCard from 'components/cards/ImageAndTextCard';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import contentMargins from 'config/theme/contentMargins';
import { desktopHeaderHeight } from 'hooks/useHeaderHeights';
import { fadeAnimation } from 'Header/Default/Menu/menuStyledComponents';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { transformMenuLinksIntoColumns } from 'utils/dataTransformers/header';

const Div = styled('div')``;

const Wrapper = styled('section', {
    shouldForwardProp: prop => ['newViewTransition', 'isActive', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 24px 0 ${desktopHeaderHeight + 40}px;
    overflow: visible;
    background: var(--header-background-color);

    // The delay for non-newTransitions is needed for the fadeIn- and fadeOut-animations to work properly
    ${({ newViewTransition, totalAnimationDuration }) =>
        newViewTransition
            ? `transition: transform ${totalAnimationDuration}ms ease-out;`
            : `transition: z-index 0ms linear ${totalAnimationDuration / 2}ms, 
                visibility 0ms linear ${totalAnimationDuration / 2}ms, 
                opacity 0ms linear ${totalAnimationDuration / 2}ms, 
                transform 0ms linear ${totalAnimationDuration / 2}ms;`}
`;

const InnerWrapper = styled('div', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 100%;

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })};
`;

const MenuColumns = styled('div')`
    display: flex;
    gap: 0 120px;
`;

const MenuList = styled('ul')`
    display: flex;
    flex-direction: column;
    gap: 6px 0;
`;

const MenuItem = styled('li')`
    width: 100%;
`;

const ImageItemWrapper = styled('ul')`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px 16px;
`;

const ImageItem = styled('li')`
    width: 14%;
    max-width: 14%;
    flex: 0 0 14%;

    &:nth-of-type(5n + 1),
    &:nth-of-type(8n + 4),
    &:nth-of-type(10n + 7) {
        width: calc(14% - 8px);
        max-width: calc(14% - 8px);
        flex: 0 0 calc(14% - 8px);
    }

    &:nth-of-type(6n + 2),
    &:nth-of-type(9n + 5) {
        width: calc(11% - 8px);
        max-width: calc(11% - 8px);
        flex: 0 0 calc(11% - 8px);
    }

    &:nth-of-type(7n + 3),
    &:nth-of-type(10n + 6) {
        width: calc(18% - 8px);
        max-width: calc(18% - 8px);
        flex: 0 0 calc(18% - 8px);
    }
`;

const ListWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: top;
`;

const Submenu = ({
    closeMenu = () => {},
    featuredLinks = [],
    isActive = false,
    label = '',
    linksType = '',
    newViewTransition = false,
    parentLink = '',
    primaryMenuImages = [],
    primaryMenuLinks = [],
    totalAnimationDuration,
    zIndex,
}) => {
    // Fade settings
    const fadeIn = !newViewTransition && isActive;
    const fadeOut = !newViewTransition && !isActive;

    // Array checks
    const hasFeaturedLinks = featuredLinks?.length > 0;
    const hasPrimaryMenuLinks = primaryMenuLinks?.length > 0;
    const hasPrimaryMenuImages = primaryMenuImages?.length > 0;

    // Text styling
    const featuredLinksTextStyles = styleObjectToProps('Value Sans/16');
    const menuItemTextStyles = styleObjectToProps('Value Sans/16 Regular');

    // Transforming data
    const transformedLinksWithColumns = hasPrimaryMenuLinks
        ? transformMenuLinksIntoColumns(primaryMenuLinks)
        : undefined;

    return (
        <Wrapper
            isActive={isActive}
            newViewTransition={newViewTransition}
            opacity={isActive ? 1 : 0}
            px={contentMargins}
            totalAnimationDuration={totalAnimationDuration}
            visibility={isActive ? 'visible' : 'hidden'}
            zIndex={zIndex}
        >
            <InnerWrapper fadeIn={fadeIn} fadeOut={fadeOut} totalAnimationDuration={totalAnimationDuration}>
                <Div marginBottom="32px">
                    <Link to={parentLink}>
                        <Heading as="span" fontKeys="Value Serif/80">
                            {label}
                        </Heading>
                    </Link>
                </Div>
                <ListWrapper>
                    {hasFeaturedLinks && !hasPrimaryMenuImages && (
                        <MenuList margin="0" width="25%">
                            {featuredLinks.map(({ label, to }) => (
                                <MenuItem key={label + to} {...featuredLinksTextStyles}>
                                    <TextLinkUnderline to={to} onClick={closeMenu}>
                                        {label}
                                    </TextLinkUnderline>
                                </MenuItem>
                            ))}
                        </MenuList>
                    )}
                    {(hasPrimaryMenuLinks || hasPrimaryMenuImages) && (
                        <MenuColumns width={hasFeaturedLinks && !hasPrimaryMenuImages ? '75%' : '100%'}>
                            {linksType === 'links' &&
                                hasPrimaryMenuLinks &&
                                transformedLinksWithColumns.map((column, index) => (
                                    <MenuList key={index} margin="0">
                                        {column?.map(({ label, link, menuType, submenu }) => (
                                            <MenuItem key={label + link?.to} {...menuItemTextStyles}>
                                                <TextLinkUnderline to={link?.to} onClick={closeMenu}>
                                                    {label}
                                                </TextLinkUnderline>
                                                {menuType === 'submenu' && submenu?.primaryMenuLinks?.length > 0 && (
                                                    <MenuList
                                                        borderLeft="1px solid var(--header-border-color)"
                                                        key={index}
                                                        margin="4px 0 4px 2px"
                                                        paddingLeft="16px"
                                                    >
                                                        {submenu.primaryMenuLinks.map(({ label, link }) => (
                                                            <MenuItem key={label + link?.to} {...menuItemTextStyles}>
                                                                <TextLinkUnderline to={link?.to} onClick={closeMenu}>
                                                                    {label}
                                                                </TextLinkUnderline>
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                ))}
                            {linksType === 'images' && hasPrimaryMenuImages && (
                                <ImageItemWrapper>
                                    {primaryMenuImages.map(({ label, link, image }) => (
                                        <ImageItem
                                            fadeIn={fadeIn}
                                            fadeOut={fadeOut}
                                            key={link.to}
                                            totalAnimationDuration={totalAnimationDuration}
                                        >
                                            <ImageAndTextCard
                                                handleClick={closeMenu}
                                                imageBackgroundPosition={image.backgroundPosition}
                                                imageBackgroundSize={image.backgroundSize}
                                                imageUrl={image.url}
                                                label={label}
                                                to={link.to}
                                            />
                                        </ImageItem>
                                    ))}
                                </ImageItemWrapper>
                            )}
                        </MenuColumns>
                    )}
                </ListWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};

Submenu.propTypes = {
    closeMenu: PropTypes.func,
    featuredLinks: PropTypes.array,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    linksType: PropTypes.string,
    newViewTransition: PropTypes.bool,
    parentLink: PropTypes.string,
    primaryMenuImages: PropTypes.array,
    primaryMenuLinks: PropTypes.array,
    totalAnimationDuration: PropTypes.number,
    zIndex: PropTypes.number,
};

export default Submenu;
