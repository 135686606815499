import Close from 'assets/icons/Close';
import Cookies from 'js-cookie';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import getApplicationAliasNameById from 'utils/getApplicationAliasNameById';
import { inServer } from 'config/constants';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TransitionElement = styled('div', {
    shouldForwardProp: prop => ['headerHeights', 'headerIsVisible'].indexOf(prop) === -1,
})`
    position: absolute;
    right: 0;
    height: auto;
    bottom: ${({ headerHeights, headerIsVisible }) =>
        headerIsVisible ? `${headerHeights.mobileAndBreadcrumbsBar}px !important` : 0};
    width: 100%;

    ${above.tabletSm} {
        bottom: ${({ headerHeights, headerIsVisible }) =>
            headerIsVisible ? `${headerHeights.tabletAndBreadcrumbsBar}px !important` : 0};
        width: auto;
    }

    ${above.desktopSm} {
        bottom: ${({ headerHeights, headerIsVisible }) =>
            headerIsVisible ? `${headerHeights.desktopAndBreadcrumbsBar}px !important` : 0};
    }
`;

const Wrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: ${colors.yellow};

    ${above.tabletSm} {
        max-width: 560px;
        padding: 24px;
    }

    ${above.desktopSm} {
        max-width: 675px;
    }
`;

const Button = styled('button')`
    position: absolute;
    top: 18px;
    right: 18px;
`;

const ButtonWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 9px;

    ${above.tabletSm} {
        flex-direction: row;
    }
`;

const SiteSelectorOverlay = () => {
    const { t } = useTranslation();
    const isAboveMobile = useAboveBreakpoint('tabletSm');
    const { search: urlQuery } = useLocation();

    const { aliasId, domain, path, protocol, customer } = useSelector(state => state.application);
    const overlay = useSelector(state => state.overlay);
    const headerIsVisible = useSelector(state => state.header.state.headerIsVisible);
    const headerHeights = useHeaderHeights();

    const externalApplications = process.env.REACT_APP_EXTERNAL_APPLICATIONS?.split(',');

    const customerCountryName = customer?.geo?.country_name;
    const customerAliasId = customer?.primaryApplication?.alias_id;
    const currentAppName = getApplicationAliasNameById(aliasId);
    const suggestedAppName = externalApplications?.includes(customerCountryName)
        ? customerCountryName
        : getApplicationAliasNameById(customerAliasId);

    let suggestedAppPath = '';
    if (customerAliasId && customer.primaryApplication?.url) {
        //Set path for suggested app (country)
        suggestedAppPath = `${customer.primaryApplication.path}${urlQuery}`;
    }

    const stayOnCurrentApp = () => {
        setSiteSelectorCookie(path, aliasId);
        handleClose();
    };

    const switchToSuggestedApp = () => {
        setSiteSelectorCookie(suggestedAppPath, customerAliasId);
        if (!inServer) {
            window.location.href = `${protocol}://${domain}${suggestedAppPath}`;
        }
    };

    const setSiteSelectorCookie = (selectedPath, selectedAliasId) => {
        const cookieValue = JSON.stringify({ selectedPath, selectedAliasId });
        Cookies.set(process.env.REACT_APP_SITE_SELECTOR_POPUP_COOKIE, cookieValue, {
            expires: 7,
            selectedPath,
        });
    };

    const handleClose = () => {
        overlay.hide();
    };

    return (
        <TransitionElement
            className={`slide-in ${isAboveMobile ? 'right--bottom' : 'bottom'}`}
            headerIsVisible={headerIsVisible}
            headerHeights={headerHeights}
        >
            <Wrapper>
                <Button type="button" onClick={() => overlay.hide()}>
                    <Close width="24px" />
                </Button>

                <Heading fontKeys={['Value Serif/20', null, null, 'Value Serif/20']} mb="24px">
                    {t('site_selector_popup.title')}
                </Heading>
                <Paragraph fontKeys="Value Sans/16" mb="16px" mr={['0px', null, '120px']}>
                    {t('site_selector_popup.country_popup_text', {
                        customerCountryName,
                        applicationName: currentAppName,
                    })}
                </Paragraph>
                <ButtonWrapper>
                    {/* Stay on the current application */}
                    <ThemeButton
                        theme="black"
                        cornerBackground={colors.yellow}
                        height="42px"
                        onClick={stayOnCurrentApp}
                    >
                        {t('site_selector_popup.stay_current_application', {
                            applicationName: currentAppName,
                        })}
                    </ThemeButton>

                    {/* Switch to suggested application */}
                    {suggestedAppPath && (
                        <ThemeButton
                            theme="outlined"
                            cornerBackground={colors.yellow}
                            type="button"
                            height="42px"
                            onClick={switchToSuggestedApp}
                        >
                            {t('site_selector_popup.go_to_suggested_location', {
                                applicationName: suggestedAppName,
                            })}
                        </ThemeButton>
                    )}
                </ButtonWrapper>
            </Wrapper>
        </TransitionElement>
    );
};

export default SiteSelectorOverlay;
