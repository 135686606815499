import Chevron from 'assets/icons/Chevron';
import PropTypes from 'prop-types';
import React from 'react';
import TextLink from 'components/text/TextLink';
import { below } from 'utils/mediaqueries';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import styled from 'libs/styled';

const Wrapper = styled('ul')`
    ${below.desktopSm} {
        &::before,
        &::after {
            content: '';
            width: 16px;
            height: 16px;
        }
    }
`;

const Breadcrumb = styled('li')`
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 8px;
`;

const Items = ({
    activeColor = 'var(--theme-text-color)',
    linkColor = 'var(--theme-opacity-color-primary)',
    list = [],
    textStyles = {},
    ...rest
}) => (
    <Wrapper {...rest}>
        {list?.map((breadcrumb, index) => {
            if (!breadcrumb) {
                return null;
            }

            const to = breadcrumb.permalink || breadcrumb.to;

            return (
                <Breadcrumb key={breadcrumb.title} {...textStyles}>
                    {index > 0 && <Chevron color={linkColor} height="10px" marginRight="8px" width="5px" />}
                    <TextLink
                        noParanthesis
                        volatile
                        color={to ? linkColor : activeColor}
                        cursor={to ? 'pointer' : 'default'}
                        to={to}
                    >
                        {decodeHTMLEntities(breadcrumb.title)}
                    </TextLink>
                </Breadcrumb>
            );
        })}
    </Wrapper>
);

Items.propTypes = {
    activeColor: PropTypes.string,
    linkColor: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            permalink: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
    textStyles: PropTypes.object,
};

export default Items;
