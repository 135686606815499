import colors from './colors';

interface Theme {
    backgroundColor: string;
    color: string;
}

// Export used in changeSiteColorTheme.ts
export type ColorThemes =
    | 'black_white'
    | 'blue_white'
    | 'blue_yellow'
    | 'default'
    | 'light-blue_black'
    | 'light-blue_blue'
    | 'light-blue_red'
    | 'light-red_black'
    | 'light-red_blue'
    | 'light-yellow_black'
    | 'light-yellow_blue'
    | 'light-yellow_red'
    | 'red_white'
    | 'red_yellow'
    | 'yellow_black'
    | 'yellow_blue'
    | 'yellow_red';

/*@todo: 
 "[key in ColorThemes]" generates an eslint error due to eslint not being configured for typescript.
 Solved with ignoring this file in the eslint config.
 Come back to this and add configuration for typescript in eslint.
*/
type Themes = {
    [key in ColorThemes]: Theme;
};

export const themes = {
    'default': {
        activeColor: colors.blue,
        backgroundColor: colors.white,
        color: colors.black,
        opacityColor: colors.opacity.black.low,
        errorColor: colors.tint.red,
    },
    'black_white': {
        activeColor: colors.yellow,
        backgroundColor: colors.black,
        color: colors.white,
        opacityColor: colors.opacity.white.high,
        errorColor: colors.red,
    },
    'blue_white': {
        activeColor: colors.yellow,
        backgroundColor: colors.blue,
        color: colors.white,
        errorColor: colors.tint.red,
        opacityColor: colors.opacity.white.high,
    },
    'blue_yellow': {
        activeColor: colors.white,
        backgroundColor: colors.blue,
        color: colors.yellow,
        opacityColor: colors.opacity.yellow.low,
        errorColor: colors.tint.red,
    },
    'light-blue_black': {
        activeColor: colors.blue,
        backgroundColor: colors.tint.blue,
        color: colors.black,
        opacityColor: colors.opacity.black.low,
        errorColor: colors.red,
    },
    'light-blue_blue': {
        activeColor: colors.black,
        backgroundColor: colors.tint.blue,
        color: colors.blue,
        opacityColor: colors.opacity.blue.low,
        errorColor: colors.red,
    },
    'light-blue_red': {
        activeColor: colors.black,
        backgroundColor: colors.tint.blue,
        color: colors.red,
        opacityColor: colors.opacity.red.low,
        errorColor: colors.red,
    },
    'light-red_black': {
        activeColor: colors.blue,
        backgroundColor: colors.tint.red,
        color: colors.black,
        opacityColor: colors.opacity.black.low,
        errorColor: colors.red,
    },
    'light-red_blue': {
        activeColor: colors.black,
        backgroundColor: colors.tint.red,
        color: colors.blue,
        opacityColor: colors.opacity.blue.low,
        errorColor: colors.red,
    },
    'light-yellow_black': {
        activeColor: colors.blue,
        backgroundColor: colors.tint.yellow,
        color: colors.black,
        opacityColor: colors.opacity.black.low,
        errorColor: colors.red,
    },
    'light-yellow_blue': {
        activeColor: colors.black,
        backgroundColor: colors.tint.yellow,
        color: colors.blue,
        opacityColor: colors.opacity.blue.low,
        errorColor: colors.red,
    },
    'light-yellow_red': {
        activeColor: colors.black,
        backgroundColor: colors.tint.yellow,
        color: colors.red,
        opacityColor: colors.opacity.red.low,
        errorColor: colors.blue,
    },
    'red_white': {
        activeColor: colors.yellow,
        backgroundColor: colors.red,
        color: colors.white,
        opacityColor: colors.opacity.white.high,
        errorColor: colors.yellow,
    },
    'red_yellow': {
        activeColor: colors.white,
        backgroundColor: colors.red,
        color: colors.yellow,
        opacityColor: colors.opacity.yellow.low,
        errorColor: colors.white,
    },
    'yellow_black': {
        activeColor: colors.blue,
        backgroundColor: colors.yellow,
        color: colors.black,
        opacityColor: colors.opacity.black.low,
        errorColor: colors.red,
    },
    'yellow_blue': {
        activeColor: colors.white,
        backgroundColor: colors.yellow,
        color: colors.blue,
        opacityColor: colors.opacity.blue.low,
        errorColor: colors.red,
    },
    'yellow_red': {
        activeColor: colors.white,
        backgroundColor: colors.yellow,
        color: colors.red,
        opacityColor: colors.opacity.red.low,
        errorColor: colors.blue,
    },
};
