import React, { useEffect, useRef, useState } from 'react';
import { above, below } from 'utils/mediaqueries';
import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import DesktopCarousel from './DesktopCarousel';
import Image from 'components/Image';
import ProductImageZoom from './ProductImageZoom';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import open from 'assets/icons/cursors/open.svg';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';

const Wrapper = styled('div')`
    width: 100%;
    height: 100%;

    ${below.desktopSm} {
        overflow: auto;
    }

    ${above.desktopSm} {
        position: relative;
    }

    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const InnerWrapper = styled('div')`
    display: inline-flex;
    align-items: flex-start;
    height: 100%;
    cursor: ${`url(${open}) 20 20, pointer`};
`;

const ImageWrapper = styled('div')`
    aspect-ratio: 3 / 4;

    ${above.desktopSm} {
        height: 100%;
    }
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none; // Prevents ::selection from marking the image
`;

const ProductGallery = ({ imageConfig = {}, images = [], ...rest }) => {
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');
    const [currentImage, setCurrentImage] = useState(null); // Product zoom
    const headerHeights = useHeaderHeights();

    const wrapperRef = useRef(null);
    const imageSrc = images[0]?.src;

    useEffect(() => {
        wrapperRef.current.scrollTo({ top: 0, left: 0 });
    }, [imageSrc]);

    if (!images.length) {
        return null;
    }

    const { imageSizes, srcWidths, srcHeights } = imageConfig;
    const numberOfImages = images.length;
    const isOneImage = images.length === 1;
    const imageWidth = ['90vw', null, '66.666vw'];
    const carouselImages = [...images];
    if (isOneImage) {
        if (isAboveTabletSm) {
            // Add placeholder duplicate image in tablet
            carouselImages.push(images[0]);
        } else {
            // Make image cover entire screen in mobile
            imageWidth[0] = '100%';
        }
    }

    return (
        <Wrapper ref={wrapperRef} marginTop={[null, null, null, null, `-${headerHeights.banner}px`]} {...rest}>
            <Below
                breakpoint="desktopSm"
                render={() => (
                    <InnerWrapper>
                        {carouselImages.map((image, index) => {
                            const addFrame = isOneImage && index === 1;

                            return (
                                <ImageWrapper
                                    background={addFrame ? colors.yellow : null}
                                    key={`${image.src}${index}`}
                                    padding={addFrame ? '80px 60px' : null}
                                    width={imageWidth}
                                >
                                    <StyledImage
                                        alt={image.alt || image.name}
                                        loading="none"
                                        sizes={imageSizes}
                                        src={{ url: image.src, width: srcWidths, height: srcHeights }}
                                        title={image.name}
                                        onClick={() => setCurrentImage(isOneImage ? 0 : index)}
                                    />
                                </ImageWrapper>
                            );
                        })}
                    </InnerWrapper>
                )}
            />
            <Above
                breakpoint="desktopSm"
                render={() => (
                    <>
                        <DesktopCarousel
                            currentImage={currentImage}
                            setCurrentImage={setCurrentImage}
                            images={carouselImages}
                            imageConfig={imageConfig}
                            isOneImage={isOneImage}
                        />
                        <ProductImageZoom
                            currentImageIndex={currentImage}
                            images={images.slice(0, numberOfImages)}
                            setCurrentImage={setCurrentImage}
                        />
                    </>
                )}
            />
        </Wrapper>
    );
};

ProductGallery.propTypes = {
    imageConfig: PropTypes.object,
    images: PropTypes.array,
    setCurrentImage: PropTypes.func,
};

ProductGallery.defaultProps = {
    imageConfig: {},
    images: [],
    setCurrentImage: () => null,
};

export default ProductGallery;