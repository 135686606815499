import React, { useEffect, useState } from 'react';
import { getSortedFilters, getTransformedFilters } from 'utils/dataTransformers/filters';

import Close from 'assets/icons/Close';
import FilterAccordion from 'components/accordions/FilterAccordion';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const FlexDiv = styled('div')`
    display: flex;
`;

const Filter = ({ filters = [], handleChange = {}, clear = () => null, hitsCount }) => {
    const overlay = useSelector(state => state.overlay);
    const { t } = useTranslation();
    const clientHeight = useClientHeight();
    const [filterIsSelected, setFilterIsSelected] = useState(false);

    // Transform filters
    const transformedFilters = getTransformedFilters(filters, t);

    // Sort filters by priority
    const sortedFilters = getSortedFilters(transformedFilters, 'priority');

    useEffect(() => {
        setFilterIsSelected(sortedFilters.some(filter => filter.totalSelected !== 0));
    }, [sortedFilters]);

    return (
        <FlexDiv
            postion="relative"
            flexDirection="column"
            overflowY="auto"
            height={clientHeight}
            px={contentMargins}
            py="24px"
        >
            <FlexDiv alignItems="flex-start" justifyContent="space-between" marginBottom={['64px', null, '56px']}>
                <FlexDiv alignItems="flex-start">
                    <Heading
                        as="h3"
                        display="inline-block"
                        fontKeys={[
                            'Value Serif/40-scalable-mobile',
                            null,
                            'Value Serif/56-scalable-tablet',
                            null,
                            'Value Serif/80-scalable-desktop',
                            null,
                            'Value Serif/80',
                        ]}
                    >
                        {t('filter_config.filter')}
                    </Heading>
                    {hitsCount ? <Paragraph as="span">{`(${hitsCount})`}</Paragraph> : null}
                </FlexDiv>
                <button type="button" onClick={() => overlay.hide()}>
                    <Close height="16px" width="16px" />
                </button>
            </FlexDiv>
            <FilterAccordion clearByKey={clear.byKey} filters={sortedFilters} handleChange={handleChange.filter} />
            <FlexDiv justifyContent="center" gap="8px" marginTop="auto" marginBottom="24px">
                <ThemeButton
                    flexGrow="0"
                    disabled={!filterIsSelected}
                    theme="outlinedBlack"
                    onClick={() => clear.filter()}
                >
                    {t('filter_config.clear_filter')}
                </ThemeButton>
                <ThemeButton flexGrow="1" theme="outlinedBlack" onClick={() => overlay.hide()}>
                    {t('filter_config.show_results')} {`(${hitsCount})`}
                </ThemeButton>
            </FlexDiv>
            {hitsCount < 1 && (
                <Paragraph as="span" fontKeys="Value Sans/12" marginTop="12px" textAlign="center">
                    {t('filter_config.no_results_with_these_filters')}
                </Paragraph>
            )}
        </FlexDiv>
    );
};

Filter.propTypes = {
    clear: PropTypes.object,
    filters: PropTypes.arrayOf(
        PropTypes.exact({
            key: PropTypes.string,
            label: PropTypes.string,
            stats: PropTypes.object,
            totalSelected: PropTypes.number,
            values: PropTypes.array,
        })
    ),
    handleChange: PropTypes.shape({
        filter: PropTypes.shape({
            value: PropTypes.func,
            range: PropTypes.func,
        }),
        sort: PropTypes.func,
    }),
    hitsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default Filter;
