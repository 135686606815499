import React, { Component } from 'react';

import Events from 'libs/Events';
import PropTypes from 'prop-types';
import RadioList from 'components/forms/Radio';
import { injectModels } from 'state';
import styled from 'libs/styled';
import { withTranslation } from 'react-i18next';

const StyledRadioList = styled(RadioList)`
    label {
        display: flex;
        justify-content: space-between;

        p + p {
            flex-shrink: 0;
            margin-left: 24px;
        }
    }
`;

class ShippingFallback extends Component {
    static propTypes = {
        basket: PropTypes.object.isRequired,
        homeDeliveryId: PropTypes.string,
        maxWeight: PropTypes.number,
        notice: PropTypes.object.isRequired,
        onLoad: PropTypes.func,
        t: PropTypes.func.isRequired,
    };

    static defaultProps = {
        homeDeliveryId: 'PRP',
        maxWeight: 20,
        onLoad: null,
    };

    state = {
        error: null,
        loading: false,
    };

    componentDidMount() {
        const { onLoad } = this.props;

        onLoad && onLoad();

        Events.trigger('shipping.loaded');
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { basket, onLoad } = this.props;

        // Rerender if the onLoad-function has changed
        if (nextProps.onLoad !== onLoad) {
            return true;
        }

        // Rerender if there are any new/removed shipping methods
        if (JSON.stringify(nextProps.basket.shippingMethods) !== JSON.stringify(basket.shippingMethods)) {
            return true;
        }

        // Rerender if the basket has a new shipping method set
        if (nextProps.basket.shippingMethodId !== basket.shippingMethodId) {
            return true;
        }

        // Rerender if this components state is updated
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true;
        }

        // If number of items in basket is updated
        if (
            nextProps.basket.items.reduce((total, item) => total + item.quantity, 0) !==
            basket.items.reduce((total, item) => total + item.quantity, 0)
        ) {
            return true;
        }

        return false;
    }

    render() {
        const {
            basket: { shippingMethods, shippingMethodId, selectShippingMethod, items: basketItems },
            homeDeliveryId,
            maxWeight,
            notice,
            t,
        } = this.props;

        const { error, loading } = this.state;

        const nonIngrid = Object.keys(shippingMethods).filter(k => k.indexOf('ingrid') === -1);

        if (nonIngrid.length === 0) {
            console.error(`Found incorrect amount of non-ingrid shipping methods: ${nonIngrid.length}.`);
            return null;
        }

        const updateShippingMethod = async value => {
            // Make sure that the id is a possible value
            if (nonIngrid.indexOf(value.id) !== -1) {
                // Set loading to true and reset error messages
                this.setState({
                    error: null,
                    loading: true,
                });

                // Update the basket shipping method and wait for the response
                const response = await selectShippingMethod(value.id);

                // Set loading to false and add error message if something went wrong
                this.setState({
                    error: response.status !== 200 ? t('Something went wrong.') : null,
                    loading: false,
                });
            }
        };

        // Special handling for orders over 20 kg
        const totalWeightKg = basketItems.reduce((total, item) => {
            if (item.product_reference.attributes.weight?.value) {
                const totalFloat = parseFloat(item.product_reference.attributes.weight.value);
                total += totalFloat * item.quantity;
            }

            return total;
        }, 0);

        // Prepp data for RadioList
        const radioItems = nonIngrid.map(item => {
            const id = shippingMethods[item].id;
            const disabled = totalWeightKg > maxWeight && id === homeDeliveryId;

            return {
                extraText: shippingMethods[item].price,
                id,
                text: disabled
                    ? `${shippingMethods[item].name} - ${t(
                          'checkout.shipping_error.unavailable_max_weight'
                      )} ${maxWeight} kg`
                    : shippingMethods[item].name,
                disabled,
            };
        });

        // Display a error message and update shipping method if weight is to high for selected method
        if (shippingMethodId === homeDeliveryId && totalWeightKg > maxWeight) {
            notice.addNotice({
                type: 'error',
                title: t('checkout.shipping_error.title'),
                message: t('checkout.shipping_error.max_shipping_limit'),
            });

            const allowed = radioItems.filter(item => !item.disabled);
            updateShippingMethod(allowed[0]);
        }

        return (
            <StyledRadioList
                defaultActive={shippingMethodId}
                items={radioItems}
                isLoading={loading}
                name="shippingMethod"
                error={error}
                itemStyling={{
                    alignItems: 'center',
                    borderBottom: '1px solid var(--theme-input-border-color)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '12px 0',
                    width: '100%',
                }}
                handleChange={updateShippingMethod}
                borderTop="1px solid var(--theme-input-border-color)"
            />
        );
    }
}

export default withTranslation()(injectModels(['basket', 'notice'])(ShippingFallback));
