/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';
import { recursiveDeleteKeyByValue } from 'utils/object';
import { useSelector } from 'react-redux';

/**
 * BreadcrumbsStructuredData for Byon
 * @version 1.1
 *
 * BreadcrumbsStructuredData Init in Breadcumbs
 * @param {object[]} breadcrumbs - The brdcrumbs data used in the Breadcrumbs-component
 */

const BreadcrumbsStructuredData = ({ breadcrumbs }) => {
    const application = useSelector(
        state => state.application,
        (prev, next) =>
            prev.application?.domain !== next.application?.domain ||
            prev.application?.protocol !== next.application?.protocol ||
            prev.application?.path !== next.application?.path
    );

    // Setup urls
    const protocolPath = application.protocol ? `${application.protocol}://` : '';
    const localePath = application.path || '';
    const baseUrl = `${protocolPath}${application.domain}` || '';

    // check if we have the breadcrumbs otherwise fallback to [].
    const structuredBreadcrumbs =
        breadcrumbs?.map((item, i) => {
            const name = item?.title || '';
            const link = item?.permalink || '';
            return {
                '@type': 'ListItem',
                position: i + 1,
                name,
                item: link ? baseUrl + localePath + link : null,
            };
        }) || [];

    // List of breadcrumbs. we still want to return something to the structured data.
    const breadcrumbList = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: structuredBreadcrumbs,
    };

    // Remove null values from breadcrumbList
    recursiveDeleteKeyByValue(breadcrumbList, null);

    return structuredBreadcrumbs ? (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(breadcrumbList, null, 2)}</script>
        </Helmet>
    ) : null;
};

BreadcrumbsStructuredData.propTypes = {
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            permalink: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
};

export default BreadcrumbsStructuredData;
