import Path from '../base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from '../base/SVG';
import colors from 'config/theme/colors';

const ByonLogo = ({ color = colors.black, height = '100%', width = '192px', ...rest }) => (
    <SVG fill={color} height={height} preserveAspectRatio="xMinYMin slice" viewBox="0 0 192 46" width={width} {...rest}>
        <Path
            fill={color}
            d="M45.956 31.7a12.676 12.676 0 0 1-.799 4.627 10.773 10.773 0 0 1-2.036 3.4 11.411 11.411 0 0 1-3.049 2.327 19.187 19.187 0 0 1-3.543 1.49 30.097 30.097 0 0 1-3.788.858 29.647 29.647 0 0 1-3.514.395c-.962.04-1.962.061-3 .064H0V.153h23.663c1.027 0 2.003.02 2.928.06a33.41 33.41 0 0 1 3.416.372c1.247.174 2.482.423 3.698.746 1.187.336 2.333.798 3.42 1.38 1.1.558 2.099 1.292 2.959 2.173.855.921 1.534 1.99 2.002 3.154.55 1.341.823 2.78.803 4.228 0 3.594-1.583 6.452-4.748 8.574 5.213 2.175 7.818 5.795 7.815 10.86ZM5.906 10.507v6.737h18.117c8.707 0 13.06-1.119 13.06-3.356s-4.353-3.355-13.06-3.355l-18.117-.026Zm20.58 16.11H5.906v7.903h20.58c9.12 0 13.7-1.307 13.74-3.922.043-2.655-4.537-3.982-13.74-3.982ZM93.825 39.052c-4.635-3.976-6.953-9.501-6.953-16.575 0-7.034 2.318-12.538 6.953-16.512S104.928.002 113.228 0c8.295 0 14.761 1.977 19.398 5.931 4.638 3.955 6.957 9.469 6.957 16.542 0 7.076-2.319 12.601-6.957 16.576-4.637 3.974-11.103 5.962-19.398 5.965-8.298.002-14.765-1.985-19.403-5.962Zm30.525-6.528c3.117-.894 5.493-2.196 7.125-3.903a8.582 8.582 0 0 0 2.456-6.144c0-3.574-1.828-6.355-5.486-8.344-3.657-1.988-8.744-2.982-15.259-2.982-6.517 0-11.605.994-15.262 2.982-3.658 1.989-5.487 4.77-5.487 8.344 0 3.579 1.83 6.371 5.487 8.377 3.657 2.006 8.745 3.01 15.262 3.013 4.325.002 8.047-.444 11.164-1.339v-.004ZM143.314.153h9.922l23.723 29.08a5.598 5.598 0 0 0 4.436 1.897h4.193V.134H192v44.72h-10.481L157.92 15.631c-.945-1.146-2.301-1.717-4.069-1.715h-4.125v30.944h-6.412V.153ZM83.854.153 72.7 15.345l-.093.112a7.27 7.27 0 0 1-2.626 2.408 7.32 7.32 0 0 1-6.926.015 7.27 7.27 0 0 1-2.636-2.397c-.034-.045-.071-.09-.105-.138L49.099.153H40.71l22.001 29.71v15.009h7.197v-15.01L92.031.152h-8.178Z"
        />
    </SVG>
);

ByonLogo.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default ByonLogo;
