import React, { useRef, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';

import PrefixSelect from './PrefixSelect';
import PropTypes from 'prop-types';
import { countries } from './countries';
import { media } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    position: relative;
    width: 100%;
    margin: 16px 0;
`;

const InputWrapper = styled('div')`
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 4px;
`;

const InputAndLabel = styled('div', { shouldForwardProp: prop => ['error'].indexOf(prop) === -1 })`
    position: relative;
    width: 100%;
    --input-color: ${({ error }) => (error ? 'var(--theme-error-color)' : 'var(--theme-text-color)')};
    --label-color: ${({ error }) => (error ? 'var(--theme-error-color)' : 'var(--theme-input-label-color)')};

    ${media.hover} {
        :hover {
            > label {
                color: var(--label-color);
            }
        }
    }
`;

const Prefix = styled('span', { shouldForwardProp: prop => ['isActive'].indexOf(prop) === -1 })`
    flex: 0 0 auto;
    padding-right: 16px;
    padding-top: 1px;
    color: ${({ isActive }) => `${isActive ? 'var(--theme-text-color)' : 'var(--theme-opacity-color-primary)'}`};
    position: relative;
    cursor: pointer;

    ::after {
        content: '';
        margin-left: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: ${({ isActive }) =>
            `6px solid ${isActive ? 'var(--theme-border-color)' : 'var(--theme-opacity-color-primary)'}`};
    }
`;

const Label = styled('label')`
    transition: all ${transitions.secondary};
    position: absolute;
    top: 1px; // Alignment magic
    left: 2px;
    cursor: text;
    user-select: none;
`;

const Input = styled('input')`
    width: 100%;
    text-decoration: none;
    display: block;
    border: none;
    border-radius: 0;
    background-color: transparent;
    -webkit-appearance: none;
    color: var(--input-color);

    :focus {
        outline: none;
        + label {
            display: none;
        }
    }

    // Keeps label hidden if a value is given and the user clicks outside the field
    :not(:placeholder-shown) {
        outline: none;

        + label {
            display: none;
        }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        box-shadow: 0 0 0 30px var(--theme-background-color) inset !important;
    }
`;

const ErrorMessage = styled('span')`
    margin: -8px 0 8px;
    color: var(--theme-error-color);
`;

const PhoneInput = ({
    defaultValue,
    error,
    label,
    name,
    required = false,
    type,
    userCountry = 'SE',
    handleBlur = () => null,
    handleChange = () => null,
}) => {
    const ref = useRef();

    const [value, setValue] = useState(defaultValue);
    const [isActive, setIsActive] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(userCountry);
    const [userCallingCode, setUserCallingCode] = useState(`+${getCountryCallingCode(userCountry)}`);

    const fontStyles = styleObjectToProps('Value Sans/16');
    const transformedLabel = required ? `${label}*` : label;

    // Maps through supported regions & adds respective country.
    // Filters out the few countries that don't match a country code & sorts alphabetically
    const countriesAndCountryCodes = getCountries()
        .map(region => {
            const callingCode = `+${getCountryCallingCode(region)}`;
            const country = countries.filter(country => country.countryCode === region);

            return { isoCode: region, callingCode, country: country[0]?.name };
        })
        .filter(region => region?.country)
        .sort((a, b) => {
            if (a.country < b.country) {
                return -1;
            }
            if (a.country > b.country) {
                return 1;
            }

            return 0;
        });

    const change = e => {
        setValue(e.target.value);
        handleChange(e);
    };

    const blur = e => {
        if (!e.target.value) {
            setValue(undefined);
        }
        handleBlur(e);
    };

    const handleSelectedOption = region => {
        setIsActive(true);
        setIsSelectOpen(false);
        setSelectedRegion(region);
        setUserCallingCode(`+${getCountryCallingCode(region)}`);
    };

    return (
        <>
            <Wrapper>
                <InputWrapper
                    borderBottom={isSelectOpen ? 'none' : '1px solid var(--theme-input-border-color)'}
                    {...fontStyles}
                >
                    <Prefix isActive={isActive} onClick={() => setIsSelectOpen(!isSelectOpen)}>
                        {userCallingCode}
                    </Prefix>
                    <InputAndLabel error={error}>
                        <Input
                            aria-label={label}
                            data-locale={selectedRegion}
                            id={name}
                            name={name}
                            placeholder="" // Needed for :placeholder-shown
                            required={required}
                            type={type}
                            value={value}
                            onChange={change}
                            onBlur={blur}
                            onClick={() => {
                                setIsActive(true);
                                setIsSelectOpen(false);
                            }}
                            {...fontStyles}
                        />
                        <Label htmlFor={name} required={required}>
                            {transformedLabel}
                        </Label>
                    </InputAndLabel>
                </InputWrapper>
                <PrefixSelect
                    callingCodes={countriesAndCountryCodes}
                    handleSelectedOption={handleSelectedOption}
                    isSelectOpen={isSelectOpen}
                    ref={ref}
                />
            </Wrapper>
            {error?.message && <ErrorMessage {...styleObjectToProps('Value Sans/12')}>{error.message}</ErrorMessage>}
        </>
    );
};

PhoneInput.propTypes = {
    defaultValue: PropTypes.string,
    error: PropTypes.exact({
        message: PropTypes.string,
        name: PropTypes.string,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    type: PropTypes.string.isRequired,
    userCountry: PropTypes.string,
};

export default PhoneInput;
