import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    white-space: nowrap;
`;

const PriceText = styled('span')`
    font-size: inherit;
    line-height: inherit;
`;

const Price = ({
    fontKeys = 'Value Sans/20',
    isOnSale = false,
    priceWithCurrency = '',
    salePriceWithCurrency = '',
    textColor = 'var(--theme-text-color)',
    textColorLight = 'var(--default-text-color-light)',
    ...rest
}) => {
    const fontStyles = styleObjectToProps(fontKeys);

    return (
        <Wrapper gap="8px" color={textColor} {...fontStyles} {...rest}>
            {!!isOnSale && <PriceText>{salePriceWithCurrency}</PriceText>}
            <PriceText
                color={isOnSale ? textColorLight : 'inherit'}
                textDecoration={isOnSale ? 'line-through' : 'none'}
            >
                {priceWithCurrency}
            </PriceText>
        </Wrapper>
    );
};
Price.propTypes = {
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    gap: PropTypes.string,
    isOnSale: PropTypes.bool,
    priceWithCurrency: PropTypes.string.isRequired,
    salePriceWithCurrency: PropTypes.string,
    textColor: PropTypes.string,
    textColorLight: PropTypes.string,
    textStyles: PropTypes.object,
};
export default Price;
