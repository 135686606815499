import React, { useCallback, useEffect, useRef, useState } from 'react';
import { above, below } from 'utils/mediaqueries';

import Arrow from 'assets/icons/Arrow';
import Heading from 'components/text/Heading';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import { animations } from 'config/theme/transitions';
import { headingTagProp } from 'utils/proptypes/modules/textProps';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const HeadingAndButtons = styled('div')`
    margin-bottom: 24px;

    ${above.tabletSm} {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
    }

    ${above.desktopSm} {
        margin-bottom: 48px;
    }
`;
const ButtonAndArrows = styled('div')`
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

const Arrows = styled('div')`
    display: none;

    ${above.desktopSm} {
        display: flex;
        gap: 24px;
    }
`;

const ArrowButton = styled('button')`
    display: flex;
    align-items: center;
`;

const PrevArrow = styled(ArrowButton)`
    transform: translateX(0) rotate(180deg) translateY(-1px); // -1px for alignment reasons
`;

const NextArrow = styled(ArrowButton)``;

const Grid = styled('ul')`
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    > * {
        scroll-snap-align: start;
    }

    ${above.tabletSm} {
        gap: 16px;
    }

    /* Hide scrollbar  */
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Product = styled('li', {
    shouldForwardProp: prop => ['isSliderInDesktop'].indexOf(prop) === -1,
})`
    --grid-gap-offset-tablet: 10.66px;

    flex-shrink: 0;
    flex-basis: 83%;
    margin-top: 8px;

    :nth-of-type(even) {
        flex-basis: 66%;
    }

    ${below.tabletSm} {
        :only-child {
            flex-basis: 100%;
        }
    }

    ${above.tabletSm} {
        ${({ isSliderInDesktop }) =>
            isSliderInDesktop
                ? `
        flex-basis: calc(25% - var(--grid-gap-offset-tablet));

        :nth-of-type(even) {
            flex-basis: calc(33.333% - var(--grid-gap-offset-tablet));
        }`
                : `
        flex-basis: calc(41.66% - var(--grid-gap-offset-tablet));

        :nth-of-type(2) {
            flex-basis: calc(25% - var(--grid-gap-offset-tablet));
        }

        :nth-of-type(3) {
            flex-basis: calc(33.33% - var(--grid-gap-offset-tablet));
        }`}
    }
`;

const headingSizes = {
    small: ['Value Serif/24', null, 'Value Serif/32-scalable-tablet', null, 'Value Serif/32-scalable-desktop'],
    large: [
        'Value Serif/56-scalable-mobile',
        null,
        'Value Serif/80-scalable-tablet',
        null,
        'Value Serif/136-scalable-desktop',
    ],
};

const ProductsPromotion = ({
    button = {},
    heading = '',
    headingSize = 'large',
    headingTag = 'h2',
    loadImages = true,
    products = [],
    ...rest
}) => {
    const [itemWidth, setItemWidth] = useState(0);
    const [state, setState] = useState({ visibleNext: true, visiblePrev: false });
    const scrollRef = useRef(null);

    const isSliderInDesktop = products.length > 3;
    const fontKeys = headingSizes[headingSize];
    const hasButton = button.label;

    // Restore slider to its original state when component is loaded
    const wrapperRef = useRef(null);

    useEffect(() => {
        wrapperRef.current.scrollTo({ left: 0 });
    }, [loadImages]);

    const scrollToNext = () => {
        if (scrollRef.current) {
            let scrollTarget = scrollRef.current.scrollLeft + itemWidth;

            // If the scroll taget is close to the end, scroll all the way
            if (scrollRef.current.scrollWidth - (scrollTarget + scrollRef.current.offsetWidth) < itemWidth * 0.66) {
                scrollTarget = scrollRef.current.scrollWidth;
            }

            scrollRef.current.scrollTo({ left: scrollTarget, behavior: 'smooth' });
            handleScroll();
        }
    };

    const scrollToPrevious = () => {
        if (scrollRef.current) {
            let scrollTarget = scrollRef.current.scrollLeft - itemWidth;

            // If the scroll taget is close to the start, scroll all the way
            if (scrollTarget < itemWidth * 0.66) {
                scrollTarget = 0;
            }

            scrollRef.current.scrollTo({ left: scrollTarget, behavior: 'smooth' });
            handleScroll();
        }
    };

    const handleScroll = useCallback(() => {
        if (scrollRef.current) {
            const { scrollWidth, offsetWidth, scrollLeft } = scrollRef.current;

            const newState = { visibleNext: false, visiblePrev: false };

            // Only show arrows if there is a need to scroll
            if (scrollWidth > offsetWidth) {
                // Show prev-arrow if we have scrolled more than 5px
                if (scrollLeft > 5) {
                    newState.visiblePrev = true;
                }

                // Show next-arrow until there is less then 5px left
                if (offsetWidth + scrollLeft + 5 < scrollWidth) {
                    newState.visibleNext = true;
                }
            }

            // Only update state if neccessary
            if (newState.visibleNext !== state.visibleNext || newState.visiblePrev !== state.visiblePrev) {
                setState(newState);
            }
        }
    }, [state]);

    useEffect(() => {
        let node;

        if (scrollRef.current) {
            const children = [...scrollRef.current.children];

            // Amount of pixels to scroll
            const scrollWidth = children.reduce((acc, child) => {
                // We base the amount of pixels to scroll on the widest element in the list, making sure we scroll enough pixels
                if (child.clientWidth > acc) {
                    // Divide by 1.25 so we don't scroll too far on narrower elements or too short on wider elements
                    // and letting the scroll snap pick up the remainder of the distance
                    acc = child.clientWidth / 1.25;
                }

                return acc;
            }, 0);

            setItemWidth(scrollWidth);
            node = scrollRef.current;
            node.addEventListener('scroll', handleScroll);
            handleScroll();
        }

        return () => {
            if (node) {
                node.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef, handleScroll]);

    return (
        <Wrapper ref={wrapperRef} {...rest}>
            {(heading || hasButton || isSliderInDesktop) && (
                <HeadingAndButtons alignItems={headingSize === 'large' && isSliderInDesktop && 'flex-end'}>
                    {heading && (
                        <Heading
                            as={headingTag}
                            fontKeys={fontKeys}
                            marginBottom={[hasButton ? '24px' : '0px', null, '0px']}
                        >
                            {heading}
                        </Heading>
                    )}
                    <ButtonAndArrows>
                        {hasButton && (
                            <ThemeButton dontGrowOnHover flex="0 0 auto" to={button.to}>
                                {button.label}
                            </ThemeButton>
                        )}
                        {isSliderInDesktop && (
                            <Arrows marginLeft="24px">
                                <PrevArrow
                                    type="button"
                                    opacity={state.visiblePrev ? 1 : 0.6}
                                    onClick={() => scrollToPrevious()}
                                >
                                    <Arrow color="var(--theme-text-color)" width="16px" />
                                </PrevArrow>
                                <NextArrow
                                    type="button"
                                    opacity={state.visibleNext ? 1 : 0.6}
                                    onClick={() => scrollToNext()}
                                >
                                    <Arrow color="var(--theme-text-color)" width="16px" />
                                </NextArrow>
                            </Arrows>
                        )}
                    </ButtonAndArrows>
                </HeadingAndButtons>
            )}
            <Grid
                marginRight={['-16px', null, isSliderInDesktop ? '-24px' : '0px']}
                paddingRight={['16px', null, isSliderInDesktop ? '24px' : '0px']}
                ref={scrollRef}
            >
                {products?.map((product, index) => (
                    <Product
                        key={`${product?.id}-${product?.sku}`}
                        isSliderInDesktop={isSliderInDesktop}
                        transitionDelay={[
                            `${animations.primary.delay * (index % 2)}ms`,
                            `${animations.primary.delay * (index % 3)}ms`,
                        ]}
                    >
                        <ProductCard
                            loadImage={loadImages}
                            imageSizes={['75vw', null, '30vw', '33vw', '640px']}
                            srcWidths={[282, 538, 277, 476, 640]}
                            {...product}
                        />
                    </Product>
                ))}
            </Grid>
        </Wrapper>
    );
};

ProductsPromotion.propTypes = {
    button: PropTypes.object,
    heading: PropTypes.string,
    headingSize: PropTypes.string,
    headingTag: headingTagProp,
    loadImages: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductsPromotion;
