import Circle from 'assets/icons/base/Circle';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Remove = ({ color = colors.black, height = '24px', viewBox = '0 0 24 24', width = '24px', ...rest }) => (
    <SVG fill="none" height={height} viewBox={viewBox} width={width} {...rest}>
        <Path
            d="m16.773 15.712-1.06 1.06-3.183-3.181a.75.75 0 0 0-1.06 0l-3.182 3.182-1.061-1.06 3.182-3.183a.75.75 0 0 0 0-1.06L7.227 8.288l1.06-1.061 3.183 3.182a.75.75 0 0 0 1.06 0l3.182-3.182 1.061 1.06-3.182 3.183a.75.75 0 0 0 0 1.06l3.182 3.182Z"
            fill={color}
        />
        <Circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2" />
    </SVG>
);

Remove.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Remove;
