import React, { Fragment } from 'react';

import CheckoutSection from 'components/pages/checkout/CheckoutSection';
import Heading from 'components/text/Heading';
import KlarnaPaymentContainer from 'containers/KlarnaPaymentContainer';
import ShippingForm from '../Shipping';
import { below } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const KlarnaWrapper = styled('div')`
    ${below.lg} {
        position: relative;
        padding: 24px 0;

        > * {
            position: relative;
            z-index: 1;
        }

        &::before {
            display: block;
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0px;
            width: 100vw;
            height: 100%;
            background: ${colors.background};
            z-index: 0;
            transform: translateX(-50%);
        }
    }
`;

const KlarnaPayment = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <ShippingForm />

            <CheckoutSection>
                <Heading>{t('Payment')}</Heading>
                <KlarnaWrapper>
                    <KlarnaPaymentContainer
                        languageTransform={{
                            svse: 'se',
                        }}
                    />
                </KlarnaWrapper>
            </CheckoutSection>
        </Fragment>
    );
};

export default KlarnaPayment;
