import Arrow from 'assets/icons/Arrow';
import Link from 'components/base/Link';
import Marquee from 'components/Marquee';
import React from 'react';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const BannerWrapper = styled('div')`
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-color: var(--default-text-color); // Inverted
`;

const Message = styled('div')`
    display: flex;
    align-items: center;
    margin: 0 16px;
    color: var(--default-background-color); // Inverted
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const Banner = () => {
    const banner = useSelector(state => state.header.data.banner || []);
    const headerHeights = useHeaderHeights();
    const pageType = useSelector(state => state.page.type || '');
    const isPdp = pageType === 'product';

    if (banner?.length < 1) {
        return null;
    }

    // Makes the sticky gallery on desktop PDP look good
    const pdpDesktopStyling = isPdp
        ? {
              'position': [null, null, null, null, 'absolute'],
              'top': [null, null, null, null, '0'],
              'zIndex': [null, null, null, null, zIndex.header],
              'width': [null, null, null, null, '100%'],
          }
        : {};

    return (
        <BannerWrapper {...pdpDesktopStyling} height={`${headerHeights.banner}px`}>
            <Marquee pauseOnHover speed={30}>
                {banner.map(({ message = '', link = {} }, index) => (
                    <Message height={`${headerHeights.banner}px`} key={index}>
                        <StyledLink to={link.to}>
                            <Wysiwyg
                                data={message}
                                textComponent={props => (
                                    <WysiwygParagraph
                                        fontKeys="Value Sans/14"
                                        lineHeight={1}
                                        whiteSpace="nowrap"
                                        {...props}
                                    />
                                )}
                            />
                            {link.to && <Arrow color="currentColor" marginLeft="8px" />}
                        </StyledLink>
                    </Message>
                ))}
            </Marquee>
        </BannerWrapper>
    );
};

export default Banner;
