import Above from 'components/breakpoints/Above';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import LogoFullWidth from 'components/Logo/LogoFullWidth';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollButton from 'components/scrollComponents/ScrollButton';
import Tag from 'components/text/Tag';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import { bleedMargins } from 'config/theme/contentMargins';
import colors from 'config/theme/colors';
import { imageProps } from 'utils/proptypes/media';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')``;

const Row = styled('div')`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ${above.desktopSm} {
        justify-content: flex-start;
    }
`;
const Hr = styled('hr')`
    width: 100%;
    margin: 0 0 16px;
    border: 1px solid black;
`;
const Information = styled('div')`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;

    ${above.tabletSm} {
        gap: 16px;
    }
`;

const Div = styled('div')`
    display: flex;
    flex-direction: column;
`;

const Text = styled(Paragraph)`
    a {
        color: inherit;
    }
`;

const HeadingAndInformation = styled('div')`
    display: flex;
    flex-direction: column;

    ${above.desktopSm} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Hero = ({
    author = '',
    background,
    backgroundQueries = mediaQueries.fullWidth,
    backgroundRatio = [ratios.vertical, ratios.horizontal, null, ratios.horizontalWide],
    backgroundType,
    breadcrumbs = [],
    category = '',
    date = '',
    preamble = [],
    title = '',
}) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <LogoFullWidth />
            <Row margin="64px 0 16px">
                <Above
                    breakpoint="desktopSm"
                    render={() => <Breadcrumbs currentTitle={title} items={breadcrumbs} width="auto" />}
                />
                <ScrollButton scrollDirection="down" />
            </Row>
            <Hr />

            <HeadingAndInformation marginBottom={['16px', null, '24px']}>
                <Heading
                    as="h1"
                    fontKeys={[
                        'Value Serif/32-scalable-mobile',
                        null,
                        'Value Serif/56-scalable-tablet',
                        null,
                        'Value Serif/56-scalable-desktop',
                    ]}
                    margin={['0 0 24px', null, '0 0 32px', null, '0']}
                    width={['100%', null, '75%', null, '58%']}
                >
                    {title}
                </Heading>
                {(category || date || author) && (
                    <Information width={['100%', null, 'calc(66.666666% - 6px)', null, 'calc(33.333333% - 12px)']}>
                        {date && (
                            <Div>
                                <Paragraph as="span" color={colors.grey.five}>
                                    {t('articles.date')}
                                </Paragraph>
                                <Tag>{date}</Tag>
                            </Div>
                        )}
                        {category && (
                            <Div>
                                <Paragraph as="span" color={colors.grey.five}>
                                    {t('articles.subject')}
                                </Paragraph>
                                <Tag>{category}</Tag>
                            </Div>
                        )}
                        {author && (
                            <Div>
                                <Paragraph as="span" color={colors.grey.five}>
                                    {t('articles.written_by')}
                                </Paragraph>
                                <Tag>{author}</Tag>
                            </Div>
                        )}
                    </Information>
                )}
            </HeadingAndInformation>
            <AspectWrapper
                marginTop={['16px', null, null, null, '24px']}
                maxHeight="100vh"
                mx={bleedMargins}
                ratio={backgroundRatio}
            >
                <BackgroundSwitch type={backgroundType} background={background} mediaQuery={backgroundQueries} />
            </AspectWrapper>
            {preamble?.length > 0 && (
                <Div marginTop={['48px', null, '64px']} width={['100%', null, null, null, 'calc(75% - 4px)']}>
                    <Wysiwyg
                        data={preamble}
                        textComponent={props => (
                            <Text fontKeys={['Value Sans/24', null, null, null, 'Value Sans/32']} {...props} />
                        )}
                        tagComponents={{ url: TextLinkWysisyg }}
                    />
                </Div>
            )}
        </Wrapper>
    );
};

Hero.propTypes = {
    author: PropTypes.string,
    background: imageProps,
    backgroundQueries: PropTypes.array,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    backgroundType: PropTypes.string,
    breadcrumbs: PropTypes.array.isRequired,
    category: PropTypes.string,
    date: PropTypes.string,
    preamble: PropTypes.array,
    title: PropTypes.string.isRequired,
};

export default Hero;
