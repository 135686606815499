import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import productProp from 'utils/proptypes/product';
import styled from 'libs/styled';

const Wrapper = styled('section')`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px 8px;

    ${above.tabletSm} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 32px 16px;
    }
`;

const ProductGrid = ({ products = [] }) => (
    <Wrapper>
        {products.map((product, index) => (
            <ProductCard
                imageSizes={['50vw', null, '25vw']}
                key={`${product.sku}_${product.uri}`}
                loading={index < 4 ? 'lazy' : 'none'}
                position={index}
                srcWidths={[180, 192, 256, 320, 342, 384, 480, 640]}
                {...product}
            />
        ))}
    </Wrapper>
);

ProductGrid.propTypes = {
    products: PropTypes.arrayOf(productProp).isRequired,
};

export default ProductGrid;
