import AspectWrapper from 'components/wrappers/AspectWrapper';
import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Li = styled('li', {
    shouldForwardProp: prop => ['isSelected', 'inStock'].indexOf(prop) === -1,
})`
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    ${({ isSelected }) =>
        isSelected
            ? `
        cursor: default;

      ::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0px 0px 0px 4px var(--theme-background-color);
      }
        `
            : `
        :hover {
            border: 2px solid var(--theme-color-active);
            ::after {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                pointer-events: none;
                box-shadow: inset 0px 0px 0px 4px var(--theme-background-color);
            }
        }
        `}

    ${({ inStock }) =>
        !inStock &&
        `
      ::before {
        content: '';
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 2px;
        background: ${colors.grey.five};
        pointer-events: none;
      }

        ::after {
          content: '';
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: ${colors.opacity.white.high};
          pointer-events: none;
        }
          `}
`;

const ColorSelectItem = ({ variant = {}, ...rest }) => {
    const { colorOutline, currentVariant, imageSrc, inStock, name, title, to} = variant;
    let border = 'none';
    if (currentVariant) {
        border = '2px solid var(--theme-border-color)';
    }

    if (!currentVariant && colorOutline) {
        border = `1px solid ${colors.grey.two}`;
    }

    return (
        <Li
            border={border}
            height={['40px', null, null, null, '32px']}
            inStock={inStock}
            isSelected={currentVariant}
            title={title || name}
            width={['40px', null, null, null, '32px']}
            {...rest}
        >
            <Link to={!currentVariant && to ? to : ''}>
                <AspectWrapper backgroundColor={colors.placeholder} position="relative" ratio={1}>
                    <Image
                        alt={name}
                        height="100%"
                        objectFit="cover"
                        sizes={['40px', null, null, null, '32px']}
                        src={{ url: imageSrc, width: [40, 80, 120] }}
                    />
                </AspectWrapper>
            </Link>
        </Li>
    );
};

ColorSelectItem.propTypes = {
    variant: PropTypes.object,
};

export default ColorSelectItem;
