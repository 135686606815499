import AspectWrapper from 'components/wrappers/AspectWrapper';
import Image from 'components/Image';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const Content = styled('div')`
    display: flex;
    flex-direction: column;
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ArticleCard = ({
    category = '',
    contentStyles = {},
    heading,
    image,
    imageStyles = {},
    ratio = ratios.vertical,
    to = '',
    ...rest
}) => (
    <Wrapper {...rest}>
        <StyledLink to={to}>
            <AspectWrapper
                backgroundColor={colors.placeholder}
                marginBottom={['8px', null, null, null, '12px']}
                position="relative"
                height="auto"
                ratio={ratio}
                {...imageStyles}
            >
                <Image
                    alt={heading}
                    height="100%"
                    objectFit="cover"
                    sizes={['100vw', null, '33.33vw']}
                    src={{ url: image.url, width: [375, 768, 1024, 1440] }}
                />
            </AspectWrapper>

            {(heading || category) && (
                <Content width="100%" {...contentStyles}>
                    {category && (
                        <Paragraph
                            as="span"
                            width="100%"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            fontKeys={['Value Sans/12', null, null, null, 'Value Sans/14']}
                        >
                            {category}
                        </Paragraph>
                    )}
                    {heading && (
                        <Paragraph fontKeys={['Value Serif/16', null, null, null, 'Value Serif/24']} marginTop="4px">
                            {heading}
                        </Paragraph>
                    )}
                </Content>
            )}
        </StyledLink>
    </Wrapper>
);

ArticleCard.propTypes = {
    category: PropTypes.string,
    contentStyles: PropTypes.object,
    heading: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageStyles: PropTypes.object,
    ratio: PropTypes.number,
    to: PropTypes.string,
};

export default ArticleCard;
