import React, { useState } from 'react';

import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import { injectModels } from 'state';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';
import { variantSelectorConfig } from 'config/variants';

const Button = styled(ThemeButton)`
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: none;
    font-size: 1.6rem;
`;

const getButtonText = buttonState => {
    if (buttonState.warning) {
        return 'checkout_basket.error_try_again';
    }

    if (buttonState.success) {
        return 'checkout_basket.added_to_basket';
    }

    return 'checkout_basket.add_to_basket';
};

const AddToBasketButton = ({
    basket,
    comingSoon = false,
    currentProduct = {},
    inStock = false,
    latestAddedProduct = {},
    selectionState = {},
    size = 'lg',
    theme = 'blue',
    variationId,
    ...rest
}) => {
    const { t } = useTranslation();

    const [buttonState, setButtonState] = useState({
        success: false,
        loading: false,
        warning: false,
    });

    const { selectionIsNeeded, selectionIsNeededFor } = selectionState;

    const addToCart = async quantity => {
        setButtonState({ ...buttonState, loading: true });

        try {
            const response = await basket.addToBasket(variationId, quantity, null);

            if (response.status === 200 || response.status === 201) {
                latestAddedProduct.addLatestProduct(currentProduct);
                setButtonState({ ...buttonState, success: true, loading: false });
            } else {
                setButtonState({ ...buttonState, warning: true, success: false, loading: false });
            }
        } catch (error) {
            console.error(error);
            setButtonState({ ...buttonState, warning: true });
        } finally {
            setTimeout(
                () =>
                    setButtonState({
                        success: false,
                        loading: false,
                        warning: false,
                    }),
                2800
            );
        }
    };

    const getLoading = () => {
        return basket.isFetching || buttonState.loading;
    };

    const isLoading = getLoading();
    const quantity = 1;
    let buttonText;

    if (!inStock) {
        buttonText = comingSoon ? t('product_card.coming_soon') : t('checkout_basket.out_of_stock');
    } else if (selectionIsNeeded) {
        // If we wanted to we could display a message for when there a multiple choices available
        // if (selectionIsNeededFor.length > 1) {
        //     buttonText = t('checkout_basket.choose_multiple');
        // } else {
        buttonText = t(variantSelectorConfig[selectionIsNeededFor[0]].selectionLabel);
        //}
    } else {
        buttonText = t(getButtonText(buttonState));
    }

    return (
        <Button
            disabled={!inStock || selectionIsNeeded}
            size={size}
            theme={theme}
            {...rest}
            onClick={() => {
                if (!isLoading && inStock) {
                    addToCart(quantity);
                }
            }}
        >
            {buttonText}
            <Plus color="currentColor" width="12px" height="12px" marginLeft="6px" />
        </Button>
    );
};

AddToBasketButton.propTypes = {
    basket: PropTypes.shape({
        addToBasket: PropTypes.func,
        toggleBasket: PropTypes.func,
        isFetching: PropTypes.bool,
    }).isRequired,
    comingSoon: PropTypes.bool,
    currentProduct: PropTypes.shape({
        id: PropTypes.string,
        imageSrc: PropTypes.string,
        isOnSale: PropTypes.bool,
        line: PropTypes.string,
        name: PropTypes.string,
        priceWithCurrency: PropTypes.string,
        quantity: PropTypes.string,
        salePriceWithCurrency: PropTypes.string,
        to: PropTypes.string,
    }),
    inStock: PropTypes.bool,
    latestAddedProduct: PropTypes.exact({
        addLatestProduct: PropTypes.func,
        product: PropTypes.object, // Only passing through
    }),
    selectionState: PropTypes.object,
    size: PropTypes.string,
    theme: PropTypes.string,
    variationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default injectModels(['basket', 'latestAddedProduct'])(AddToBasketButton);
