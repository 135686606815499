import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import colors from 'config/theme/colors';
import transitions from 'config/theme/transitions';

const Size = styled('li', {
    shouldForwardProp: prop => 'inStock'.indexOf(prop) === -1,
})`
    position: relative;
    border-radius: 30px;
    cursor: pointer;
    transition: all ${transitions.secondary};

    span {
        display: inline-block;
        padding: 6px 12px;

        ${({ inStock }) =>
            !inStock &&
            `
      ::before {
        content: '';
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 2px;

        background: ${colors.grey.five};
        pointer-events: none;
      }

        ::after {
          content: '';
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: ${colors.opacity.white.high};
          pointer-events: none;
        }
          `}
    }

    :hover {
        border: 2px solid var(--theme-color-active);
        background: var(--theme-background-color);
        color: var(--theme-text-color);
    }
`;

const SizeSelectItem = ({ variant = {}, ...rest }) => {
    const sizeSelectFontStyles = styleObjectToProps('Value Sans/14');
    const { currentVariant, inStock, name, title, to } = variant;

    return (
        <Size
            backgroundColor={currentVariant ? 'transparent' : 'var(--size-selector-background-color)'}
            inStock={inStock}
            border={
                currentVariant
                    ? '2px solid var(--theme-color-active)'
                    : '2px solid var(--size-selector-background-color)'
            }
            color={inStock ? 'var(--theme-text-color)' : 'var(--default-text-color-light)'}
            textDecoration={inStock ? 'none' : 'line-through'}
            {...sizeSelectFontStyles}
            {...rest}
        >
            <Link to={!currentVariant && to ? to : ''} title={title || name}>
                <span>{name}</span>
            </Link>
        </Size>
    );
};

SizeSelectItem.propTypes = {
    variant: PropTypes.object,
};

export default SizeSelectItem;
