import React, { useCallback, useEffect, useRef, useState } from 'react';
import Arrow from 'assets/icons/Arrow';
import Button from 'components/base/Button';
import Image from 'components/Image';
import Plus from 'assets/icons/Plus';
import colors from 'config/theme/colors';
import left from 'assets/icons/cursors/left.svg';
import left_transparent from 'assets/icons/cursors/left_transparent.svg';
import PropTypes from 'prop-types';
import ratios from 'config/theme/ratios';
import right from 'assets/icons/cursors/right.svg';
import right_transparent from 'assets/icons/cursors/right_transparent.svg';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';

const Wrapper = styled('div')`
    position: sticky;
    top: 0;
`;

const LeftSide = styled('div')`
    position: absolute;
    left: 0;
    width: 50%;
    z-index: 1;
`;

const RightSide = styled('div')`
    position: absolute;
    right: 0;
    width: 50%;
    z-index: 1;
`;

const GalleryButtons = styled('div')`
    position: absolute;

    left: 10px;
    z-index: 2;
    display: flex;
    gap: 8px;
    height: 40px;
    bottom: 10px;
`;

const ButtonsContainer = styled('div')`
    position: relative;
    background-color: blue;
    display: flex;

    clip-path: polygon(
        0 5px,
        5px 0,
        calc(100% - 5px) 0,
        100% 5px,
        100% calc(100% - 5px),
        calc(100% - 5px) 100%,
        5px 100%,
        0% calc(100% - 5px),
        0% 5px
    );
`;

const ImageWrapper = styled('div')``;

const Grid = styled('ul')`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    > * {
        scroll-snap-align: start;
    }

    /* Hide scrollbar  */
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const StyledImage = styled(Image)`
    user-select: none; // Prevents ::selection from marking the image
`;

const DesktopCarousel = ({ images, imageConfig, isOneImage, setCurrentImage }) => {
    const scrollRef = useRef(null);
    const wrapperRef = useRef(null);
    const { imageSizes, srcWidths } = imageConfig;
    const headerHeights = useHeaderHeights();

    const [itemWidth, setItemWidth] = useState(0);
    const [state, setState] = useState({ visibleNext: true, visiblePrev: false });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isCmdPressed, setIsCmdPressed] = useState(false);

    const SCROLL_THRESHOLD = 5;

    useEffect(() => {
        const handleKeyDown = event => {
            // Meta is for mac, Control is for windows
            if (event.key === 'Meta' || event.key === 'Control') {
                setIsCmdPressed(true);
            }
        };

        const handleKeyUp = event => {
            // Meta is for mac, Control is for windows
            if (event.key === 'Meta' || event.key === 'Control') {
                setIsCmdPressed(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    // Restore slider to its original state when component is loaded
    useEffect(() => {
        if (scrollRef.current) {
            // Resets position
            scrollRef.current.scrollTo({ left: 0 });
        }
    }, [scrollRef.current, images]);

    const handleScroll = useCallback(() => {
        const node = scrollRef.current;
        if (node) {
            const { scrollWidth, offsetWidth, scrollLeft } = node;

            let imageEnd = 0;

            // Update currentIndex
            for (let i = 0; i < itemWidth.length; i++) {
                let imageStart = imageEnd;
                imageEnd = imageEnd + itemWidth[i];

                if (
                    node.scrollLeft < imageEnd &&
                    node.scrollLeft >= imageStart &&
                    imageEnd - node.scrollLeft > SCROLL_THRESHOLD
                ) {
                    // We haven't scrolled past the image with index i yet, so we set currentindex
                    setCurrentIndex(i);
                    break;
                } else if (node.scrollLeft - imageEnd < 0) {
                    // Sometimes it doesn't scroll the whole way for unkown reason, this makes sure to update the index to the new one if it's very close to the next
                    setCurrentIndex(i + 1);
                    break;
                }
            }

            const newState = { visibleNext: false, visiblePrev: false };

            // Only show arrows if there is a need to scroll
            if (scrollWidth > offsetWidth) {
                // Show prev-arrow if we have scrolled more than the scroll threshold
                if (scrollLeft > SCROLL_THRESHOLD) {
                    newState.visiblePrev = true;
                }

                // Show next-arrow until there is less then the scroll threshold left
                if (offsetWidth + scrollLeft + SCROLL_THRESHOLD < scrollWidth) {
                    newState.visibleNext = true;
                }
            }

            // Only update state if neccessary
            if (newState.visibleNext !== state.visibleNext || newState.visiblePrev !== state.visiblePrev) {
                setState(newState);
            }
        }
    }, [state]);

    const scrollToNext = () => {
        const node = scrollRef.current;
        if (node) {
            const { scrollLeft, scrollWidth, offsetWidth } = node;

            if (scrollWidth - offsetWidth - scrollLeft > SCROLL_THRESHOLD && !itemWidth[currentIndex + 1]) {
                // If last image is atleast the scroll threshold wider than the carouselwidth we scroll to the end.
                node.scrollTo({ left: scrollWidth - offsetWidth, behavior: 'smooth' });
            } else if (scrollWidth - offsetWidth - scrollLeft > SCROLL_THRESHOLD) {
                // Scroll to the next image unless we are at the end
                node.scrollTo({ left: scrollLeft + itemWidth[currentIndex], behavior: 'smooth' });
            }
        }
    };

    const scrollToPrevious = () => {
        if (scrollRef.current) {
            const { scrollWidth, offsetWidth, scrollLeft } = scrollRef.current;
            let scrollTarget;

            if (scrollWidth - offsetWidth - scrollLeft < SCROLL_THRESHOLD && itemWidth[currentIndex + 1]) {
                // User has scrolled all the way(less than the scroll threshold from), last image is not 100% of the width and we can still see the previous image.
                // Since we can see the previous image, the currentIndex hasn't updated yet to the last image, so we scroll to currentIndex position
                scrollTarget = itemWidth?.slice(0, currentIndex)?.reduce((acc, prev) => acc + prev, 0);
            } else if (currentIndex !== 0) {
                // scroll to the previous index
                scrollTarget = itemWidth?.slice(0, currentIndex - 1)?.reduce((acc, prev) => acc + prev, 0);
            }

            scrollRef.current.scrollTo({ left: scrollTarget, behavior: 'smooth' });
            handleScroll();
        }
    };

    useEffect(() => {
        const node = scrollRef.current;

        if (node) {
            const children = [...node.children];

            const scrollWidth = children.map(child => {
                if (child.clientWidth) {
                    return child.clientWidth;
                }
            });

            setItemWidth(scrollWidth);
            node.addEventListener('scroll', handleScroll);
            handleScroll();
        }

        return () => {
            if (node) {
                node.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef, handleScroll]);

    return (
        <Wrapper ref={wrapperRef}>
            <LeftSide
                display={isCmdPressed ? 'none' : 'block'}
                cursor={`url(${state.visiblePrev ? left : left_transparent}) 20 20, pointer`}
                height={`calc(100vh - ${headerHeights.desktop}px)`}
                onClick={() => scrollToPrevious()}
            />
            <RightSide
                display={isCmdPressed ? 'none' : 'block'}
                cursor={`url(${state.visibleNext ? right : right_transparent}) 20 20, pointer`}
                height={`calc(100vh - ${headerHeights.desktop}px)`}
                onClick={() => scrollToNext()}
            />
            <GalleryButtons>
                <ButtonsContainer>
                    <Button width="40px" height="40px" onClick={() => setCurrentImage(currentIndex)}>
                        <Plus color={colors.white} width="12px" />
                    </Button>
                </ButtonsContainer>
                <ButtonsContainer>
                    <Button width="40px" height="40px" onClick={() => scrollToPrevious()}>
                        <Arrow opacity={state.visiblePrev ? 1 : 0.5} transform="rotate(180deg)" color={colors.white} />
                    </Button>
                    <Button width="40px" height="40px" onClick={() => scrollToNext()}>
                        <Arrow
                            opacity={state.visibleNext ? 1 : 0.5}
                            transform="translateY(-1px)"
                            color={colors.white}
                        />
                    </Button>
                </ButtonsContainer>
            </GalleryButtons>
            <Grid height={`calc(100vh - ${headerHeights.desktop}px)`} ref={scrollRef}>
                {images?.map((image, index) => {
                    const addFrame = isOneImage && index === 1;
                    // make custom srcHeights for desktop since it has both 4/3 and 3/4 images
                    const srcHeights = srcWidths.map(
                        width =>
                            image.width > image.height
                                ? parseInt(width * ratios.vertical, 10) // horizontal
                                : parseInt(width * ratios.horizontal, 10) // vertical
                    );
                    return (
                        <ImageWrapper
                            key={`${image.src}-${index}`}
                            background={addFrame ? colors.yellow : null}
                            height="100%"
                            aspectRatio={image.width > image.height ? ratios.horizontal : ratios.vertical}
                            padding={addFrame ? '80px 60px' : null}
                        >
                            <StyledImage
                                alt={image.alt || image.name}
                                fetchpriority={index < 3 ? 'high' : 'low'}
                                height="100%"
                                loading="none"
                                objectFit="cover"
                                quality="70"
                                sizes={imageSizes}
                                src={{ url: image.src, width: srcWidths, height: srcHeights }}
                                title={image.name}
                                userSelect="none" // Prevents ::selection from marking the image
                            />
                        </ImageWrapper>
                    );
                })}
            </Grid>
        </Wrapper>
    );
};

DesktopCarousel.propTypes = {
    images: PropTypes.array,
    imageConfig: PropTypes.object,
    isOneImage: PropTypes.bool,
    setCurrentImage: PropTypes.func,
};

export default DesktopCarousel;
