import G from 'assets/icons/base/G';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Piggy = ({ color = colors.black, height = '100%', viewBox = '0 0 37 43', width = '100%', ...rest }) => (
    <SVG width={width} height={height} display="flex" viewBox={viewBox} {...rest}>
        <G id="Artboard1" transform="matrix(1.01195,3.0955e-19,3.12172e-19,1.01925,-0.537594,-0.891841)">
            <Path
                d="M16.977 19.129v9.266h-3.613v-9.266c0-4.106.598-6.168 1.793-6.186 1.213-.016 1.819 2.046 1.82 6.186Zm7.874 0v9.266h-3.613v-9.266c0-4.106.598-6.168 1.792-6.186 1.214-.016 1.821 2.046 1.821 6.186Zm7.426-12.681c3.211 3.711 4.817 8.886 4.817 15.523 0 6.638-1.615 11.812-4.843 15.524-3.23 3.712-7.702 5.568-13.417 5.568-5.747 0-10.236-1.856-13.466-5.568C2.138 33.783.526 28.607.531 21.966c0-6.638 1.615-11.812 4.843-15.523C8.603 2.732 13.091.876 18.84.875c5.748.004 10.228 1.862 13.441 5.573h-.004Zm-18.423.911c-1.389 1.311-2.446 3.215-3.173 5.711-.727 2.496-1.09 5.471-1.09 8.925 0 5.215.816 9.286 2.447 12.213 1.631 2.927 3.899 4.391 6.805 4.391 2.906 0 5.166-1.464 6.78-4.391 1.615-2.927 2.422-6.998 2.423-12.213 0-5.214-.807-9.285-2.423-12.212-1.615-2.927-3.875-4.391-6.78-4.391-1.939 0-3.602.656-4.989 1.967Z"
                fill={color}
            />
        </G>
    </SVG>
);

Piggy.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Piggy;
