import React, { useEffect, useRef, useState } from 'react';

import ByonLogo from 'assets/icons/logos/ByonLogo';
import Link from 'components/base/Link';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    width: 100%;
`;

const StickyLogo = styled('div')`
    position: fixed;
    top: 12px;
    left: 50%;
    transition: transform ${transitions.primary};
`;

const LogoFullWidth = ({ ...rest }) => {
    const logoRef = useRef();
    const [logoInView, setLogoInView] = useState(true);

    /*
     *  Let an IntersectionObserver check if the logoRef is in the viewport, if not we let the StickyLogo slide down instead.
     *  The observer will trigger when the wrapper is out of the viewport and an additional 50% of the viewport(rootMargin).
     */
    useEffect(() => {
        const ref = logoRef.current;
        const options = {
            rootMargin: '50%',
            threshold: 0,
        };

        const observer = new IntersectionObserver(entries => {
            const entry = entries[0];
            setLogoInView(entry.isIntersecting);
        }, options);

        observer.observe(ref);

        return () => observer.unobserve(ref);
    }, []);

    return (
        <Wrapper ref={logoRef} {...rest}>
            <Link to="/">
                <ByonLogo width="100%" height="auto" />
            </Link>
            <Link to="/">
                <StickyLogo
                    zIndex={zIndex.stickyLogo}
                    transform={!logoInView ? 'translate(-50%, 0)' : 'translate(-50%, calc(-100% - 24px))'}
                    display="flex"
                    justifyContent="center"
                >
                    <ByonLogo width="132px" height="31.625px" />
                </StickyLogo>
            </Link>
        </Wrapper>
    );
};

export default LogoFullWidth;
