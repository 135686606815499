/*
 * NOTE: use this file to setup your projects overall drop shadows.
 * Customize as your design is.
 */

const dropShadows: { primary: string } = {
    primary: '0 2px 16px',
};

export default dropShadows;
