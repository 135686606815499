import JumpingPiggy from 'assets/icons/animatedIcons/JumpingPiggy';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
const Wrapper = styled('div')``;

const Animation = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const TextAndButton = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

// @todo: Add animation
const EmptyBasket = ({ closeBasket = () => {} }) => {
    const { text = '', button = {} } = useSelector(state => state.header.data.basket?.empty || {});

    return (
        <Wrapper>
            <Animation borderBottom="2px solid var(--default-border-color)" marginBottom="24px">
                <JumpingPiggy width={['33%', null, '25%']} />
                <JumpingPiggy width={['33%', null, '25%']} delay="-0.2" />
                <JumpingPiggy width={['33%', null, '25%']} delay="-0.4" />
            </Animation>
            <TextAndButton>
                {text && (
                    <Paragraph marginRight="4px" width="66.66666667%">
                        {text}
                    </Paragraph>
                )}
                {button.text && button.url && (
                    <ThemeButton
                        cornerBackground="var(--basket-background-color)"
                        marginLeft="4px"
                        theme="outlinedBlack"
                        to={button.url}
                        width="33.33333333%"
                        onClick={() => closeBasket()}
                    >
                        {button.text}
                    </ThemeButton>
                )}
            </TextAndButton>
        </Wrapper>
    );
};

EmptyBasket.propTypes = {
    closeBasket: PropTypes.func.isRequired,
};

export default EmptyBasket;
