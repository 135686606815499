interface Grey {
    one: string;
    two: string;
    three: string;
    four: string;
    five: string;
    six: string;
}

interface Opacity {
    black: {
        low: string;
        medium: string;
        high: string;
    };
    blue: {
        low: string;
        high: string;
    };
    white: {
        low: string;
        high: string;
    };
    red: {
        low: string;
    };
    yellow: {
        low: string;
    };
}

interface Tint {
    blue: string;
    red: string;
    yellow: string;
}

interface Colors {
    background: string;
    black: string;
    blue: string;
    error: string;
    grey: Grey;
    opacity: Opacity;
    placeholder: string;
    red: string;
    success: string;
    tint: Tint;
    white: string;
    yellow: string;
}

/**
 * Add new colors from design to the palette object
 * Add color name into to ColorType
 * Customize as needed
 */

const palette: {
    black: string[];
    blue: string[];
    grey: string[];
    red: string[];
    white: string[];
    yellow: string[];
} = {
    black: ['#000000', 'rgba(0,0,0,0.6)', 'rgba(0,0,0,0.3)', 'rgba(0,0,0,0.1)'], // Basic Colors Black, Basic Colors Black 60%, Basic Colors Black 30%, Basic Colors Black 10%
    blue: ['#2D53F6', '#CAEBFF', 'rgba(45, 83, 246, 0.6)', 'rgba(45, 83, 246, 0.2)'], // Primary Blue, Secondary Blue tint, Primary Blue 60%, Primary Blue 20%
    grey: ['#F3F3F3', '#D0D0D0', '#C0C0C0', '#969696', '#676767', '#3C3C3C'], // Grey 01, Grey 02, Grey 03, Grey 04, Grey 05, Grey 06
    red: ['#D81E1E', '#FFCAC7', 'rgba(216, 30, 30, 0.6)'], // Primary Red, Secondary Red tint, Primary Red 60%
    white: ['#FFFFFF', 'rgba(255,255,255,0.8)', 'rgba(255,255,255,0.6)'], // Basic Colors White, Basic Colors White 80%, Basic Colors White 60%
    yellow: ['#FEA500', '#FFE3CC', 'rgba(254, 165, 0, 0.6)'], // Primary Yellow, Secondary Yellow Tint, Primary Yellow 60%
};

const colors: Colors = {
    background: palette.white[0],
    black: palette.black[0],
    blue: palette.blue[0],
    error: palette.yellow[0],
    grey: {
        one: palette.grey[0],
        two: palette.grey[1],
        three: palette.grey[2],
        four: palette.grey[3],
        five: palette.grey[4],
        six: palette.grey[5],
    },
    opacity: {
        black: {
            low: palette.black[1],
            medium: palette.black[2],
            high: palette.black[3],
        },
        blue: {
            low: palette.blue[2],
            high: palette.blue[3],
        },
        red: {
            low: palette.red[2],
        },
        white: {
            low: palette.white[1],
            high: palette.white[2],
        },
        yellow: {
            low: palette.yellow[2],
        },
    },
    placeholder: palette.grey[0],
    red: palette.red[0],
    success: palette.black[0],
    tint: {
        blue: palette.blue[1],
        red: palette.red[1],
        yellow: palette.yellow[1],
    },
    white: palette.white[0],
    yellow: palette.yellow[0],
};

export default colors;
