/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Heading from 'components/text/Heading';
import NewsletterCheckbox from 'components/Newsletter/NewsletterCheckbox';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { injectModels } from 'state';
import regExp from 'utils/regExp';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    width: 100%;
`;

const FormWrapper = styled('form')`
    position: relative;
    display: inline-block;
    width: 100%;
    // not(:placeholder-shown) ~ label Keeps label up when value is invalid
    input:focus ~ label,
    input:valid ~ label,
    margin-bottom: 20px;
    input:not(:placeholder-shown) ~ label {
        bottom: 100%;
        font-size: 12px;
        line-height: 1;
    }
`;

const MessageRow = styled('p')`
    margin-bottom: 16px;
`;

const ErrorWrapper = styled('div')``;

const ErrorMessages = styled('span')`
    color: var(--footer-error-color);
    margin-right: 12px;
    :before {
        content: '*';
    }
    &:last-of-type {
        margin-right: 0;
    }
`;

const EmailInput = styled('input')`
    width: 100%;
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    border-radius: 0;
    outline: 0;
    opacity: ${({ loading }) => (loading ? '0.2' : '1')};
    padding-right: 80px;
    padding-bottom: 4px;
    line-height: 1;
    &::-ms-clear {
        display: none;
    }
    &::-webkit-input-placeholder {
        opacity: 0;
    }
    &::-moz-placeholder {
        opacity: 0;
    }
    &:-moz-placeholder {
        opacity: 0;
    }
    &:-ms-input-placeholder {
        opacity: 0;
    }
`;

const Label = styled('label')`
    position: absolute;
    left: 0;
    padding-right: 80px;
    transition: all ${transitions.primary};
    cursor: text;
    user-select: none;
    pointer-events: none;
    bottom: 12px;
`;

const InputWrapper = styled('div')`
    position: relative;
`;

const NewsletterWrapper = styled('div')`
    margin-top: 16px;
`;

class Newsletter extends Component {
    static propTypes = {
        backgroundColor: PropTypes.string,
        buttonTheme: PropTypes.string,
        checkboxErrorText: PropTypes.string,
        formErrorText: PropTypes.string,
        formSuccessText: PropTypes.string,
        heading: PropTypes.string,
        headingTag: headingTagProp,
        hoverColor: PropTypes.string,
        id: PropTypes.string.isRequired,
        inputErrorText: PropTypes.string,
        inputTextColor: PropTypes.string,
        linkColor: PropTypes.string,
        newsletter: PropTypes.shape({ subscribe: PropTypes.func }).isRequired,
        onSignUp: PropTypes.func,
        placeholder: PropTypes.string,
        privacyText: wysiwygProp,
        submitLabel: PropTypes.string,
        textColor: PropTypes.string,
    };

    static defaultProps = {
        backgroundColor: '',
        buttonTheme: 'outlinedWhite',
        checkboxErrorText: 'Please accept the privacy policy',
        formErrorText: 'Something went wrong, please try again or contact support',
        formSuccessText: 'Thank you for signing up!',
        heading: undefined,
        headingTag: 'h2',
        hoverColor: 'var(--default-color-active)',
        inputErrorText: 'Please enter correct email',
        linkColor: 'var(--default-link-color)',
        onSignUp: () => {},
        inputTextColor: '',
        placeholder: 'Enter email here',
        privacyText: ['I have read and accepted the privacy policy'],
        submitLabel: 'Sign up',
        textColor: '',
    };

    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.checkboxInput = React.createRef();
    }

    state = {
        email: null,
        emailVerified: false,
        privacyIsChecked: false,
        signupComplete: false,
        signupFailed: false,
        errors: false,
        timeout: null,
        loading: false,
    };

    handleCheckboxChange = e => {
        this.setState({ privacyIsChecked: e.target.checked });
    };

    handleEmailChange = ({ target }) => {
        let emailVerified = false;

        if (target.value.length < 1) {
            this.setState({ errors: false });
        }

        const value = target.value.trim();
        emailVerified = regExp.validation.correctEmail.test(value);

        this.setState({ emailVerified, email: value });
    };

    handleSubmit = async () => {
        const { privacyIsChecked, emailVerified, email } = this.state;
        const { newsletter } = this.props;

        this.setState({
            errors: true,
            loading: true,
        });

        if (!privacyIsChecked || !emailVerified) {
            this.setState({ loading: false });
        }

        if (privacyIsChecked && emailVerified) {
            try {
                await newsletter.subscribe('default', { email });

                this.setState({ signupComplete: true }, () => {
                    this.props.onSignUp();
                });
            } catch (err) {
                this.setState({ signupFailed: true });
            }

            this.setState({
                errors: false,
                emailVerified: false,
                privacyIsChecked: false,
            });

            if (this.state.signupComplete || this.state.signupFailed) {
                this.setState({ loading: false });
            }

            if (this.emailInput.current) {
                this.emailInput.current.blur();
                this.emailInput.current.value = '';
            }

            if (this.checkboxInput.current) {
                this.checkboxInput.current.checked = false;
            }

            this.state.timeout = setTimeout(() => {
                clearTimeout(this.state.timeout);
                this.resetNewsletter();
            }, 20000);
        }
    };

    resetNewsletter = () => {
        clearTimeout(this.state.timeout);
        this.setState({
            email: null,
            signupComplete: false,
            signupFailed: false,
            timeout: null,
            loading: false,
        });
    };

    render() {
        const {
            backgroundColor,
            buttonTheme,
            submitLabel,
            checkboxErrorText,
            formErrorText,
            formSuccessText,
            heading,
            headingTag,
            hoverColor,
            id,
            inputErrorText,
            inputTextColor,
            linkColor,
            placeholder,
            privacyText,
            textColor,
        } = this.props;

        const { privacyIsChecked, emailVerified, errors, signupComplete, signupFailed, loading } = this.state;

        const labelsTextStyles = styleObjectToProps(['Value Sans/20', null, null, null, 'Value Sans/24']);
        const inputTextStyles = styleObjectToProps(['Value Sans/16', null, null, null, 'Value Sans/20']);
        const alertTextStyles = styleObjectToProps(['Value Sans/16', null, null, null, 'Value Sans/20']);

        const placeholderText = decodeHTMLEntities(
            signupComplete ? formSuccessText : signupFailed ? formErrorText : `${placeholder}*`
        );

        return (
            <Wrapper backgroundColor={backgroundColor} color={textColor}>
                {heading && (
                    <Heading
                        as={headingTag}
                        fontKeys={[
                            'Value Serif/32-scalable-mobile',
                            null,
                            'Value Serif/32-scalable-tablet',
                            null,
                            'Value Serif/32-scalable-desktop',
                        ]}
                        marginBottom="48px"
                    >
                        {heading}
                    </Heading>
                )}

                <FormWrapper
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.handleSubmit();
                    }}
                >
                    <InputWrapper>
                        <EmailInput
                            id={id}
                            type="email"
                            name="rule_email"
                            focus={signupComplete ? 1 : 0}
                            borderColor={textColor}
                            color="var(--footer-text-color)"
                            maxLength="75"
                            placeholder={placeholder}
                            loading={loading ? 1 : 0}
                            ref={this.emailInput}
                            {...inputTextStyles}
                            onChange={this.handleEmailChange}
                        />
                        {!errors && placeholder && (
                            <Label
                                color={inputTextColor ? inputTextColor : textColor}
                                {...labelsTextStyles}
                                htmlFor={id}
                            >
                                {placeholderText}
                            </Label>
                        )}
                    </InputWrapper>
                    <NewsletterWrapper hoverColor={hoverColor} linkColor={linkColor} marginBottom="16px">
                        <NewsletterCheckbox
                            id="confirmation"
                            checkboxSize="12px"
                            hoverColor={hoverColor}
                            linkColor={linkColor}
                            color={textColor}
                            privacyText={privacyText}
                            ref={this.checkboxInput}
                            handleChange={e => this.handleCheckboxChange(e)}
                        />
                    </NewsletterWrapper>
                    {errors && (
                        <ErrorWrapper {...alertTextStyles}>
                            {!emailVerified && errors && (
                                <MessageRow>
                                    <ErrorMessages>{decodeHTMLEntities(inputErrorText)}</ErrorMessages>
                                </MessageRow>
                            )}
                            {!privacyIsChecked && errors && (
                                <MessageRow>
                                    <ErrorMessages>{decodeHTMLEntities(checkboxErrorText)}</ErrorMessages>
                                </MessageRow>
                            )}
                        </ErrorWrapper>
                    )}
                    <ThemeButton theme={buttonTheme} cornerBackground={backgroundColor} mt="8px" type="submit">
                        {submitLabel}
                    </ThemeButton>
                </FormWrapper>
            </Wrapper>
        );
    }
}

export default injectModels(['newsletter', 'application'])(Newsletter);
