import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Basket = ({ color = colors.black, height = '100%', width = '24px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 24 24" width={width} {...rest}>
        <Path
            d="M22 4.5H1v10.2a9 9 0 0 0 .615 3.3 9.704 9.704 0 0 0 2.175 3.33 10.5 10.5 0 0 0 3.555 2.055c1.177.407 2.414.615 3.66.615h1.065a11.55 11.55 0 0 0 3.57-.57 10.922 10.922 0 0 0 3.57-2.025 9.646 9.646 0 0 0 2.175-3.315A9.345 9.345 0 0 0 22 14.775V4.5Zm-2.25 10.26a4.364 4.364 0 0 1-2.175 3.885A11.235 11.235 0 0 1 11.5 20.04c-2.09.103-4.169-.38-6-1.395a4.395 4.395 0 0 1-2.25-3.885V7.5h16.5v7.26Z"
            fill={color}
        />
        <Path
            d="M19 5.25h-3l-1.08-2.175a1.5 1.5 0 0 0-1.35-.825H9.43a1.5 1.5 0 0 0-1.35.825L7 5.25H4L7 0h9l3 5.25Z"
            fill={color}
        />
    </SVG>
);

Basket.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Basket;
