import Circle from 'assets/icons/base/Circle';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Decrease = ({ color = colors.black, height = '100%', width = '24px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 24 24" width={width} {...rest}>
        <Path fill={color} d="M6 11h12v1.5H6z" />
        <Circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2" />
    </SVG>
);

Decrease.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Decrease;
