import React, { useState } from 'react';

import Loading from './Loading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import { basketProps } from 'utils/proptypes/basket';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const Button = styled('button')`
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
`;

const Voucher = ({ discountId = '', removeVoucher = () => {} }) => {
    const { t } = useTranslation();
    const [showLoader, setLoading] = useState(false);
    const voucherTextStyles = styleObjectToProps('Value Serif/16');

    const remove = () => {
        setLoading(true);

        removeVoucher(discountId).then(() => {
            setLoading(false);
        });
    };

    return (
        <Wrapper {...voucherTextStyles}>
            <Paragraph as="span">{`${t('checkout_basket.voucher')}: ${discountId}`}</Paragraph>
            <Button type="button" onClick={remove}>
                {showLoader ? <Loading /> : t('checkout_basket.remove')}
            </Button>
        </Wrapper>
    );
};

Voucher.propTypes = {
    discountId: PropTypes.string.isRequired,
    removeVoucher: basketProps.removeVoucher.isRequired,
};

export default Voucher;
