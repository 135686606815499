import React, { forwardRef, useEffect, useRef } from 'react';

import useIntersectionObserver from 'hooks/useIntersectionObserver';

/**
 * withAnimation
 * @version 1.1
 *
 * @param {node} Component - The component to observe and animate
 * @param {node} [options.root] - Used by useIntersectionObserver
 * @param {string} [options.rootMargin="0px 0px 0px 0px"] - Pixles of node to show before triggering the animation. Ex: Tigger after 200px = "-200px 0 0 0".
 * @param {number} [options.threshold=0] - Percentage of node to show before triggering the animation. Ex: Tigger after 50% = 0.5.
 * @param {number} [options.triggerOnce=true] - Define if the animation should trigger once. Used by useIntersectionObserver.
 * @param {boolean} [props.active] - Option to turn on / off.
 *
 * How to use:
 * Add css similar to this to the wrapped element:
 * opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
 *
 * Note: If it is critical that the element is visible in non-js browsers its possible to the following.
 * By doing so elements above the fold will not animate, but the element will not be hidden as default.
 * &[data-animate='true'] {
 *    opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
 * }
 * Note: Remember to use forward ref when passing a react component instead of a styled component!
 */

const withAnimation = (Component, options = {}) =>
    // Proptypes is not working for some reason when forwardRef is used combined with a HOC.
    // eslint-disable-next-line react/prop-types
    forwardRef(({ active = true, ...rest }, ref) => {
        const { root, rootMargin = '0px 0px 0px 0px', threshold = 0, triggerOnce = true } = options;

        if (!active) {
            return <Component {...rest} />;
        }

        const node = ref || useRef();
        const [entry, setNode] = useIntersectionObserver({ root, rootMargin, threshold, triggerOnce });

        useEffect(() => {
            setNode(node.current);
        }, [node, setNode]);

        return (
            <Component
                {...rest}
                data-animate={!!entry.boundingClientRect}
                animateIn={entry.isIntersecting}
                ref={node}
            />
        );
    });
export default withAnimation;
