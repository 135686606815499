import Close from 'assets/icons/Close';
import Empty from 'Header/Default/Basket/Empty';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import WithProducts from 'Header/Default/Basket/WithProducts';
import { above } from 'utils/mediaqueries';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import { transformBasketProductData } from 'utils/dataTransformers/product';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'headerHeights', 'headerIsVisible'].indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    max-height: ${({ clientHeight, headerHeights, headerIsVisible }) =>
        headerIsVisible ? `calc(${clientHeight} - ${headerHeights.mobileAndBreadcrumbsBar}px)` : clientHeight};

    ${above.tabletSm} {
        max-height: ${({ clientHeight, headerHeights, headerIsVisible }) =>
            headerIsVisible ? `calc(${clientHeight} - ${headerHeights.tabletAndBreadcrumbsBar}px)` : clientHeight};
    }

    ${above.tabletSm} {
        max-height: ${({ clientHeight, headerHeights, headerIsVisible }) =>
            headerIsVisible ? `calc(${clientHeight} - ${headerHeights.desktopAndBreadcrumbsBar}px)` : clientHeight};
    }
`;

const TopSection = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

const Basket = () => {
    const { t } = useTranslation();
    const clientHeight = useClientHeight();
    const headerHeights = useHeaderHeights();
    const overlay = useSelector(state => state.overlay);
    const basketItems = useSelector(state => state.basket.items || []);
    const totalNumberOfProducts = useSelector(state => state.basket.totalNumberOfProducts);
    const label = useSelector(state => state.header.data.basket?.label || '');
    const headerIsVisible = useSelector(state => state.header.state.headerIsVisible);
    const hasProducts = basketItems?.length > 0;
    const basketLabel = label || t('checkout_basket.basket');

    const closeBasket = () => {
        overlay.hide();
    };

    return (
        <Wrapper
            clientHeight={clientHeight}
            headerHeights={headerHeights}
            headerIsVisible={headerIsVisible}
            padding={contentMargins}
        >
            <TopSection>
                <Paragraph as="h2" fontKeys={['Value Serif/20', null, 'Value Serif/24']}>
                    {`${basketLabel}(${totalNumberOfProducts})`}
                </Paragraph>
                <button type="button" onClick={() => closeBasket()}>
                    <Close />
                </button>
            </TopSection>
            {hasProducts ? (
                <WithProducts closeBasket={closeBasket} products={basketItems.map(transformBasketProductData)} />
            ) : (
                <Empty closeBasket={closeBasket} />
            )}
        </Wrapper>
    );
};

export default Basket;
