import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import withHeightAuto from 'utils/withHeightAuto';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
`;

const Div = styled('div')``;

const InnerWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const AnimationWrapper = withHeightAuto(styled('div')`
    overflow: hidden;
`);

const SingleAccordion = ({
    anchorTag = '',
    animationDuration = 0,
    children,
    colorText,
    fontKeys,
    handleClick,
    headerPX = '12px',
    headerPY = '16px',
    hideIcon = false,
    iconSize = ['16px', null, '24px'],
    isActive = false,
    maxWithContent = false,
    text = '',
    timingFunction = 'linear',
    url = '',
    ...rest
}) => {
    // Add MaxWidthWrapper with includeContentMargins prop if maxWithContent is true
    const RenderMaxWidth = maxWithContent ? MaxWidthWrapper : Div;
    const RenderMaxWidthProps = maxWithContent ? { includeContentMargins: true } : {};

    return (
        <Wrapper color={colorText} id={anchorTag} {...rest}>
            <RenderMaxWidth px="0" {...RenderMaxWidthProps}>
                <Link volatile to={url || null} style={{ textDecoration: 'none' }}>
                    <InnerWrapper px={headerPX} py={headerPY} onClick={children && !url ? handleClick : null}>
                        <Heading as="h3" fontKeys={fontKeys}>
                            {text}
                        </Heading>
                        {children && !hideIcon && (
                            <Plus
                                color={colorText}
                                height={iconSize}
                                transform={isActive ? 'rotate(45deg)' : 'rotate(0)'}
                                transition={`transform ${transitions.primary}`}
                                width={iconSize}
                            />
                        )}
                    </InnerWrapper>
                </Link>
                {children && (
                    <AnimationWrapper
                        animationDuration={animationDuration}
                        height={isActive ? 'auto' : '0'}
                        transition={animationDuration > 0 && `${animationDuration}ms ${timingFunction}`}
                    >
                        {children}
                    </AnimationWrapper>
                )}
            </RenderMaxWidth>
        </Wrapper>
    );
};

SingleAccordion.propTypes = {
    anchorTag: PropTypes.string,
    animationDuration: PropTypes.number,
    children: PropTypes.node,
    colorText: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    handleClick: PropTypes.func.isRequired,
    headerPX: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    headerPY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    hideIcon: PropTypes.bool,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isActive: PropTypes.bool,
    maxWithContent: PropTypes.bool,
    text: PropTypes.string,
    timingFunction: PropTypes.string,
    url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default SingleAccordion;
