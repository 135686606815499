import { useSelector } from 'react-redux';

// Banner
export const bannerHeight = 26;

// BreadcumbsBar
export const breadcrumbsBarHeight = 32;

// Default
export const mobileHeaderHeight = 80;
export const tabletHeaderHeight = 72;
export const desktopHeaderHeight = 63;

// Checkout
export const mobileCheckoutHeaderHeight = 88;
export const tabletCheckoutHeaderHeight = 92;
export const desktopCheckoutHeaderHeight = 108;

/**
 * Used for handling height regarding the header across the site.
 */

const useHeaderHeights = () => {
    const hasBanner = useSelector<any, boolean>(state => state.header.state.hasBanner);
    const pageTemplate = useSelector<any, string>(state => state.page.template);
    const isFrontPage = pageTemplate === 'frontpage';
    const breadcrumbsBarIsVisible = useSelector<any, boolean>(state => state.header.state.breadcrumbsBarIsVisible);
    const banner = hasBanner ? bannerHeight : 0;
    const breadcrumbsBar = breadcrumbsBarIsVisible && !isFrontPage ? breadcrumbsBarHeight : 0;

    return {
        banner,
        breadcrumbsBar,
        desktop: desktopHeaderHeight,
        desktopAndBreadcrumbsBar: desktopHeaderHeight + breadcrumbsBar,
        mobile: mobileHeaderHeight,
        mobileAndBreadcrumbsBar: mobileHeaderHeight + breadcrumbsBar,
        tablet: tabletHeaderHeight,
        tabletAndBreadcrumbsBar: tabletHeaderHeight + breadcrumbsBar,
    };
};

export default useHeaderHeights;
