import { mobileHeaderHeight, tabletHeaderHeight } from 'hooks/useHeaderHeights';

import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import { transformProductPageBreadcrumbs } from 'utils/dataTransformers/product';
import transitions from 'config/theme/transitions';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    z-index: ${zIndex.menuMobile};
    background: var(--breadcrumbs-bar-background-color);
    transition: transform ${transitions.primary};
`;

// Breadcrumbs component used together with navigation/header
const BreadcrumbsBar = ({ show = false, ...rest }) => {
    const pageType = useSelector(state => state.page.type);
    const pageBreadcrumbs = useSelector(state => state.page.breadcrumbs);
    const pageBreadcrumbsTwo = useSelector(state => state.page.data?.breadcrumbs);
    const productPageTitle = useSelector(state => state.page.data?.name);
    const pageTitle = useSelector(state => state.page.data?.title);
    const productPageRelationships = useSelector(state => state.page.relationships?.ecommerce_product_category);

    let breadcrumbs = [];
    let title = '';

    if (pageType === 'product') {
        title = productPageTitle;
        breadcrumbs = productPageRelationships ? transformProductPageBreadcrumbs(productPageRelationships) : [];
    } else if (pageType === 'customer_service') {
        title = pageTitle;
        breadcrumbs = pageBreadcrumbsTwo || [];
    } else {
        title = pageTitle;
        breadcrumbs = pageBreadcrumbs || [];
    }

    if (breadcrumbs.length < 1 && !pageTitle) {
        return null;
    }

    return (
        <Wrapper
            bottom={[`${mobileHeaderHeight}px`, null, `${tabletHeaderHeight}px`]}
            transform={
                show
                    ? 'none'
                    : [
                          `translateY(calc(100% + ${mobileHeaderHeight}px))`,
                          null,
                          `translateY(calc(100% + ${tabletHeaderHeight}px))`,
                      ]
            }
        >
            <Breadcrumbs
                activeColor="var(--breadcrumbs-bar-text-color-active)"
                currentTitle={title}
                items={breadcrumbs}
                linkColor="var(--breadcrumbs-bar-text-color)"
                width="100%"
                {...rest}
            />
        </Wrapper>
    );
};

BreadcrumbsBar.propTypes = {
    show: PropTypes.bool,
};

export default BreadcrumbsBar;
