import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledBadge = styled('div')`
    padding: 4px 6px;

    ${above.desktopSm} {
        padding: 4px 8px;
    }
`;

// Theme names = background-color_text-color
const THEMES = {
    // Automatic - Sustainability or material
    'blue_light-blue': {
        backgroundColor: colors.blue,
        color: colors.tint.blue,
    },

    // Manual - Centra
    'blue_light-yellow': {
        backgroundColor: colors.blue,
        color: colors.tint.yellow,
    },

    // Manual - Centra and Online Exclusive
    'light-blue_blue': {
        backgroundColor: colors.tint.blue,
        color: colors.blue,
    },

    // Automatic - Stock status
    'light-red_red': {
        backgroundColor: colors.red,
        color: colors.tint.red,
    },

    // Manual - Centra
    'light-yellow_blue': {
        backgroundColor: colors.tint.yellow,
        color: colors.blue,
    },

    // Automatic - Discount
    'red_light-red': {
        backgroundColor: colors.red,
        color: colors.tint.red,
    },

    // Automatic - New and Coming soon
    'yellow_blue': {
        backgroundColor: colors.yellow,
        color: colors.blue,
    },

    // Manual - Centra
    'yellow_white': {
        backgroundColor: colors.yellow,
        color: colors.white,
    },
};

const Badge = ({ theme = 'blue_light-yellow', ...rest }) => {
    const themeStyling = THEMES[theme] || THEMES['blue_light-yellow'];
    const fontStyles = styleObjectToProps('Value Serif/12');

    return <StyledBadge {...themeStyling} {...fontStyles} {...rest} />;
};

Badge.propTypes = {
    theme: PropTypes.string.isRequired,
};

export default Badge;
