import Image from 'components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const ImageWrapper = styled('div')`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: ${colors.placeholder};

    .hover-image {
        opacity: 0;
    }
`;

const StyledImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity ${transitions.primary};
`;

const ProductCardImage = ({
    alt = '',
    hoverImage = '',
    imageSizes = ['50vw', '50vw', '33.33vw', null, '650px'],
    loadImage = true,
    primaryImage,
    srcWidths = [188, 384, 341, 480, 650],
    title,
}) => {
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    return (
        <ImageWrapper>
            {loadImage && (
                <>
                    <StyledImage
                        alt={alt || title}
                        fm="jpg"
                        sizes={imageSizes}
                        src={{ url: primaryImage, width: srcWidths, height: srcHeights }}
                        title={title}
                    />
                    {hoverImage && (
                        <StyledImage
                            alt={alt || title}
                            className="hover-image"
                            fm="jpg"
                            sizes={imageSizes}
                            src={{ url: hoverImage, width: srcWidths, height: srcHeights }}
                            title={title}
                        />
                    )}
                </>
            )}
        </ImageWrapper>
    );
};

ProductCardImage.propTypes = {
    alt: PropTypes.string,
    hoverImage: PropTypes.string,
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    loadImage: PropTypes.bool,
    primaryImage: PropTypes.string.isRequired,
    srcWidths: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    title: PropTypes.string.isRequired,
};

export default ProductCardImage;