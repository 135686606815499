import React, { useState } from 'react';

import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import SingleAccordion from 'components/accordions/SingleAccordion';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import textStylesConfig from 'config/theme/textStyles';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const Wrapper = styled('div')``;

const Content = styled('div')`
    width: 100%;
    padding-top: 32px;
`;

const Table = styled('table')`
    width: 100%;
    margin: 0;
    padding: 0;
`;

const TableRow = styled('tr')`
    display: flex;
    padding: 8px 0 16px;
    text-align: left;
    align-items: flex-start;

    :first-of-type {
        border-top: 2px solid var(--theme-border-color);
    }

    :not(:last-of-type) {
        border-bottom: 2px solid var(--theme-border-color);
    }
`;

const TableCol = styled('th')`
    width: 50%;
    padding: 0;
    display: flex;
    align-items: center;
`;

const ProductInformation = ({ characteristics, attributesLabel = '', description, informationLabel = '', ...rest }) => {
    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const [activeAccordion, setActiveAccordion] = useState(isAboveDesktopSm);
    const tableColFontStyles = styleObjectToProps('Value Sans/12', textStylesConfig.paragraph);

    return (
        <Wrapper {...rest}>
            {description && (
                <>
                    <Heading
                        as="h2"
                        fontKeys={['Value Serif/24', null, null, null, 'Value Serif/32-scalable-desktop']}
                        marginBottom="16px"
                    >
                        {informationLabel}
                    </Heading>
                    <Paragraph marginBottom="40px" dangerouslySetInnerHTML={{ __html: description }} />
                </>
            )}
            {characteristics?.length > 0 && (
                <SingleAccordion
                    fontKeys={['Value Serif/24', null, null, null, 'Value Serif/32-scalable-desktop']}
                    headerPX={0}
                    headerPY={0}
                    hideIcon={isAboveDesktopSm}
                    isActive={activeAccordion}
                    marginBottom="80px"
                    text={attributesLabel}
                    handleClick={() => !isAboveDesktopSm && setActiveAccordion(!activeAccordion)}
                >
                    <Content>
                        <Table>
                            <tbody>
                                {characteristics.map(({ label, value }) => (
                                    <TableRow key={`${label}-${value}`}>
                                        <TableCol {...tableColFontStyles}>{label}</TableCol>
                                        <TableCol justifyContent="flex-end">
                                            <Wysiwyg
                                                data={value}
                                                textComponent={props => (
                                                    <WysiwygParagraph textAlign="right" {...props} />
                                                )}
                                                tagComponents={{ url: TextLinkWysisyg }}
                                            />
                                        </TableCol>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </Content>
                </SingleAccordion>
            )}
        </Wrapper>
    );
};

ProductInformation.propTypes = {
    attributesLabel: PropTypes.string,
    characteristics: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        })
    ).isRequired,
    description: PropTypes.string,
    informationLabel: PropTypes.string,
};

export default ProductInformation;
