import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import ShowMoreWrapper from 'components/wrappers/ShowMoreWrapper';
import SubCategoryList from 'components/SubCategoryList';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const Hero = ({ breadcrumbs = [], heroDescription = '', heroTitle = '', subCategoryItems = [], ...rest }) => {
    const showDescription = heroDescription?.[0] !== '';
    const showSubCategories = subCategoryItems?.length > 1;
    const bannerHeight = useHeaderHeights().banner;
    const headingKeys = [
        'Value Serif/40-scalable-mobile',
        null,
        'Value Serif/56-scalable-tablet',
        null,
        'Value Serif/80-scalable-desktop',
    ];

    return (
        <>
            {breadcrumbs && (
                <Breadcrumbs
                    currentTitle={heroTitle}
                    display={['none', null, null, null, 'flex']}
                    items={breadcrumbs}
                    left="24px"
                    position="absolute"
                    top={`calc(${bannerHeight}px + 24px)`}
                />
            )}
            <Div
                alignItems="center"
                display="flex"
                flexDirection="column"
                marginBottom={['56px', null, null, null, '72px']}
                mx="auto"
                width={['100%', null, '83.33333333%']}
                marginTop={['104px', null, '128px', null, '168px']}
                textAlign="center"
                {...rest}
            >
                <Heading as="h1" fontKeys={headingKeys} mx={contentMargins} my="0">
                    {heroTitle}
                </Heading>
                {showSubCategories && <SubCategoryList marginTop="24px" items={subCategoryItems} maxWidth="800px" />}
                {showDescription && (
                    <Div marginTop="18px" maxWidth="450px" mx={contentMargins}>
                        <ShowMoreWrapper
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            maxHeightDesktop={62} // height of 3 lines(pixels) of paragraph text
                            maxHeightMobile={62} // height of 3 lines(pixels) of paragraph text
                            width="100%"
                        >
                            <Div textAlign="center">
                                <Wysiwyg
                                    data={heroDescription}
                                    textComponent={props => <WysiwygParagraph {...props} />}
                                    tagComponents={{ url: TextLinkWysisyg }}
                                />
                            </Div>
                        </ShowMoreWrapper>
                    </Div>
                )}
            </Div>
        </>
    );
};

Hero.propTypes = {
    activePage: PropTypes.number,
    breadcrumbs: PropTypes.array,
    heroDescription: wysiwygProp,
    heroTitle: PropTypes.string.isRequired,
    subCategoryItems: PropTypes.array,
};

export default Hero;
