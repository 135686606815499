import Defs from 'assets/icons/base/Defs';
import G from 'assets/icons/base/G';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Search = ({ color = colors.black, height = '100%', width = '24px', ...props }) => (
    <SVG fill="none" height={height} viewBox="0 0 24 24" width={width} {...props}>
        <G clipPath="search-icon">
            <Path
                d="M10.935 0a10.395 10.395 0 0 0-7.5 3.51 10.38 10.38 0 0 0-3.495 7.5 10.5 10.5 0 0 0 3.51 7.5 10.499 10.499 0 0 0 7.41 3.495 9.75 9.75 0 0 0 6.3-2.505l4.665 4.635 2.115-2.1-4.605-4.8a9.615 9.615 0 0 0 2.49-6.3 10.395 10.395 0 0 0-3.48-7.41A10.5 10.5 0 0 0 10.935 0Zm7.8 10.14a9.21 9.21 0 0 1-3 5.535 9.27 9.27 0 0 1-5.55 3 5.775 5.775 0 0 1-4.5-1.5 5.209 5.209 0 0 1-.45-.39c-.12-.12-.225-.255-.345-.39a2.24 2.24 0 0 0-.18-.21c-.297-.29-.573-.6-.825-.93a6.195 6.195 0 0 1-.66-1.68 6 6 0 0 1 .36-3.645A11.25 11.25 0 0 1 6.15 6.225a9.375 9.375 0 0 1 5.565-3 6 6 0 0 1 4.5 1.5c.212.144.408.31.585.495.186.185.357.386.51.6a5.79 5.79 0 0 1 1.425 4.32Z"
                fill={color}
            />
        </G>
        <Defs>
            <clipPath id="search-icon">
                <Path fill={color} d="M0 0h24v24H0z" />
            </clipPath>
        </Defs>
    </SVG>
);

Search.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Search;
