import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')`
    em {
        font-style: normal;
        text-decoration: underline;
    }
`;

const Suggestions = ({ hideOverlay = () => {}, suggestions = [], updateSearch = {}, ...rest }) => {
    const { t } = useTranslation();
    const searchItemFontStyles = styleObjectToProps(['Value Serif/20', null, null, null, 'Value Serif/24']);

    return (
        <Div {...rest}>
            <Paragraph marginBottom="12px">{t('search.search_suggestions')}</Paragraph>
            <Div display="flex" flexWrap="wrap" gap={['8px 16px', null, null, null, null, '8px 24px']}>
                {suggestions.map(category => (
                    <button
                        type="button"
                        key={category.value}
                        onClick={() => {
                            hideOverlay();
                            updateSearch(category.query);
                        }}
                    >
                        <Div
                            dangerouslySetInnerHTML={{ __html: category.highlightResult?.value }}
                            {...searchItemFontStyles}
                        />
                    </button>
                ))}
            </Div>
        </Div>
    );
};

Suggestions.propTypes = {
    hideOverlay: PropTypes.func,
    suggestions: PropTypes.array,
    updateSearch: PropTypes.func,
};

export default Suggestions;
