import Decrease from 'assets/icons/Decrease';
import Increase from 'assets/icons/Increase';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import Remove from 'assets/icons/Remove';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 0 8px;
`;

const Quantity = styled('div')`
    display: flex;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    min-width: 10px; // Prevent icon movement when changing between 1 - 10
`;

const IncreaseOrDecrease = ({ decrease, disabled, increase, quantity = 0 }) => (
    <Wrapper>
        <button disabled={disabled} type="button" onClick={decrease}>
            {quantity > 1 ? <Decrease /> : <Remove />}
        </button>
        <Quantity>
            <Paragraph as="span" lineHeight="1">
                {quantity}
            </Paragraph>
        </Quantity>
        <button disabled={disabled} type="button" onClick={increase}>
            <Increase />
        </button>
    </Wrapper>
);

IncreaseOrDecrease.propTypes = {
    decrease: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    increase: PropTypes.func.isRequired,
    quantity: PropTypes.number,
};

export default IncreaseOrDecrease;
