import React, { useEffect, useState } from 'react';

import Arrow from 'assets/icons/Arrow';
import Heading from 'components/text/Heading';
import ImageAndTextCard from 'components/cards/ImageAndTextCard';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import SubMenu from './Submenu';
import contentMargins from 'config/theme/contentMargins';
import { fadeAnimation } from 'Header/Default/Menu/menuStyledComponents';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['isActive', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: absolute;
    top: 0;
    left: ${({ isActive }) => (isActive ? '0%' : '100%')};
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background: var(--header-background-color);
    color: var(--header-text-color);

    // The following transform-style is a fix for safari z-index bug
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;

    // The delay is needed for the fadeIn- and fadeOut-animations to work properly
    transition: all 0ms linear ${({ totalAnimationDuration }) => totalAnimationDuration / 2}ms;
`;

const TopBar = styled('div', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    whitespace: nowrap;
    textoverflow: ellipsis;
    cursor: pointer;

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

const ScrollContent = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const MenuList = styled('ul')`
    display: flex;
    flex-direction: column;
    margin-left: 0;
    gap: 6px 0;
    color: var(--header-text-color);
`;

const MenuItem = styled('li', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 100%;

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })};
`;

const MenuItemImageWrapper = styled('ul')`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px 8px;
`;

const MenuItemImage = styled('li', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;

    &:nth-of-type(5n + 1),
    &:nth-of-type(9n + 5) {
        width: calc(58.33333333% - 4px);
        max-width: calc(58.33333333% - 4px);
        flex: 0 0 calc(58.33333333% - 4px);
    }

    &:nth-of-type(6n + 2),
    &:nth-of-type(7n + 3),
    &:nth-of-type(10n + 6) {
        width: calc(41.66666667% - 4px);
        max-width: calc(41.66666667% - 4px);
        flex: 0 0 calc(41.66666667% - 4px);
    }

    &:nth-of-type(8n + 4) {
        width: calc(50% - 4px);
        max-width: calc(50% - 4px);
        flex: 0 0 calc(50% - 4px);
    }

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

const RecursiveMenuMobileView = ({
    closeMenu = () => {},
    closeSubMenu = () => {},
    extraLinks = [],
    featuredLinks = [],
    isActive = false,
    isTopLevel = false,
    label = '',
    menuImages = [],
    primarySubMenu = [],
    secondarySubMenu = [],
    totalAnimationDuration,
    ...rest
}) => {
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const topBarHeight = 53;

    // Set index to open subMenu, set to null to close
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    // Array checks
    const hasExtraLinks = extraLinks?.length > 0;
    const hasFeaturedLinks = featuredLinks?.length > 0;
    const hasMenuImages = menuImages?.length > 0;
    const hasPrimaryMenuImages = secondarySubMenu?.length > 0;
    const hasPrimaryMenuLinks = primarySubMenu?.length > 0;

    // Text styles
    const topAndExtraLinksTextStyles = styleObjectToProps('Value Sans/16 Regular');
    const menuItemTextStyles = styleObjectToProps('Value Sans/20');

    // Fade settings
    const fadeOut = !isActive || typeof activeSubMenu === 'number';
    const fadeIn = !fadeOut && isActive;

    // Reset the menu to level one if menu is closed
    useEffect(() => {
        let resetTimeout;

        if (typeof activeSubMenu === 'number' && !menuIsOpen) {
            // The delay will delay the reset until the menu is fully closed
            resetTimeout = setTimeout(() => {
                setActiveSubMenu(null);
            }, totalAnimationDuration / 2);
        }

        return () => {
            clearTimeout(resetTimeout);
        };
    }, [activeSubMenu, menuIsOpen, totalAnimationDuration, label]);

    return (
        <Wrapper isActive={isActive} totalAnimationDuration={totalAnimationDuration} {...rest}>
            {!isTopLevel && (
                <TopBar
                    fadeIn={fadeIn}
                    fadeOut={fadeOut}
                    height={`${topBarHeight}px`}
                    px={contentMargins}
                    totalAnimationDuration={totalAnimationDuration}
                    onClick={() => closeSubMenu(null)}
                    {...topAndExtraLinksTextStyles}
                >
                    <Arrow
                        color="currentColor"
                        height="16px"
                        marginRight="12px"
                        transform="rotate(180deg)"
                        width="16px"
                    />
                    {label}
                </TopBar>
            )}
            <ScrollContent>
                {hasFeaturedLinks && (
                    <MenuList
                        paddingTop={isTopLevel ? `${topBarHeight}px` : '12px'}
                        width="100%"
                        margin="0 0 32px 0"
                        px={contentMargins}
                    >
                        {featuredLinks.map(({ label, to }) => (
                            <MenuItem
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                key={label + to}
                                totalAnimationDuration={totalAnimationDuration}
                                {...menuItemTextStyles}
                            >
                                <Link to={to} onClick={closeMenu}>
                                    {isTopLevel ? (
                                        <Heading as="span" fontKeys="Value Serif/32">
                                            {label}
                                        </Heading>
                                    ) : (
                                        <Paragraph as="span" fontKeys="Value Sans/24">
                                            {label}
                                        </Paragraph>
                                    )}
                                </Link>
                            </MenuItem>
                        ))}
                    </MenuList>
                )}
                {hasPrimaryMenuLinks && (
                    <MenuList
                        margin="0 0 32px 0"
                        paddingTop={isTopLevel && !hasFeaturedLinks ? `${topBarHeight}px` : '12px'}
                        px={contentMargins}
                        width="100%"
                    >
                        <SubMenu
                            activeSubMenu={activeSubMenu}
                            closeMenu={closeMenu}
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            menu={primarySubMenu}
                            menuItemTextStyles={menuItemTextStyles}
                            setActiveSubMenu={setActiveSubMenu}
                            totalAnimationDuration={totalAnimationDuration}
                        />
                    </MenuList>
                )}
                {hasMenuImages && (
                    <MenuItemImageWrapper px={contentMargins}>
                        {menuImages?.map(({ image, label, link }) => (
                            <>
                                {image.url && (
                                    <MenuItemImage
                                        fadeIn={fadeIn}
                                        fadeOut={fadeOut}
                                        key={label + link.to}
                                        totalAnimationDuration={totalAnimationDuration}
                                    >
                                        <ImageAndTextCard
                                            handleClick={closeMenu}
                                            imageBackgroundPosition={image.backgroundPosition}
                                            imageBackgroundSize={image.backgroundSize}
                                            imageUrl={image.url}
                                            label={label}
                                            to={link.to}
                                        />
                                    </MenuItemImage>
                                )}
                            </>
                        ))}
                    </MenuItemImageWrapper>
                )}
                {hasPrimaryMenuImages && (
                    <MenuList margin="0 0 32px 0" px={contentMargins} width="100%">
                        <SubMenu
                            activeSubMenu={activeSubMenu}
                            closeMenu={closeMenu}
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            menu={secondarySubMenu}
                            menuItemTextStyles={menuItemTextStyles}
                            setActiveSubMenu={setActiveSubMenu}
                            totalAnimationDuration={totalAnimationDuration}
                        />
                    </MenuList>
                )}
                {hasExtraLinks && (
                    <MenuList margin="0 0 32px 0" px={contentMargins} width="100%">
                        {extraLinks.map(({ label, to }) => (
                            <MenuItem
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                key={label + to}
                                totalAnimationDuration={totalAnimationDuration}
                                {...topAndExtraLinksTextStyles}
                            >
                                <Link to={to} onClick={closeMenu}>
                                    {label}
                                </Link>
                            </MenuItem>
                        ))}
                    </MenuList>
                )}
            </ScrollContent>
        </Wrapper>
    );
};

RecursiveMenuMobileView.propTypes = {
    closeMenu: PropTypes.func,
    closeOverlayMenu: PropTypes.func,
    closeSubMenu: PropTypes.func,
    extraLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    featuredLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    isActive: PropTypes.bool,
    isTopLevel: PropTypes.bool,
    label: PropTypes.string,
    menuImages: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            }),
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    primarySubMenu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.object,
            mneuType: PropTypes.string,
            highlight: PropTypes.bool,
            submenu: PropTypes.object,
        })
    ),
    secondarySubMenu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.object,
            mneuType: PropTypes.string,
            submenu: PropTypes.object,
        })
    ),
    totalAnimationDuration: PropTypes.number,
};

export default RecursiveMenuMobileView;
