import { closeMenu, openMenu } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const StyledButton = styled('button', { shouldForwardProp: prop => ['active'].indexOf(prop) === -1 })`
    position: relative;
    width: 40px; // For accessibility
    height: 100%; // For accessibility
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        width: 32px;
        height: 3px;
        position: absolute;
        left: 0;
        background: var(--header-text-color);
        transition: all ${transitions.primary};
        will-change: transform;
    }

    &::before {
        transform: translateY(-6px);
    }

    &::after {
        transform: translateY(6px);
    }

    ${({ active }) =>
        active &&
        `
            :before{
                transform: translateY(0) rotate(45deg);
            }

            :after{
                transform: translateY(0) rotate(-45deg);
            }
    `}
`;

const MenuButton = () => {
    const dispatch = useDispatch();
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);

    return (
        <StyledButton
            active={menuIsOpen}
            type="button"
            onClick={menuIsOpen ? () => dispatch(closeMenu()) : () => dispatch(openMenu())}
        />
    );
};

export default MenuButton;
