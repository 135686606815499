import BottomRow from './BottomRow';
import FooterContent from './FooterContent';
import React from 'react';
import contentMargins from 'config/theme/contentMargins';
import maxWidth from 'config/theme/maxWidth';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div')`
    background-color: var(--footer-background-color);
    color: var(--footer-text-color);
    box-shadow: inset 0px 24px 24px rgba(0, 0, 0, 0.1);
`;

const PositionWrapper = styled('div')`
    max-width: ${maxWidth};
    margin: 0 auto;
`;

const Footer = () => {
    const { company = {}, menu = [], newsletter = {}, partners = [], socialMedia = [] } = useSelector(
        state => state.footer.data || {}
    );

    const newsletterForm = newsletter?.form || {};

    const contentProps = {
        address: company.address,
        contact: company.contact,
        menu,
        newsletter: {
            heading: newsletter?.heading?.text,
            headingTag: newsletter?.heading?.type,
            id: 'footerNewsletter',
            ...newsletterForm,
        },
        socialMedia,
        textarea: company.information,
    };

    return (
        <footer>
            <Wrapper px={contentMargins}>
                <PositionWrapper>
                    <FooterContent {...contentProps} />
                    <BottomRow partners={partners} />
                </PositionWrapper>
            </Wrapper>
        </footer>
    );
};

export default Footer;
