import React, { useState } from 'react';

import FilterButton from './FilterButton';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import SortOptions from './SortOptions';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import zIndex from 'config/theme/z-index';

const Wrapper = styled(MaxWidthWrapper)`
    position: sticky;
    top: 0;
    padding-top: 24px;
    left: 0;
    z-index: ${zIndex.filterBar};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    background: linear-gradient(180deg, #fff 50%, rgba(255, 255, 255, 0) 100%);
`;

const FilterBar = ({ handleChange = {}, openFilterOverlay = () => {}, sorts = [] }) => {
    const { t } = useTranslation();
    const [showSort, setShowSort] = useState(false);

    const handleSortChange = value => {
        handleChange.sort(value);
        setShowSort(false);
    };

    return (
        <Wrapper includeContentMargins>
            <FilterButton handleClick={() => openFilterOverlay()}>{t('filter_config.filter')}</FilterButton>
            <FilterButton active={showSort} handleClick={() => setShowSort(!showSort)}>
                {t('filter_config.sort')}
            </FilterButton>
            <SortOptions show={showSort} sorts={sorts} handleChange={value => handleSortChange(value)} />
        </Wrapper>
    );
};

FilterBar.propTypes = {
    handleChange: PropTypes.object.isRequired,
    openFilterOverlay: PropTypes.func.isRequired,
    sorts: PropTypes.array.isRequired,
};

export default withRouter(FilterBar);
