import HeroContent from 'components/checkout/HeroContent';
import React from 'react';
import emptyAnimation from 'assets/animations/empty.json';
import { useSelector } from 'react-redux';
import transformButton from 'libs/wordpress/utils/transformButton';

const CheckoutError = () => {
    const { button, heading, text } = useSelector(state => state.page.data.checkout_response);

    return <HeroContent animation={emptyAnimation} button={button} heading={heading} text={text} />;
};

export default CheckoutError;
