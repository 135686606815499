import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';

const CheckoutSection = styled('section')`
    margin-bottom: 56px;

    ${above.tabletLg} {
        margin-bottom: 80px;
    }
`;

export default CheckoutSection;
