import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Line = styled('hr')`
    width: 100%;
    height: 2px;
    margin: 0;
    border-color: var(--basket-border-color);
`;

const Summary = ({ closeBasket }) => {
    const { t } = useTranslation();
    const checkoutLink = useSelector(state => state.application.shop_config.checkout_uri);
    const checkoutButtonLabel = useSelector(state => state.header.data.basket.checkoutButtonLabel);
    const totalDiscountIncludingSale = useSelector(state => state.basket.customPrices?.totalDiscountIncludingSale);
    const paymentMethodId = useSelector(state => state.basket.paymentMethodId);
    const { shipping_price: shippingPrice, final_price: finalPrice } = useSelector(state => state.basket.totals || {});
    const goToCheckoutButtonLabel = checkoutButtonLabel || t('checkout_basket.go_to_checkout');

    return (
        <Div>
            <Line />
            <Div display="flex" flexDirection="column" gap="8px 0" margin={['12px 0', null, '16px 0']}>
                {paymentMethodId && (
                    <Div display="flex" justifyContent="space-between">
                        <Paragraph as="span" display="block" textAlign="right">
                            {t('checkout_basket.shipping')}
                        </Paragraph>
                        <Paragraph as="span" display="block">
                            {shippingPrice}
                        </Paragraph>
                    </Div>
                )}
                {totalDiscountIncludingSale && (
                    <Div display="flex" justifyContent="space-between">
                        <Paragraph as="span" display="block" textAlign="right">
                            {t('checkout_basket.total_discount')}
                        </Paragraph>
                        <Paragraph as="span" display="block">
                            {totalDiscountIncludingSale}
                        </Paragraph>
                    </Div>
                )}
                <Div display="flex" justifyContent="space-between">
                    <Paragraph as="span" display="block" fontKeys="Value Sans/24">
                        {t('checkout_basket.to_pay')}
                    </Paragraph>
                    <Paragraph as="span" display="block" fontKeys="Value Sans/24" textAlign="right">
                        {finalPrice}
                    </Paragraph>
                </Div>
            </Div>
            {/* @todo: Add css-varible to theme */}
            <ThemeButton
                cornerBackground="var(--basket-background-color)"
                theme="black"
                to={checkoutLink}
                width="100%"
                onClick={() => closeBasket()}
            >
                {goToCheckoutButtonLabel}
            </ThemeButton>
        </Div>
    );
};

Summary.propTypes = {
    closeBasket: PropTypes.func.isRequired,
};

export default Summary;
