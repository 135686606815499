import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')``;

const ViewProductsButton = ({ hideOverlay = () => {}, hitsCount, to = '', ...rest }) => {
    const { t } = useTranslation();

    return (
        <Wrapper display="inline-block" {...rest}>
            <ThemeButton
                cornerBackground="var(--search-background-color)"
                theme="outlinedWhite"
                to={to}
                onClick={hideOverlay}
            >
                {t('search.view_all_products')} ({hitsCount})
            </ThemeButton>
        </Wrapper>
    );
};

ViewProductsButton.propTypes = {
    hideOverlay: PropTypes.func,
    hitsCount: PropTypes.number,
    to: PropTypes.string,
};

export default ViewProductsButton;
