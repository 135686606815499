import OverlayContent from 'components/contentLayouts/ShopTheLook/OverlayContent';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useClientHeight from 'hooks/useClientHeight';

const TransitionElement = styled('div')`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;

    ${above.tabletSm} {
        top: 0;
        left: unset;
        right: 0;
        max-width: 400px;
    }
`;

const ShopTheLookOverlay = ({ ...rest }) => {
    const clientHeight = useClientHeight();
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');

    return (
        <TransitionElement
            className={isAboveTabletSm ? 'slide-in right' : 'slide-in bottom'}
            height={isAboveTabletSm ? clientHeight : 'auto'}
            maxHeight={clientHeight}
        >
            <OverlayContent {...rest} />
        </TransitionElement>
    );
};

export default ShopTheLookOverlay;
