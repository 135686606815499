/* eslint-disable camelcase */
import React, { useMemo } from 'react';

import CmsModules from 'libs/wordpress/content/CmsModules';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductHero from 'components/products/ProductHero';
import ProductMeta from 'components/metadata/ProductMeta';
import ProductStructuredData from 'components/metadata/structuredData/ProductStructuredData';
import PropTypes from 'prop-types';
import RelatedProducts from 'components/products/RelatedProducts';
import { bleedMargins } from 'config/theme/contentMargins';
import { stringReplace } from 'utils/string';
import styled from 'libs/styled';
import transformHeading from 'libs/wordpress/utils/transformHeading';
import { transformProductPageData } from 'utils/dataTransformers/product';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CmsModulesWrapper = styled('div')``;

const ProductView = ({ data, relationships = {} }) => {
    const { t } = useTranslation();

    const product = useMemo(() => transformProductPageData(data, relationships, t), [data.uri]);

    const {
        badges,
        basketProductData,
        breadcrumbs,
        characteristics,
        comingSoon,
        description,
        id,
        inStock,
        mediaGallery,
        name,
        pageContent,
        price,
        relatedProductsIds,
        relatedVariationAttributes,
        series,
        sku,
        variantData,
        variationId,
    } = product;

    const {
        attributes_label: attributesLabel,
        information_label: informationLabel, //@todo: want to change this to description label in cms
        product_sliders: productSliders,
        usps,
    } = useSelector(state => state.application.config.options.product_page);

    const { more_in_series: moreInSeries, related_products: relatedProducts } = productSliders;

    const moreInSeriesHeadingsVariables = {
        '{series}': series,
    };

    const transformedMoreInSeriesHeading = transformHeading(moreInSeries.heading);
    const transformedRelatedHeading = relatedProducts ? transformHeading(relatedProducts.heading) : {};

    if (transformedMoreInSeriesHeading?.text) {
        transformedMoreInSeriesHeading.text = stringReplace(
            transformedMoreInSeriesHeading.text,
            moreInSeriesHeadingsVariables
        );
    }

    return (
        <MaxWidthWrapper includeContentMargins>
            <ProductMeta transformedProduct={product} />
            <ProductStructuredData />
            <ProductHero
                attributesLabel={attributesLabel}
                badges={badges}
                basketProductData={basketProductData}
                breadcrumbs={breadcrumbs}
                characteristics={characteristics}
                comingSoon={comingSoon}
                description={description}
                descriptionLabel={informationLabel}
                images={mediaGallery}
                informationLabel={informationLabel}
                inStock={inStock}
                name={name}
                price={price}
                relatedVariationAttributes={relatedVariationAttributes}
                sku={sku}
                usps={usps}
                variantData={variantData}
                variationId={variationId}
            />
            {pageContent && (
                <CmsModulesWrapper mx={bleedMargins}>
                    <CmsModules isProductPage data={pageContent} />
                </CmsModulesWrapper>
            )}
            {(relatedProductsIds.length > 0 || series) && (
                <RelatedProducts
                    key={id}
                    currentId={id}
                    relatedHeading={transformedRelatedHeading}
                    relatedProductsIds={relatedProductsIds}
                    series={series}
                    seriesHeading={transformedMoreInSeriesHeading}
                />
            )}
        </MaxWidthWrapper>
    );
};

ProductView.propTypes = {
    data: PropTypes.object.isRequired,
    relationships: PropTypes.object,
};

export default ProductView;
