import ArticleCard from 'components/ArticleCard';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import { animations } from 'config/theme/transitions';
import styled from 'libs/styled';
import withAnimation from 'utils/withAnimation';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Wrapper = styled('div')``;

const HeadingAndButtons = styled('div')`
    margin-bottom: 24px;

    ${above.tabletSm} {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 48px;
    }
`;

const Grid = styled('ul')`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 32px 8px;
    ${above.tabletSm} {
        gap: 48px 16px;
    }
`;

const Article = withAnimation(styled('li')`
    width: auto;
    max-width: none;
    flex: none;
    min-width: 0;
    &:nth-of-type(4n + 1) {
        grid-column-start: 1;
        grid-column-end: 8;
    }
    &:nth-of-type(4n + 2) {
        grid-column-start: 8;
        grid-column-end: 13;
    }
    &:nth-of-type(4n + 3) {
        grid-column-start: 1;
        grid-column-end: 6;
    }
    &:nth-of-type(4n + 4) {
        grid-column-start: 6;
        grid-column-end: 13;
    }
    ${above.tabletSm} {
        &:nth-of-type(6n + 1) {
            grid-column-start: 1;
            grid-column-end: 6;
        }
        &:nth-of-type(6n + 2) {
            grid-column-start: 6;
            grid-column-end: 9;
        }
        &:nth-of-type(6n + 3) {
            grid-column-start: 9;
            grid-column-end: 13;
        }
        &:nth-of-type(6n + 4) {
            grid-column-start: 1;
            grid-column-end: 5;
        }
        &:nth-of-type(6n + 5) {
            grid-column-start: 5;
            grid-column-end: 8;
        }
        &:nth-of-type(6n + 6) {
            grid-column-start: 8;
            grid-column-end: 13;
        }
    }
`);

const headingSizes = {
    small: ['Value Serif/24', null, 'Value Serif/32-scalable-tablet', null, 'Value Serif/32-scalable-desktop'],
    large: [
        'Value Serif/56-scalable-mobile',
        null,
        'Value Serif/80-scalable-tablet',
        null,
        'Value Serif/136-scalable-desktop',
    ],
};

const ArticleFlow = ({ articles = [], button = {}, heading, headingSize = 'large', headingTag = 'h2' }) => {
    const fontKeys = headingSizes[headingSize];

    return (
        <Wrapper>
            <HeadingAndButtons>
                {heading && (
                    <Heading as={headingTag} fontKeys={fontKeys} width={['100%', null, 'calc(75% - 4px)']}>
                        {heading}
                    </Heading>
                )}

                {button.to && (
                    <ThemeButton
                        marginTop={['24px', null, '0']}
                        maxWidth={[null, null, '25%']}
                        theme="outlinedBlack"
                        to={button.to}
                    >
                        {button.label}
                    </ThemeButton>
                )}
            </HeadingAndButtons>
            <Grid>
                {articles?.map((article, index) => (
                    <Article
                        key={article?.id}
                        transitionDelay={[
                            `${animations.primary.delay * (index % 2)}ms`,
                            `${animations.primary.delay * (index % 3)}ms`,
                        ]}
                    >
                        <ArticleCard {...article} />
                    </Article>
                ))}
            </Grid>
        </Wrapper>
    );
};

ArticleFlow.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object).isRequired,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingSize: PropTypes.string,
    headingTag: PropTypes.string,
    tag: PropTypes.string,
    text: wysiwygProp,
};

export default ArticleFlow;
