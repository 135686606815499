export default {
    body: 'Value Sans',
    primary: 'Value Sans',
    heading: 'Value Serif',
    subHeading: 'Value Sans',
    paragraph: {
        sans: 'Value Sans',
        serif: 'Value Serif',
    },
    quote: {
        sans: 'Value Sans',
        serif: 'Value Serif',
    },
    tag: {
        sans: 'Value Sans',
        serif: 'Value Serif',
    },
};
