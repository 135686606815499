import PropTypes from 'prop-types';
import React from 'react';

/**
 * Creates the added OG meta tags given
 */

const createOgTags = ({
    description = '',
    media = '',
    ogType = 'website',
    price = {},
    title = '',
    ogUrl,
    variations = [],
}) => {
    // Setup data
    const ogTitle = title;
    const ogDescription = description || null;
    const imageSrc = typeof media === 'string' ? media : media?.src;
    const ogImage = imageSrc ? `${imageSrc}?w=1200&q=70&auto=format` : null; // Facebook wants specific widths and heights on their images;
    const ogProductPrice = price?.on_sale ? price?.sale_price_with_currency : price?.price_with_currency || null;
    // Only want to display the currency here. split the text on space. Always take the last in list.
    const ogProductCurrency = price?.price_with_currency?.split(/\s+/)[1] || null;
    const inStock = variations?.some(i => i.in_stock);
    const ogProductAvailability = inStock ? 'in stock' : 'out of stock';

    return (
        <>
            {ogType && <meta property="og:type" content={ogType} />}
            {ogTitle && <meta property="og:title" content={ogTitle} />}
            {ogDescription && <meta property="og:description" content={ogDescription} />}
            {ogImage && <meta property="og:image" content={ogImage} />}
            {ogUrl && <meta property="og:url" content={ogUrl} />}
            {ogProductPrice && <meta property="product:price:amount" content={ogProductPrice} />}
            {ogProductCurrency && <meta property="product:price:currency" content={ogProductCurrency} />}
            {ogProductAvailability && <meta property="product:availability" content={ogProductAvailability} />}
        </>
    );
};

createOgTags.propTypes = {
    description: PropTypes.string,
    media: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ogType: PropTypes.string,
    ogUrl: PropTypes.string,
    price: PropTypes.object,
    title: PropTypes.string,
    variations: PropTypes.arrayOf(PropTypes.object),
};

export default createOgTags;