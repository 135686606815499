import React, { useEffect, useRef, useState } from 'react';
import contentMargins, { bleedMargins } from 'config/theme/contentMargins';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import PropTypes from 'prop-types';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import { above } from 'utils/mediaqueries';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    width: 100%;
`;

const Links = styled('div')`
    display: flex;
    flex-wrap: nowrap;
    gap: 8px 12px;

    ${above.tabletSm} {
        flex-wrap: wrap;
        justify-content: center;
    }

    &::before,
    &::after {
        display: block;
        content: '';
        ${responsiveBreakpointsStyle(contentMargins, 'width: {value}; max-width: {value}; flex: 0 0 {value};')}
    }
`;

const SubCategoryList = ({ items, ...rest }) => {
    const linksRef = useRef();
    const [fitsOnScreen, setFitsOnScreen] = useState();

    // Center links on screen if all fits inside the window.
    useEffect(() => {
        // Run on resize
        const handleResize = () => {
            const clientWidth = document.documentElement.clientWidth;
            const linksWidth = linksRef.current?.getBoundingClientRect().width;
            setFitsOnScreen(clientWidth > linksWidth);
        };

        // Run on mount
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [linksRef]);

    return (
        <Wrapper mx={bleedMargins} {...rest}>
            <Below
                breakpoint="tabletSm"
                render={() => (
                    <ScrollWrapper
                        displayFadeEffect
                        direction="horizontal"
                        scrollContentProps={fitsOnScreen ? { justifyContent: 'center' } : null}
                    >
                        <Links ref={linksRef}>
                            {items.map(item => (
                                <TextLinkUnderline
                                    color={item.current ? 'var(--theme-text-color)' : 'var(--default-text-color-light)'}
                                    key={item.title}
                                    noUnderline={!item.current}
                                    to={item.to}
                                    onClick={item.onClick}
                                >
                                    {decodeHTMLEntities(item.title)}
                                </TextLinkUnderline>
                            ))}
                        </Links>
                    </ScrollWrapper>
                )}
            />
            <Above
                breakpoint="tabletSm"
                render={() => (
                    <Links ref={linksRef}>
                        {items.map(item => (
                            <TextLinkUnderline
                                color={item.current ? 'var(--theme-text-color)' : 'var(--default-text-color-light)'}
                                key={item.title}
                                noUnderline={!item.current}
                                to={item.to}
                                onClick={item.onClick}
                            >
                                {decodeHTMLEntities(item.title)}
                            </TextLinkUnderline>
                        ))}
                    </Links>
                )}
            />
        </Wrapper>
    );
};

SubCategoryList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            to: PropTypes.string,
            current: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ).isRequired,
};

export default SubCategoryList;
