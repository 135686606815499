import React, { useEffect, useState } from 'react';

import Banner from 'Header/Default/HeaderTop/Banner';
import ByonLogo from 'assets/icons/logos/ByonLogo';
import Link from 'components/base/Link';
import { above } from 'utils/mediaqueries';
import contentMargins from 'config/theme/contentMargins';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import { scrollTo } from 'utils/scrollFunctions';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

// Used for calculate max-width for logo
const logoRect = {
    height: 20,
    width: 132,
};

const LogoLink = styled(Link)`
    position: fixed;
    left: 50%;
    width: 100vw;
    max-width: ${logoRect.width}px;
    z-index: ${zIndex.logoMobile};
    transform: translate3d(-50%, 0, 0);
    transition: all ${transitions.secondary};

    ${above.desktopMd} {
        z-index: ${zIndex.logoDesktop};
    }

    &.introAnimation {
        ${responsiveBreakpointsStyle(contentMargins, 'max-width: calc( 100% - ({value}*2) );')}
    }
`;

// The logo is fullwidth and var(--top-logo-theme-color) above the fold on the start page. On the other pages it is always small and var(--top-logo-default-color).
const HeaderTop = () => {
    // Custom hooks for handling heights
    const headerHeights = useHeaderHeights();
    const clientHeight = useClientHeight();

    // Selectors
    const pageTemplate = useSelector(state => state.page.template);
    const pageType = useSelector(state => state.page.type);
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const currentOverlay = useSelector(state => state.overlay.current);

    // Variables for readability
    const hideBanner = pageType === 'ecommerce_checkout';
    const hideLogoTop = pageType === 'ecommerce_checkout' || pageType === 'post' || pageTemplate === 'magazine';
    const hideHeaderTop = hideBanner && hideLogoTop;
    const isFrontPage = pageTemplate === 'frontpage';
    const clientHeightAsNumber = parseFloat(clientHeight);
    const hasOverlay = menuIsOpen || currentOverlay !== null;

    // State handling logo appearance
    const [fullWidthLogo, setFullWidthLogo] = useState(isFrontPage);
    const [scrolledPastBanner, setScrolledPastBanner] = useState(false);
    const [logoColor, setLogoColor] = useState(
        isFrontPage ? 'var(--top-logo-theme-color)' : 'var(--top-logo-default-color)'
    );

    // Minimize the full logo and scroll past banner if menu or overlays is open
    useEffect(() => {
        if (hasOverlay) {
            if (!scrolledPastBanner) {
                // Scroll past banner - 1px threshold so that the logo does not animate up to full size again right at the scroll
                scrollTo({ smooth: true, top: headerHeights.banner + 1 });
            }
            setFullWidthLogo(false);
        } else if (isFrontPage && !scrolledPastBanner) {
            // Set back to full size logo when closing an overlay at top of frontpage
            scrollTo({ smooth: true, top: 0 });
            setFullWidthLogo(true);
        }
    }, [currentOverlay, menuIsOpen]);

    // Used for logo animations and color settings
    const handleScroll = () => {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || 0;
        const scrolledPastAboveTheFold = scrollPosition < clientHeightAsNumber;

        // New states
        const newScrolledPastBannerState = scrollPosition > headerHeights.banner;
        let newFullWidthLogoState = fullWidthLogo;
        let newLogoColorState = logoColor;

        // Change logo size when scrolled passed banner on frontpage
        if (isFrontPage && !newScrolledPastBannerState) {
            newFullWidthLogoState = true;
        } else {
            newFullWidthLogoState = false;
        }

        // Change logo color when scrolled passed above the fold on frontpage
        if (isFrontPage && scrolledPastAboveTheFold) {
            newLogoColorState = 'var(--top-logo-theme-color)';
        } else {
            newLogoColorState = 'var(--top-logo-default-color)';
        }

        // Update states if there is new states
        if (newFullWidthLogoState !== fullWidthLogo) {
            setFullWidthLogo(newFullWidthLogoState);
        }
        if (newLogoColorState !== logoColor) {
            setLogoColor(newLogoColorState);
        }
        if (newScrolledPastBannerState !== scrolledPastBanner) {
            setScrolledPastBanner(newScrolledPastBannerState);
        }
    };

    useEffect(() => {
        // Run on init
        handleScroll();

        document.addEventListener('scroll', handleScroll);
        return () => document.removeEventListener('scroll', handleScroll);
    }, [pageTemplate, pageType, logoColor, scrolledPastBanner]);

    if (hideHeaderTop) {
        return null;
    }

    const logoTop = scrolledPastBanner
        ? ['16px', null, '24px']
        : [`${headerHeights.banner + 16}px`, null, `${headerHeights.banner + 24}px`];

    return (
        <>
            {!hideBanner && <Banner />}
            {!hideLogoTop && (
                <LogoLink className={fullWidthLogo ? 'introAnimation' : null} to="/" top={logoTop}>
                    <ByonLogo color={logoColor} width="100%" height="100%" />
                </LogoLink>
            )}
        </>
    );
};

export default HeaderTop;
