import PropTypes from 'prop-types';
import { srcProp } from 'utils/proptypes/modules/textProps';
import styled from 'libs/styled';

const excludedProps = ['objectFit', 'forwardRef', 'imageBackground'];

const LazyImage = styled('img', {
    shouldForwardProp: prop => excludedProps.indexOf(prop) === -1,
})`
    user-select: none;
    color: transparent;

    &:not([src]) {
        visibility: hidden;
    }
`;

LazyImage.propTypes = {
    alt: PropTypes.string.isRequired,
    forwardRef: PropTypes.node,
    height: PropTypes.string,
    objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']),
    sizes: PropTypes.string,
    src: srcProp,
    srcSet: PropTypes.string,
    width: PropTypes.string,
};

LazyImage.defaultProps = {
    forwardRef: null,
    height: 'auto',
    objectFit: 'contain',
    src: null,
    width: '100%',
};

export default LazyImage;
