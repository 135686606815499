import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledParagraph = styled('p')``;

const Paragraph = ({ as = 'p', children, fontKeys = 'Value Sans/16', ...rest }) => {
    const fontStyles = styleObjectToProps(fontKeys);

    return (
        <StyledParagraph as={as} {...fontStyles} {...rest}>
            {children}
        </StyledParagraph>
    );
};

Paragraph.propTypes = {
    as: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Paragraph;
