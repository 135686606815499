import React, { useEffect, useRef, useState } from 'react';
import { above, below } from 'utils/mediaqueries';

import Piggy from 'assets/icons/Piggy.tsx';
import PropTypes from 'prop-types';
import { labels } from 'config/theme/breakpoints';
import styled from 'libs/styled';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['borderColor'].indexOf(prop) === -1,
})`
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    border-bottom: 2px solid ${({ borderColor }) => borderColor};
`;

const animationKeyframes = parsedWidth => {
    return `@keyframes spin${parsedWidth} {
        0% {
            transform: translateX(0) translateY(0%);
        }
        25% {
            transform: translateX(${((10000 / parsedWidth) * 100 - 100) * 0.25}%) translateY(1.5%) rotate(45deg);
        }
        50% {
            transform: translateX(${((10000 / parsedWidth) * 100 - 100) * 0.5}%) translateY(8.5%) rotate(90deg);
        }
        75% {
            transform: translateX(${((10000 / parsedWidth) * 100 - 100) * 0.75}%) translateY(1.5%) rotate(135deg);
        }
        100% {
            transform: translateX(${(10000 / parsedWidth) * 100 - 100}%) translateY(0%) rotate(180deg);
        }
    }`;
};

const animationMediaQueries = parsedWidth => {
    const mediaQueries = [];
    for (let i = 0; i < parsedWidth.length; i++) {
        if (parsedWidth[i]) {
            mediaQueries.push(`animation: spin${parsedWidth[i]} 3s linear infinite alternate;`);
        } else if (parsedWidth[i - 1]) {
            mediaQueries.push(`animation: spin${parsedWidth[i - 1]} 3s linear infinite alternate;`);
        } else {
            mediaQueries.push(null);
        }
    }

    return mediaQueries;
};

const PiggyWrapper = styled('div', {
    shouldForwardProp: prop => ['parsedIconWidth'].indexOf(prop) === -1,
})`

    // mediaqueries animation css
    ${({ parsedIconWidth }) =>
        animationMediaQueries(parsedIconWidth)
            .map((item, index) => {
                if (item && index === 0) {
                    return `${below[labels[index]]} {${item}}`;
                } else if (item) {
                    return `${above[labels[index - 1]]} {${item}}`;
                }
                return '';
            })
            .join('\r\n')}
    
    // keyframes
    ${({ parsedIconWidth }) =>
        parsedIconWidth
            .map(item => {
                if (item) {
                    return animationKeyframes(item);
                }
                return '';
            })
            .join('\r\n')}
`;

/**
 * RollingPiggy
 *
 * @param {string} borderColor - Set border color
 * @param {string} color - Set color for piggy
 * @param {array || string} iconWidth - Set width for piggy icon. I.e. ['80px', null, '15%', null', '10%'] or '100px'. Currently optimal to use '%' instead of 'px'.
 *
 *
 */

const RollingPiggy = ({
    borderColor = 'var(--theme-border-color)',
    color = 'var(--theme-text-color)',
    iconWidth = '15%',
    ...rest
}) => {
    const [wrapperWidth, setWrapperWidth] = useState();
    const wrapperRef = useRef();

    if (typeof iconWidth === 'string') {
        iconWidth = [iconWidth];
    } else if (!iconWidth[0]) {
        iconWidth[0] = '15%';
    }

    // Set WrapperWidth for calculating animation when iconWidth unit is in px
    useEffect(() => {
        if (wrapperRef.current) {
            setWrapperWidth(wrapperRef.current.getBoundingClientRect().width);
        }
    }, []);

    /**
     * Create an array with parsed input data for calculating animation keyframes.
     * The numbers are multiplied with 100('XX%') or 10 000('XXpx') to avoid problems with floating-point arithmetic and to have good
     * accuracy if very small px values are used for bigger screens.
     * Rem and Em is currently not supported.
     */
    const parsedIconWidth = iconWidth.map(element => {
        if (typeof element === 'string') {
            // Width %
            if (element.includes('%')) {
                element = Math.round(parseFloat(element) * 100);
            } else {
                const iconWrapperWidth = parseInt(element, 10);
                element = Math.round((iconWrapperWidth / wrapperWidth) * 10000);
            }
        }
        return element;
    });

    return (
        <Wrapper ref={wrapperRef} borderColor={borderColor}>
            <PiggyWrapper width={iconWidth} parsedIconWidth={parsedIconWidth}>
                <Piggy color={color} {...rest} />
            </PiggyWrapper>
        </Wrapper>
    );
};

RollingPiggy.propTypes = {
    borderColor: PropTypes.string,
    color: PropTypes.string,
    iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default RollingPiggy;
