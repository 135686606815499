import { useSelector } from 'react-redux';

const showroomAliasID = process.env.REACT_APP_SHOWROOM_ALIAS_ID;

const useIsShowroom = () => {
    const { aliasId } = useSelector(state => state.application);

    return showroomAliasID === `${aliasId}`;
};

export default useIsShowroom;
