import CheckoutContainer from 'containers/CheckoutContainer';
import CheckoutView from 'views/Page/Checkout/CheckoutView';
import EmptyCheckoutView from 'views/Page/Checkout/EmptyCheckoutView';
import ErrorCheckoutView from 'views/Page/Checkout/ErrorCheckoutView';
import PropTypes from 'prop-types';
import React from 'react';
import ThankYouView from './SuccessCheckoutView';
import { useSelector } from 'react-redux';

export const selectedPaymentMethod = 'adyen-drop-in-byon';
export const defaultShippingMethod = 'ingrid';

const Checkout = ({ data }) => {
    const basket = useSelector(state => state.basket || {});
    const template = useSelector(state => state.page.template || '');
    const hasBasketIdAndItems = basket.basketId && basket.items?.length > 0;

    if (template === 'ecommerce_checkout_success') {
        return <ThankYouView />;
    }

    if (template === 'ecommerce_checkout_error') {
        return <ErrorCheckoutView />;
    }

    if (hasBasketIdAndItems) {
        return (
            <CheckoutContainer selectedPaymentMethod={selectedPaymentMethod} render={CheckoutView} renderData={data} />
        );
    }

    return <EmptyCheckoutView />;
};

Checkout.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Checkout;
